import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import device from "../../theme/utils/breakpoints";
import Button from "../UiKit/Button";
import Card from "../UiKit/Card";
import { ScrollWrapperMarginCss, SectionWrapperMarginCss } from "../UiKit/Helpers/StyledCss";

const SectionScrollWrapper = styled.div`
  overflow-x: auto;
  ${ScrollWrapperMarginCss};

  & + & {
    margin-top: 24px;
  }
`;

export const SectionWrapper = styled(Card)`
  ${SectionWrapperMarginCss};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 6px;
  min-width: min-content;
`;

export function SectionContainer({ children }: { children: JSX.Element | JSX.Element[] }) {
  return (
    <SectionScrollWrapper>
      <SectionWrapper>{children}</SectionWrapper>
    </SectionScrollWrapper>
  );
}

export const SectionTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`;

export const TableReportTitleColumn = styled.div`
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

export const TableReportTitleLink = styled(Link)`
  display: inline-flex;
`;

export const PageFilterContainer = styled.div`
  display: flex;
  position: sticky;
  gap: 22px;
  row-gap: 12px;
  flex-wrap: wrap;
  align-items: center;
  top: ${(props) => props.theme.navBar.height};
  z-index: 200;
  padding: 12px 32px;
  border-bottom: 1px solid ${(props) => props.theme.text4};
  margin-bottom: 32px;
  background: #fff;

  @media ${device.M} {
    position: static;
    flex-direction: column;
    align-items: start;
  }
`;

export const ExportButton = styled(Button)`
  margin-bottom: 20px;
  gap: 8px;
  margin-left: auto;
`;
