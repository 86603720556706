import React from "react";
import { useTranslation } from "react-i18next";
import useInitialFetchDone from "../../utils/hooks/useInitialFetchDone";
import Loader from "../Loader";
import PagePeriodFilter from "../PagePeriodFilter/PagePeriodFilter";
import { MainContentWrapper } from "../UiKit/Helpers/StyledCss";
import DataTypeSelection from "./DataTypeSelection";
import ReportHeader, { ReportHeaderI } from "./ReportHeader";
import { ExportButton, PageFilterContainer } from "./StyledComponents";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";
import EmptyState from "../EmptyState";
import HeaderWithBreadcrumbs from "../Layout/HeaderWithBreadcrumbs";
import Container from "../UiKit/Container";
import WithTooltip from "../Tooltip/WithTooltip";

interface ReportPageI {
  children: React.ReactElement;
  reportHeaderProps: ReportHeaderI;
  showEmptyState: boolean;
  isFetching: boolean;
  exportPDF: () => void;
  title: string;
  isError?: boolean;
  exportDisabled?: boolean;
}

export default function ReportPage({
  children,
  reportHeaderProps,
  showEmptyState,
  isError,
  isFetching,
  title,
  exportPDF,
}: ReportPageI) {
  const { t } = useTranslation();
  const initialFetchDone = useInitialFetchDone(isFetching);

  if (!initialFetchDone) {
    return (
      <MainContentWrapper style={{ minHeight: "700px" }}>
        <Loader show />;
      </MainContentWrapper>
    );
  }
  if (isError) {
    return (
      <EmptyState
        show={showEmptyState}
        title={t("common.reportPages.errorState.title")}
        subtitle={t("common.reportPages.errorState.subtitle")}
        type="error"
      />
    );
  }
  return (
    <MainContentWrapper>
      <HeaderWithBreadcrumbs title={title} />

      <PageFilterContainer>
        <PagePeriodFilter />
        <DataTypeSelection />
      </PageFilterContainer>

      <Container>
        <WithTooltip
          disableTooltip={!showEmptyState || isFetching}
          id="report-page-download"
          tooltip={t(`common.reportPages.exportButtonTooltip`)}
          place="top"
          withoutUnderline
        >
          <ExportButton
            $disabled={showEmptyState || isFetching}
            data-uf-export-pdf
            onClick={() => {
              if (showEmptyState || isFetching) {
                return;
              }
              exportPDF();
            }}
          >
            <DownloadIcon /> {t("common.reportPages.exportButton")}
          </ExportButton>
        </WithTooltip>

        <ReportHeader {...reportHeaderProps} />

        {children}

        <EmptyState
          show={showEmptyState}
          title={t("common.noDataInPeriod.title")}
          subtitle={t("common.noDataInPeriod.subtitle")}
        />
      </Container>
    </MainContentWrapper>
  );
}
