import styled from "styled-components";
import { ButtonResetCSS } from "./Helpers/StyledCss";

export const CloseButton = styled.button`
  ${ButtonResetCSS}
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.5);
  position: absolute;
  top: 20px;
  right: 20px;

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after,
  &:before {
    left: 50%;
    top: 50%;
    position: absolute;
    content: "";
    display: block;
    width: 2px;
    height: 20px;
    border-radius: 1px;
    background: ${(props) => props.theme.text2};
    transform-origin: center;
  }
`;
