import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";
import UiKitCard from "../../../components/UiKit/Card";
import { FlexCenterCSS } from "../../../components/UiKit/Helpers/StyledCss";
import device from "../../../theme/utils/breakpoints";
import { RLTextOnHover } from "../RevealingLink/RevealingLink";

export const Link = styled(RouterLink)`
  text-decoration: none;
  flex: 1;
`;

export const Card = styled(UiKitCard)`
  transition: background 0.3s;
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 28px;

  &:hover {
    background: ${(props) => props.theme.blueFair20};
    ${RLTextOnHover}
  }
  &:active {
    background: ${(props) => props.theme.blueFair50};
  }
`;

export const TableWrapper = styled.div`
  display: flex;
`;

const RowCellsCss = css`
  > * {
    &:first-child {
      flex: 2;
    }

    &:last-child {
      flex: 1;
      margin-left: 8px;
      text-align: right;
    }
  }
`;

export const Row = styled.div`
  ${FlexCenterCSS}
  border-top: ${(props) => props.theme.lightBorder};
  padding: 5px 12px;
  font-size: 14px;

  ${RowCellsCss};

  > * {
    &:last-child {
      font-size: 14px;
      font-weight: bold;
      font-variant-numeric: tabular-nums;
    }
  }
`;

export const HeaderRow = styled.div`
  ${FlexCenterCSS}
  ${RowCellsCss};
  padding: 0 12px 6px;
  align-items: start;
  font-weight: 600;
  font-size: 18px;
`;

export const Table = styled.div`
  min-width: 370px;
  max-width: calc(100% - 60px);

  @media ${device.M} {
    min-width: unset;
    max-width: unset;
  }
`;

export const ToolBar = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-width: 128px;
  align-items: end;
  margin-left: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
