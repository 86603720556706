import React from "react";
import { Control, Controller } from "react-hook-form";
import styled from "styled-components";
import FormGroupWrapper from "./FormGroupWrapper";

const StyledInput = styled.input<{ $isInvalid?: boolean; passwordController: boolean }>`
  width: 100%;
  background: ${(props) => (props.$isInvalid ? props.theme.redDangerBackground : props.theme.systemBackground)};
  height: 38px;
  border: none;
  border-radius: 12px;
  padding: 0 14px;
  ${({ passwordController }) => passwordController && "padding-right: 38px"}
`;

export interface IFormControlController {
  className?: string;
  name: string;
  control: Control<any>;
  label?: string;
  text?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  passwordController?: boolean;
  [key: string]: any;
}

export default function FormControlController({
  className,
  control,
  name,
  label,
  text,
  placeholder,
  required,
  passwordController,
  ...rest
}: IFormControlController) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, fieldState: { invalid, error } }) => (
        <FormGroupWrapper
          className={className}
          text={text}
          invalid={invalid}
          error={error}
          label={label}
          required={required}
        >
          <StyledInput
            onChange={onChange}
            passwordController={!!passwordController}
            value={value}
            placeholder={placeholder}
            ref={ref}
            $isInvalid={invalid}
            {...rest}
          />
        </FormGroupWrapper>
      )}
    />
  );
}
