import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./i18next";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";
import InsightsTheme from "./theme/theme";
import App from "./App";
import UserContextProvider from "./contexts/UserContext";
import Interceptor from "./api/Interceptor";
import { GlobalStyle } from "./theme/GlobalStyles";
import { ErrorBoundary } from "./libs/bugsnag";
import SpecializationSettingsModalContextProvider from "./components/Modals/SpecializationSettingsModal/SpecializationSettingsModalContext";
import CountryContextProvider from "./components/CountriesMap/CountryContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <SpecializationSettingsModalContextProvider>
              <CountryContextProvider>
                <ThemeProvider theme={InsightsTheme}>
                  <GlobalStyle />
                  <Interceptor />
                  <App />
                </ThemeProvider>
              </CountryContextProvider>
            </SpecializationSettingsModalContextProvider>
          </UserContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
