import _omit from "lodash/omit";
import { ReportTableType } from "./table.types";
import { getOrderedObjectValues } from "../../utils/common";

const arraySum = (array: Array<number>) => array.reduce((acc, current) => acc + current, 0);

export const getTableTotals = (items: Array<any>, keys: Array<string>) =>
  keys.map((key) => arraySum(items.map((item) => item[key] || 0)));

export type SpecializationDataType = "top" | "rest" | "all";

export function getSpecializationsData(
  data: { [p: string]: { [p: string]: { [p: string]: number | string } } },
  type: SpecializationDataType
) {
  switch (type) {
    case "all":
      return Object.values(data).reduce((acc: Array<any>, current) => [...acc, ...Object.values(current)], []);
    case "top":
      return data.MD ? Object.values(data.MD) : [];
    case "rest":
      return Object.values(_omit(data, "MD")).reduce(
        (acc: Array<any>, current) => [...acc, ...Object.values(current)],
        []
      );
    default:
      throw new Error("getSpecializationsData was called with unsupported type");
  }
}

export const generateSpecializationsTableData = (
  data: {
    [key: string]: { [key: string]: { [key: string]: number | string } };
  },
  reportType: "tools" | "ads" | "cme",
  type: "top" | "rest",
  headerValuesKeys: Array<string>,
  headerHints: Array<string>,
  dividerIndex?: number
): ReportTableType => {
  const itemValues = getSpecializationsData(data, type);

  return {
    headerHints,
    header: {
      description: `${reportType}.table.${type}Specializations`,
      values: headerValuesKeys.map((key) => `${reportType}.table.specializations.${key}`),
    },
    dividerIndex,
    items: itemValues.map((item) => {
      const { translation, ...values } = item;
      return {
        description: translation,
        values: getOrderedObjectValues(values, headerValuesKeys),
      };
    }),
    footer: {
      description: `${reportType}.table.footer`,
      values: getTableTotals(itemValues, headerValuesKeys),
    },
  };
};

export const getChartOptions = (data?: { [key: string]: any }) => {
  const barPercentage = data ? 0.8 - Math.exp(-0.286 * Object.keys(data).length) : 0.8;

  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: "#000",
        bodyColor: "#fff",
        titleColor: "#fff",
        cornerRadius: 12,
        boxPadding: 4,
        titleFont: { weight: "400" },
        bodyFont: { size: 12 },
        padding: 12,
      },
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
    },
    scale: {
      ticks: {
        precision: 0,
      },
    },
    barPercentage,
  };
};
