import styled from "styled-components";

export const SpecializationsContainerWrapper = styled.div`
  width: 100%;
  padding: 16px 8px 0 0;
  overflow: hidden;
  border-radius: 12px;
  background: ${(props) => props.theme.systemBackground};
`;

export const SpecializationsContainer = styled.div`
  max-height: 166px;
  padding: 4px 0 20px 20px;
  overflow: auto;
  background: ${(props) => props.theme.systemBackground};

  label {
    color: ${(props) => props.theme.text1};
  }

  > * + * {
    margin-top: 13px;
  }

  /* Scroll track */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.lavenderGrayFair};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.lavenderGrayLight};
  }
`;
