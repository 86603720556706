import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { ReactComponent as CompleteFigure } from "../../../assets/icons/auth/signup-complete2.svg";

import { RegisterContext } from "./RegisterContext";
import { AuthUserApi } from "../../../api";
import { FormRowLink } from "../Auth.styled";
import SpinnerLoader from "../../../components/UiKit/SpinnerLoader";
import { bugsnagNotify } from "../../../libs/bugsnag";
import * as Styled from "./Register.styled";

function Step4() {
  const { t } = useTranslation();

  return (
    <>
      <CompleteFigure />
      <Styled.Heading1>{t("auth.verifyEmail.header")}</Styled.Heading1>

      <Styled.InfoContainer>
        <Trans i18nKey="auth.verifyEmail.info1" />
      </Styled.InfoContainer>

      <Styled.InfoContainer>{t("auth.verifyEmail.info2")}</Styled.InfoContainer>
    </>
  );
}

function Step5() {
  const { t } = useTranslation();

  return (
    <>
      <Styled.SuccessIllustration />
      <Styled.Heading1>{t("auth.resendSuccess.header")}</Styled.Heading1>

      <Styled.ContentContainer>
        <Styled.InfoContainer $left>{t("auth.resendSuccess.info1")}</Styled.InfoContainer>

        <Styled.List>
          <li>
            <Styled.InfoContainer>
              <Trans i18nKey="auth.resendSuccess.info2" />
            </Styled.InfoContainer>
          </li>
          <li>
            <Styled.InfoContainer>
              <Trans i18nKey="auth.resendSuccess.info3" />
            </Styled.InfoContainer>
          </li>
        </Styled.List>
      </Styled.ContentContainer>
    </>
  );
}

export default function RegisterVerifyEmail() {
  const { t } = useTranslation();
  const { formState, setStep, step } = React.useContext(RegisterContext);

  const email = React.useMemo(() => formState.email, [formState.email]);

  const { mutate, isLoading } = useMutation(AuthUserApi.verifyRequestToken, {
    onSuccess: () => {
      setStep(5);
    },
    onError: (e: any) => {
      bugsnagNotify(e);
    },
  });

  const handleResendEmailClick = React.useCallback(() => {
    mutate({ requestBody: { email } });
  }, [email, mutate]);

  return (
    <Styled.Container>
      {step === 4 ? <Step4 /> : <Step5 />}

      <Styled.StyledFormRow center>
        {isLoading && <SpinnerLoader />}
        <span>{t("auth.verifyEmail.resendCopy")}</span>
        <FormRowLink onClick={handleResendEmailClick}>{t("auth.verifyEmail.resendButton")}</FormRowLink>
      </Styled.StyledFormRow>
    </Styled.Container>
  );
}
