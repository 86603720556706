import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../Table";
import { getSpecializationsData, SpecializationDataType } from "./utils";
import { formatNumber } from "../../utils/numbers";
import useReportType from "../../utils/hooks/useReportType";
import HeaderCell from "../Table/HeaderCell";

interface IReportSpecializationTable {
  data: {
    [key: string]: { [key: string]: { [key: string]: number | string } };
  };
  keys: Array<string>;
  type: SpecializationDataType;
  defaultSortKey: string;
  total?: {
    [key: string]: number;
  };
  translationsPrefix?: string;
}

export default function ReportSpecializationTable({
  data,
  keys,
  type,
  total,
  defaultSortKey,
  translationsPrefix = "",
}: IReportSpecializationTable) {
  const { t } = useTranslation();
  const specializationsData = React.useMemo(() => getSpecializationsData(data, type), [data, type]);
  const reportType = useReportType();

  const tableInitialState = React.useMemo(
    () => ({
      sortBy: [
        {
          id: defaultSortKey,
          desc: true,
        },
      ],
    }),
    [defaultSortKey]
  );

  const columns = React.useMemo(
    () => [
      ...keys.map((key) => ({
        Header: (
          <HeaderCell
            id={`${reportType}.${type}.table.specializations.${key}`}
            title={t(`${reportType}.table.specializations.${key}`)}
            tooltipText={t(`${reportType}.table.specializations.${translationsPrefix}${key}Tooltip`)}
            withoutUnderline={key === "translation"}
            tooltipEnabled={key !== "translation"}
          />
        ),
        accessor: key,
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const record = row.original;
            if (key === "translation") {
              return record[key];
            }
            return formatNumber(record[key]);
          }, [row]),
        // eslint-disable-next-line react/no-unstable-nested-components
        Footer: (info: any) => {
          if (key === "translation") {
            return <span>{t(`${reportType}.table.footer`)}</span>;
          }
          if (total) {
            return <span>{formatNumber(total[key])}</span>;
          }
          const { rows } = info;
          return <span>{formatNumber(rows.reduce((sum: number, row: any) => row.values[key] + sum, 0))}</span>;
        },
      })),
    ],
    [keys, t, reportType, type, translationsPrefix, total]
  );

  return (
    <Table
      columns={columns}
      data={specializationsData}
      withFooter
      initialState={tableInitialState}
      horizontallyScrollableCard={false}
    />
  );
}
