import React from "react";
import { Routes, Route, useLocation, Navigate, Outlet, useSearchParams } from "react-router-dom";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjs from "dayjs";
import Tool from "./pages/Tools/Tool/Tool";
import Ad from "./pages/Ads/Ad/Ad";
import Cme from "./pages/CMEs/Cme/Cme";
import Story from "./pages/Stories/Story/Story";
import Layout from "./components/Layout";
import { UserContext } from "./contexts/UserContext";
import { trackPageView } from "./libs/mixpanel";
import { AuthRoutes, ReportRoutes } from "./pages/routes";
import ToolsList from "./pages/Tools/ToolsList";
import AdsList from "./pages/Ads/AdsList";
import CmesList from "./pages/CMEs/CmesList";
import StoriesList from "./pages/Stories/StoriesList";
import PagePeriodFilterContextProvider from "./components/PagePeriodFilter/PagePeriodFilterContextProvider";
import MdsPerSpecialization from "./pages/MdsPerSpecialization";
import DataTypeSelectionContextProvider from "./components/ReportPageComponents/DataTypeSelectionContext";
import Home from "./pages/Home";
import Faq from "./pages/Static/Faq";
import ReleaseNotes from "./pages/Static/ReleaseNotes";
import Container from "./components/UiKit/Container";
import Drugs from "./pages/Drugs";
import { AdsListPage } from "./pages/Ads/AdsList/AdsList";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface DisableRouteProps {
  condition: boolean;
  fallbackRoute: string;
  redirectBackAfterLogin?: boolean;
}

function DisableRoutes({ condition, fallbackRoute, redirectBackAfterLogin }: DisableRouteProps) {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { setRedirectUrl } = React.useContext(UserContext);

  React.useEffect(() => {
    if (redirectBackAfterLogin) {
      setRedirectUrl(`${pathname}?${searchParams.toString()}`);
    }
  }, [redirectBackAfterLogin, setRedirectUrl, pathname, searchParams]);

  return condition ? <Navigate to={fallbackRoute} /> : <Outlet />;
}

function App() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { user, initialUserFetchDone } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!initialUserFetchDone) {
      return;
    }

    trackPageView(pathname, searchParams, !!user);
  }, [pathname, searchParams, user, initialUserFetchDone]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PagePeriodFilterContextProvider>
            <DataTypeSelectionContextProvider>
              <Layout />
            </DataTypeSelectionContextProvider>
          </PagePeriodFilterContextProvider>
        }
      >
        <Route path="" element={<Home />} />

        {/* Auth */}
        {Object.values(AuthRoutes).map((route) => (
          <Route key={route} path={route} element={<Home />} />
        ))}

        <Route
          element={
            <DisableRoutes
              condition={initialUserFetchDone && !user}
              fallbackRoute="/auth/login"
              redirectBackAfterLogin
            />
          }
        >
          <Route path={ReportRoutes.tools}>
            <Route path="" element={<ToolsList />} />
            <Route path=":id" element={<Tool />} />
          </Route>
          <Route path={ReportRoutes.ads}>
            <Route path="" element={<AdsList adsListType={AdsListPage.NewsFeedAds} />} />
            <Route path=":id" element={<Ad />} />
          </Route>
          <Route path={ReportRoutes.searchAds}>
            <Route path="" element={<AdsList adsListType={AdsListPage.SearchAds} />} />
            <Route path=":id" element={<Ad pageType="ads.search-ad" />} />
          </Route>
          <Route path={ReportRoutes.drugMaterials}>
            <Route path="" element={<AdsList adsListType={AdsListPage.DrugMaterials} />} />
            <Route path=":id" element={<Ad pageType="ads.drug-materials" />} />
          </Route>
          <Route path={ReportRoutes.cmes}>
            <Route path="" element={<CmesList />} />
            <Route path=":name" element={<Cme />} />
          </Route>
          <Route path={ReportRoutes.stories}>
            <Route path="" element={<StoriesList />} />
            <Route path=":id" element={<Story />} />
          </Route>

          <Route
            element={
              <DisableRoutes condition={initialUserFetchDone && !user?.permissions?.mds_per_spec} fallbackRoute="/" />
            }
          >
            <Route path={ReportRoutes.mds_per_specialization} element={<MdsPerSpecialization />} />
          </Route>

          <Route path={ReportRoutes.drugs} element={<Drugs />} />
        </Route>

        <Route
          element={
            <Container>
              <Outlet />
            </Container>
          }
        >
          <Route path="faq" element={<Faq />} />
          <Route path="release-notes" element={<ReleaseNotes />} />
        </Route>

        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  );
}

export default App;
