import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import device from "../../theme/utils/breakpoints";
import { DAILY, DataTypeContext, MONTHLY } from "./DataTypeSelectionContext";

const Container = styled.div`
  @media ${device.L} {
    align-self: flex-end;
  }
`;

export default function DataTypeSelection() {
  const { t } = useTranslation();

  const { type, setType } = React.useContext(DataTypeContext);

  const handleChange = React.useCallback(
    (e) => {
      setType(e.target.value);
    },
    [setType]
  );

  return (
    <Container>
      {[MONTHLY, DAILY].map((value) => (
        <Form.Check
          id={value}
          value={value}
          key={value}
          type="radio"
          label={t(`common.dataType.${value}`) as string}
          onChange={handleChange}
          checked={type === value}
          inline
        />
      ))}
    </Container>
  );
}
