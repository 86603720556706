/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExportAdType {
  CMR = "cmr",
  NEWSFEED = "newsfeed",
  SEARCH = "search",
}
