import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import Table from "../../../components/Table";
import { useCheckItemsForm } from "../../../utils/hooks";
import FormCheckController from "../../../components/form/FormCheckController";
import { ADS_REPORT_KEYS, ADS_SEARCH_ONLY_KEYS } from "../utils";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { mixpanelTrack, TrackingConstants } from "../../../libs/mixpanel";
import { AdResponse, ExportAdType } from "../../../api";
import { UserContext } from "../../../contexts/UserContext";
import { getAdsReportsListExport } from "../../../api/ReportListExport";
import * as Styled from "./AdsList.styled";
import EmptyState from "../../../components/EmptyState";
import DataCell, { PUBLISHED_KEY, STATE_KEY } from "../../../components/Table/DataCell/DataCell";
import TitleCell from "../../../components/Table/TitleCell";
import { ReportRoutes } from "../../routes";
import CellWithInfo from "../../../components/Table/CellWithInfo";

interface IAdsListTable {
  data: AdResponse[];
  adType: ExportAdType;
  tableTitle?: string;
}

export default function AdsListTable({ data, adType, tableTitle }: IAdsListTable) {
  const { t } = useTranslation();
  const [exportContentType, setContentType] = React.useState(null);
  const { selectedPeriod } = React.useContext(PagePeriodFilterContext);
  const { user } = React.useContext(UserContext);
  const checkItemsIds = React.useMemo(() => (data ? data.map((item) => item.id.toString()) : []), [data]);

  const { handleSubmitClick, control, handleCheckAllChange, disableSubmit, handleItemChange } = useCheckItemsForm(
    (selectedItemsIds: string[]) =>
      getAdsReportsListExport(
        exportContentType!,
        _isEmpty(selectedItemsIds) ? checkItemsIds : selectedItemsIds,
        selectedPeriod?.from!,
        selectedPeriod?.until!,
        adType
      ),
    checkItemsIds
  );

  const hiddenColumns = React.useMemo(() => {
    let columns = [...ADS_SEARCH_ONLY_KEYS];

    if (
      (adType === "newsfeed" && !user?.permissions?.newsfeed_ad_opens) ||
      (adType === "search" && !user?.permissions?.search_ad_opens)
    ) {
      columns = [...columns, "ad_opens", "ad_opens_unique_users"];
    }

    return columns;
  }, [adType, user]);

  const path = React.useMemo(() => {
    switch (adType) {
      case ExportAdType.CMR:
        return ReportRoutes.drugMaterials;
      case ExportAdType.NEWSFEED:
        return ReportRoutes.ads;
      case ExportAdType.SEARCH:
      default:
        return ReportRoutes.searchAds;
    }
  }, [adType]);

  React.useEffect(() => {
    if (exportContentType !== null) {
      mixpanelTrack(TrackingConstants.ExportAdsList, { "Export content type": exportContentType });
      handleSubmitClick();
      setContentType(null);
    }
  }, [exportContentType, handleSubmitClick]);

  const columns = React.useMemo(
    () => [
      {
        Header: <FormCheckController name="master" control={control} customOnChange={handleCheckAllChange} />,
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const record = row.original;
            return <FormCheckController name={`${record.id}`} control={control} customOnChange={handleItemChange} />;
          }, [row]),
        id: "export",
      },
      {
        Header: t("adsList.table.drugName"),
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const { insights_display_name, title, id } = row.original;
            return <TitleCell title={insights_display_name || title} link={{ pathname: `${path}/${id}` }} />;
          }, [row]),
        accessor: "title",
      },
      ...[PUBLISHED_KEY, STATE_KEY].map((key) => ({
        Header: (
          <CellWithInfo
            id={`adsList.table.${key}`}
            value={t(`adsList.table.${key}`)}
            tooltip={t(`adsList.table.${key}Tooltip`)}
          />
        ),
        accessor: key,
        Cell: ({ row }: any): any => React.useMemo(() => <DataCell record={row.original} prop={key} />, [row]),
      })),
      ...ADS_REPORT_KEYS.map((key) => ({
        Header: (
          <CellWithInfo
            id={`adsList.table.${key}`}
            value={t(`adsList.table.${key}`)}
            tooltip={t(`adsList.table.${adType}.${key}Tooltip`)}
          />
        ),
        accessor: key,
        Cell: ({ row }: any): any => React.useMemo(() => <DataCell record={row.original} prop={key} />, [row]),
      })),
      {
        Header: (
          <CellWithInfo
            id="adsList.table.country"
            value={t(`adsList.table.country`)}
            tooltip={t(`adsList.table.countryTooltip`)}
          />
        ),
        accessor: "country",
      },
      ...ADS_SEARCH_ONLY_KEYS.map((key) => ({ accessor: key })),
    ],
    [control, handleCheckAllChange, t, handleItemChange, path, adType]
  );

  if (!data || data?.length === 0) {
    return (
      <Styled.EmptyCard>
        <EmptyState show title={t("common.noDataInPeriod.title")} subtitle={t("common.noDataInPeriod.subtitle")} />
      </Styled.EmptyCard>
    );
  }
  return (
    <Table
      tableTitle={tableTitle}
      columns={columns}
      data={data}
      withGlobalFilter
      withExportButton
      disableExport={disableSubmit}
      setContentType={setContentType}
      initialState={{ hiddenColumns }}
    />
  );
}
