import React from "react";
import { useLocation } from "react-router-dom";

const useReportType = () => {
  const { pathname } = useLocation();

  return React.useMemo(() => {
    let type = pathname.split("/")[1];

    if (["search-ads"].includes(type)) {
      type = "ads";
    }

    return type;
  }, [pathname]);
};

export default useReportType;
