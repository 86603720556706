import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import Dialog from "../../UiKit/Dialog";
import { Heading3 } from "../../UiKit/Typography";
import { SpecializationSettingsModalContext } from "./SpecializationSettingsModalContext";
import { CloseButton } from "../../UiKit/CloseButton";
import { UserContext } from "../../../contexts/UserContext";
import { useCheckItemsForm } from "../../../utils/hooks";
import { UserReadWithoutInternals, UsersApi } from "../../../api";
import * as Styled from "./SpecializationSettingsModal.styled";
import { SubtleButton } from "../../UiKit/SubtleButton";
import useSpecializationsList from "../../../utils/hooks/useSpecializationsList";

function Modal() {
  const { showModal, closeModal } = React.useContext(SpecializationSettingsModalContext);
  const { user, setUserAndInit } = React.useContext(UserContext);
  const { t } = useTranslation();
  const [query, setQuery] = React.useState("");

  const specializations = useSpecializationsList(user!.country);

  const specializationsIds = React.useMemo(
    () => specializations?.map((specialization) => specialization.name),
    [specializations]
  );
  const selectedSpecializationsIds = React.useMemo(
    () => user?.specialization_interests,
    [user?.specialization_interests]
  );

  const { mutate } = useMutation(UsersApi.usersPatchCurrentUser, {
    onSuccess: (response: UserReadWithoutInternals) => {
      setUserAndInit({ ...user, ...response });
      closeModal();
    },
  });

  const submitHandler = React.useCallback(
    (specialization_interests: string[]) => {
      mutate({ requestBody: { specialization_interests } });
    },
    [mutate]
  );

  const { handleSubmitClick, control, resetForm } = useCheckItemsForm(
    submitHandler,
    specializationsIds,
    selectedSpecializationsIds
  );

  const close = React.useCallback(() => {
    closeModal();
    resetForm();
    setQuery("");
  }, [resetForm, closeModal]);

  const onInputChange = React.useCallback((e: any) => setQuery(e.target.value), [setQuery]);

  const filteredSpecializations = React.useMemo(
    () => specializations?.filter(({ local_name }) => local_name.toLowerCase().includes(query.trim().toLowerCase())),
    [query, specializations]
  );

  if (!showModal) {
    return null;
  }
  return (
    <Dialog>
      <Styled.Card>
        <CloseButton onClick={close} />

        <Heading3>{t("specializationSettingsModal.title")}</Heading3>

        <Styled.SearchInput
          placeholder={t("specializationSettingsModal.searchPlaceholder")}
          value={query}
          onChange={onInputChange}
          handleClearSearch={() => setQuery("")}
        />

        <Styled.SpecializationsSelector data={filteredSpecializations} control={control} />

        <Styled.DialogFooter>
          <SubtleButton onClick={close}>{t("common.dateSelector.cancel")}</SubtleButton>
          <SubtleButton $confirm onClick={handleSubmitClick}>
            {t("common.dateSelector.save")}
          </SubtleButton>
        </Styled.DialogFooter>
      </Styled.Card>
    </Dialog>
  );
}

export function SpecializationSettingsModal() {
  const { user } = React.useContext(UserContext);
  const { showModal } = React.useContext(SpecializationSettingsModalContext);

  if (!user || !showModal) {
    return null;
  }
  return <Modal />;
}
