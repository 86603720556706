import Markdown from "markdown-to-jsx";
import React from "react";
import Card from "../../components/UiKit/Card";
import { Heading1, Subtitle1 } from "../../components/UiKit/Typography";
import { bugsnagNotify } from "../../libs/bugsnag";
import * as Styled from "./StaticPageLayout.styled";

type StaticPageLayoutI = {
  title: string;
  subtitle: string;
  mdContent: string;
};

export default function StaticPageLayout({ title, subtitle, mdContent }: StaticPageLayoutI) {
  const [content, setContent] = React.useState("");

  React.useEffect(() => {
    fetch(mdContent)
      .then((res) => res.text())
      .then((res) => setContent(res))
      .catch((e) => {
        bugsnagNotify(e);
      });
  }, [mdContent]);

  return (
    <Styled.Wrapper>
      <Heading1>{title}</Heading1>
      <Subtitle1>{subtitle}</Subtitle1>

      <Card>
        <Styled.ContentStyle>
          <Markdown>{content}</Markdown>
        </Styled.ContentStyle>
      </Card>
    </Styled.Wrapper>
  );
}
