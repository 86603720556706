import React from "react";
import { Control } from "react-hook-form";
import { MDSpecializationSchema } from "../../api";
import FormCheckController from "../form/FormCheckController";
import * as Styled from "./SpecializationsSelector.styled";

interface SpecializationsSelectorI {
  data: MDSpecializationSchema[] | undefined;
  control: Control;
  className?: string;
}

export function SpecializationsSelector({ data, control, className }: SpecializationsSelectorI) {
  return (
    <Styled.SpecializationsContainerWrapper className={className}>
      <Styled.SpecializationsContainer>
        {data?.map((specialization) => (
          <FormCheckController
            key={specialization.name}
            name={specialization.name}
            control={control}
            label={specialization.local_name}
          />
        ))}
      </Styled.SpecializationsContainer>
    </Styled.SpecializationsContainerWrapper>
  );
}
