/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserReadWithoutInternals } from '../models/UserReadWithoutInternals';
import type { UserUpdateWithoutInternals } from '../models/UserUpdateWithoutInternals';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersApi {

  /**
   * Users:Current User
   * @returns UserReadWithoutInternals Successful Response
   * @throws ApiError
   */
  public static usersCurrentUser(): CancelablePromise<UserReadWithoutInternals> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/users/me`,
      errors: {
        401: `Missing token or inactive user.`,
      },
    });
  }

  /**
   * Users:Patch Current User
   * @returns UserReadWithoutInternals Successful Response
   * @throws ApiError
   */
  public static usersPatchCurrentUser({
    requestBody,
  }: {
    requestBody: UserUpdateWithoutInternals,
  }): CancelablePromise<UserReadWithoutInternals> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_SERVER}/users/me`,
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Missing token or inactive user.`,
        422: `Validation Error`,
      },
    });
  }

}
