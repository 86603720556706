import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import UiKitCard from "../../../components/UiKit/Card";
import { RLTextOnHover } from "../RevealingLink/RevealingLink";
import { ReactComponent as GraphSVG } from "../../../assets/icons/advertisement-graph.svg";
import { Heading2 } from "../../../components/UiKit/Typography";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
`;

export const Graph = styled(GraphSVG)`
  position: absolute;
  bottom: 0;
  fill: ${(props) => props.theme.blueFair};
`;

export const Card = styled(UiKitCard)`
  min-height: 280px;
  transition: background 0.3s;
  position: relative;

  &:hover {
    background: ${(props) => props.theme.blueFair20};
    ${RLTextOnHover}
  }
  &:active {
    background: ${(props) => props.theme.blueFair50};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const AdLink = styled(RouterLink)`
  color: ${(props) => props.theme.text2};
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
  white-space: nowrap;

  & + & {
    margin-top: 8px;
  }
`;

export const InfoText = styled.div`
  color: ${(props) => props.theme.text2};
`;

export const CardLink = styled(RouterLink)`
  text-decoration: none;
`;

export const Heading = styled(Heading2)`
  margin-bottom: 0;
  max-width: 100px;
`;
