import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IOSBadge } from "../../../assets/icons/app_badge_ios.svg";
import { ReactComponent as AndroidBadge } from "../../../assets/icons/app_badge_android.svg";
import { ReactComponent as HuaweiBadge } from "../../../assets/icons/app_badge_huawei.svg";
import FooterColumn from "./FooterColumn";

export default function FooterAppsSection() {
  const { t } = useTranslation();

  return (
    <FooterColumn title={t("footer.apps.header")}>
      <a href="https://apps.apple.com/si/developer/modra-jagoda-d-o-o/id546792104">
        <IOSBadge />
      </a>
      <a href="https://play.google.com/store/apps/dev?id=5854872199351427022">
        <AndroidBadge />
      </a>
      <a href="https://appgallery.huawei.com/#/app/C103823905">
        <HuaweiBadge />
      </a>
    </FooterColumn>
  );
}
