import React from "react";
import styled from "styled-components";
import Card from "./Card";
import { FlexCenterCSS } from "./Helpers/StyledCss";
import SpinnerLoader from "./SpinnerLoader";

const StyledCard = styled(Card)<{ $noBorder?: boolean }>`
  ${FlexCenterCSS}
  justify-content: center;
  height: 420px;

  ${({ $noBorder }) => $noBorder && "border: none;"}
`;

interface WithLoadingCardI {
  children: React.ReactNode | React.ReactElement;
  isLoading?: boolean;
  noBorder?: boolean;
}

export default function WithLoadingCard({ children, isLoading, noBorder }: WithLoadingCardI) {
  if (isLoading) {
    return (
      <StyledCard $noBorder={noBorder}>
        <SpinnerLoader />
      </StyledCard>
    );
  }
  return <div>{children}</div>;
}
