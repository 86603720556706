import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Container from "../../UiKit/Container";
import * as Styled from "./CookiesConsent.styled";
import Button from "../../UiKit/Button";

const COOKIE_CONSENT_KEY = "cookies-consent-accepted";

export default function CookiesConsent() {
  const { t } = useTranslation();
  const [isConsentAccepted, setIsConsentAccepted] = React.useState(false);

  const hideCookiesConsent = React.useMemo(
    () => isConsentAccepted || localStorage.getItem(COOKIE_CONSENT_KEY),
    [isConsentAccepted]
  );

  const onConfirm = React.useCallback(() => {
    setIsConsentAccepted(true);
    localStorage.setItem(COOKIE_CONSENT_KEY, "true");
  }, []);

  if (hideCookiesConsent) {
    return null;
  }
  return (
    <Styled.DialogWrapper>
      <Container>
        <Styled.InnerWrapper>
          <Styled.Cookie />

          <Styled.Text>
            <Trans i18nKey="cookiesConsent.text">
              We use cookies to personalize content and analyze site traffic. Read our
              <a href="https://mediately.co/business/en/cookie-policy" target="_blank" rel="noopener noreferrer">
                Cookie policy
              </a>
              to learn more.
            </Trans>
          </Styled.Text>

          <Button data-cy-cookie-confirm onClick={onConfirm}>
            {t("cookiesConsent.button")}
          </Button>
        </Styled.InnerWrapper>
      </Container>
    </Styled.DialogWrapper>
  );
}
