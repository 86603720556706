import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CHART_COLORS } from "../../../utils/constants";
import { CME_REPORT_KEYS } from "./utils";
import { CMEReportResponseSchema } from "../../../api";
import { getChartOptions } from "../../../components/ReportPageComponents/utils";
import { PeriodFilterOptions } from "../../../components/PagePeriodFilter/utils";

const ChartContainer = styled.div`
  height: 350px;
  width: 100%;
`;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface ReportPeriodChartI {
  data: CMEReportResponseSchema;
  periodOption: PeriodFilterOptions;
}

export default function ReportPeriodChart({ data, periodOption }: ReportPeriodChartI) {
  const { t } = useTranslation();
  const options = React.useMemo(() => getChartOptions(data), [data]);

  const chartData = React.useMemo(() => {
    const { date_range_total, all_time_total } = data;
    let datasets = [
      {
        backgroundColor: CHART_COLORS[periodOption === PeriodFilterOptions.ALL_TIME ? 0 : 1],
        label: t(`cmes.graph.${periodOption}`),
        data: CME_REPORT_KEYS.map((key) => date_range_total[key]),
      },
    ];

    if (periodOption !== PeriodFilterOptions.ALL_TIME) {
      datasets = [
        {
          backgroundColor: CHART_COLORS[0],
          label: t(`cmes.graph.all_time_total`),
          data: CME_REPORT_KEYS.map((key) => all_time_total[key]),
        },
        ...datasets,
      ];
    }

    return {
      labels: CME_REPORT_KEYS.map((key) => t(`cmes.graph.${key}`)),
      datasets,
    };
  }, [data, periodOption, t]);

  const isChartEmpty = chartData?.datasets.every((dataset: any) => dataset.data.length === 0);

  if (!chartData || isChartEmpty) {
    return null;
  }
  return (
    <ChartContainer>
      <Bar options={options} data={chartData} />
    </ChartContainer>
  );
}
