import React from "react";
import { useTranslation } from "react-i18next";
import useSpecializationsList from "../../utils/hooks/useSpecializationsList";

type SpecializationOptions = {
  value: string | null;
  label: string;
}[];

const useSpecializationOptions = (selectedCountry: string): { specializationOptions: SpecializationOptions } => {
  const { t } = useTranslation();

  const specializations = useSpecializationsList(selectedCountry);

  const specializationOptions = React.useMemo(() => {
    if (!specializations) {
      return [];
    }
    return [
      { value: null, label: t("drugSearchTrends.filter.allUsers") },
      ...specializations.map((s) => ({
        value: s.name,
        label: s.local_name,
      })),
    ];
  }, [specializations, t]);

  return { specializationOptions };
};

export default useSpecializationOptions;
