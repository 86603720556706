import styled from "styled-components";
import UiKitCard from "../UiKit/Card";
import { FlexCenterCSS } from "../UiKit/Helpers/StyledCss";

export const Card = styled(UiKitCard)`
  ${FlexCenterCSS}
  gap: 11%;
  padding: 39px 9% 36px;
  flex: 1;
  min-height: 184px;

  svg {
    flex-shrink: 0;
  }
`;

export const TrafficData = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  width: fit-content;
  margin-bottom: 9px;
`;

export const Strong = styled.span`
  color: ${(props) => props.theme.text1};
`;

export const TimePeriod = styled.div`
  color: ${(props) => props.theme.text2};
`;

export const TDContent = styled.div`
  display: flex;
  flex-direction: column;
`;
