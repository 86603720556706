import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import { useCheckItemsForm } from "../../../utils/hooks";
import { RegisterContext } from "./RegisterContext";
import Button from "../../../components/UiKit/Button";
import { FormRowLink } from "../Auth.styled";
import { AuthUserApi } from "../../../api";
import { bugsnagNotify } from "../../../libs/bugsnag";
import SpecializationsSelector from "../../../components/SpecializationsSelector";
import useSpecializationsList from "../../../utils/hooks/useSpecializationsList";

const Header = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 18px;
`;

const Alert = styled.div`
  color: ${(props) => props.theme.redDanger};
  margin-top: 18px;
`;

const Subheader = styled.div`
  text-align: center;
  font-size: 14px;
  margin-bottom: 19px;
`;

const Skip = styled(FormRowLink)`
  display: block;
  text-align: center;
  margin-top: 16px;
`;

export default function RegisterStepThree() {
  const { t } = useTranslation();
  const { formState, setStep } = React.useContext(RegisterContext);
  const [error, setError] = React.useState(false);
  const formattedFormState = React.useMemo(
    () => ({
      ...formState,
      full_name: `${formState.name} ${formState.lastName}`.trim(),
    }),
    [formState]
  );

  const { mutate } = useMutation(AuthUserApi.registerRegister, {
    onSuccess: async () => {
      try {
        await AuthUserApi.verifyRequestToken({ requestBody: { email: formattedFormState.email } });
        setStep(4);
      } catch (e: any) {
        setError(true);
        bugsnagNotify(e);
      }
    },
    onError: (e: any) => {
      setError(true);
      bugsnagNotify(e);
    },
  });

  const specializations = useSpecializationsList(formState.country);
  const specializationsIds = React.useMemo(
    () => specializations?.map((specialization) => specialization.name),
    [specializations]
  );

  const submitHandler = React.useCallback(
    (specialization_interests: Array<string>) => {
      setError(false);
      mutate({ requestBody: { ...formattedFormState, specialization_interests } });
    },
    [formattedFormState, mutate]
  );

  const { handleSubmitClick, control } = useCheckItemsForm(submitHandler, specializationsIds);

  const handleSkipClick = React.useCallback(() => {
    setError(false);
    mutate({ requestBody: formattedFormState });
  }, [formattedFormState, mutate]);

  return (
    <>
      <Header>{t("auth.register.step3.header")}</Header>
      <Subheader>{t("auth.register.step3.subheader")}</Subheader>

      <SpecializationsSelector data={specializations} control={control} />

      <Button data-cy-reg-step3-submit style={{ marginTop: "24px" }} big onClick={handleSubmitClick}>
        {t("auth.register.step3.submit")}
      </Button>

      {error && <Alert>{t("auth.register.step3.error")}</Alert>}

      <Skip onClick={handleSkipClick}>{t("auth.register.step3.skip")}</Skip>
    </>
  );
}
