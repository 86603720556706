import React from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";

const Spin = keyframes`
  100% {transform: rotate(360deg); }
`;

const StyledSpinner = styled(Spinner)<{ $size?: number }>`
  margin: auto;
  align-self: center;
  animation: ${Spin} 0.7s linear infinite;
  color: ${({ theme }) => theme.blueHealthy};

  ${({ $size }) =>
    $size &&
    css`
      width: ${$size}px;
      height: ${$size}px;
    `};
`;

export default function SpinnerLoader({ size }: { size?: number }) {
  return <StyledSpinner $size={size} />;
}
