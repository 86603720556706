import React from "react";
import SpinnerLoader from "../UiKit/SpinnerLoader";

interface WithLoadingSpinnerI {
  children: React.ReactElement;
  isLoading?: boolean;
}

export default function WithLoadingSpinner({ children, isLoading }: WithLoadingSpinnerI) {
  return isLoading ? <SpinnerLoader /> : children;
}
