import dayjs from "dayjs";

export type Entry = {
  published_from?: string;
  published_until?: string;
} & { [key: string]: any };

type SelectedPeriod = {
  from: string;
  until: string;
} | null;

export const todayDateRange = (): SelectedPeriod => ({
  from: dayjs().format("YYYY-MM-DD"),
  until: dayjs().format("YYYY-MM-DD"),
});

export const isInsideTheTimeRange = (
  { published_from, published_until }: Entry,
  selectedPeriod: SelectedPeriod = todayDateRange()
): boolean =>
  dayjs(published_until).isSameOrAfter(selectedPeriod?.from) &&
  dayjs(published_from).isSameOrBefore(selectedPeriod?.until);

export const isInFuture = ({ published_from }: Entry): boolean =>
  dayjs(published_from).isAfter(dayjs().format("YYYY-MM-DD"));

export const isTodayInRange = (entry: Entry): boolean => isInsideTheTimeRange(entry, todayDateRange());
export const isTodayAfterRange = ({ published_until }: Entry): boolean => dayjs().isAfter(dayjs(published_until));
