import React from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as MagnifierSvg } from "../../assets/icons/loader/magnifier.svg";
import { FlexCenterCSS } from "../UiKit/Helpers/StyledCss";

const Container = styled.div<{ fullScreen?: boolean }>`
  position: absolute;
  inset: 0;
  background: ${(props) => props.theme.systemBackground};
  z-index: 99;
  ${FlexCenterCSS}
  justify-content: center;

  p {
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    left: 60%;
    top: calc(40% + 120px);
    transform: translateY(-50%);
    color: ${(props) => props.theme.blueFair};
  }
`;

const AnimationWrapper = styled.div`
  height: 90px;
  position: relative;
  display: flex;
`;

const BarTwoAnimation = keyframes`
  0% { height: 50px; }
  36% { height: 50px; }
  52% { height: 60px; }
  86% { height: 60px; }
  100% { height: 50px; }
`;
const BarThreeAnimation = keyframes`
  0% { height: 70px; }
  63% { height: 70px; }
  77% { height: 90px; }
  86% { height: 90px; }
  100% { height: 70px; }
`;

const Bar = styled.div<{ one?: boolean; two?: boolean; three?: boolean }>`
  width: 28px;
  height: 30px;
  border-radius: 6px 6px 0px 0px;
  background: ${(props) => props.theme.lavenderGrayLight};

  ${(props) => {
    if (props.two) {
      return css`
        animation: ${BarTwoAnimation} 3s infinite;
        animation-timing-function: cubic-bezier(0.45, 0.01, 0.24, 1);
      `;
    }
    if (props.three) {
      return css`
        animation: ${BarThreeAnimation} 3s infinite;
        animation-timing-function: cubic-bezier(0.45, 0.01, 0.24, 1);
      `;
    }
    return null;
  }}
`;

const MoveChart = keyframes`
  0% {  transform:  translateY(-10px) scale(0.2) }
  13% {  transform: translateY(-10px) scale(0.2) }
  27% { transform: none; }
  86% { transform: none; }
  100% { transform:  translateY(-10px) scale(0.2) }
`;

const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 7px;
  align-self: flex-end;
  align-items: flex-end;
  animation: ${MoveChart} 3s infinite;
  animation-timing-function: cubic-bezier(0.45, 0.01, 0.24, 1);

  &:after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: -1px;
    right: -1px;
    height: 4px;
    border-radius: 2px;
    background: ${(props) => props.theme.lavenderGrayFair};
  }
`;

const MoveMagnifier = keyframes`
  0% {  transform:  none; }
  13% { transform: none; }
  27% { transform: translate(-36px,12px); }
  36% { transform: translate(-36px,12px); }
  52% { transform: translate(-1px,-12px); }
  63% { transform: translate(-1px,-12px); }
  77% { transform: translate(34px,-42px); }
  86% { transform: translate(34px,-42px); }
  100% { transform: none; }
`;

const Magnifier = styled(MagnifierSvg)`
  position: absolute;
  left: 23px;
  top: 20px;
  z-index: 5;
  animation: ${MoveMagnifier} 3s cubic-bezier(0.45, 0.01, 0.24, 1) infinite;
`;

export default function Loader({ show }: { show: boolean }) {
  if (!show) {
    return null;
  }
  return (
    <Container data-uf-loader>
      <AnimationWrapper>
        <Magnifier />
        <ChartWrapper>
          <Bar one />
          <Bar two />
          <Bar three />
        </ChartWrapper>
      </AnimationWrapper>
    </Container>
  );
}
