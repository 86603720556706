import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { ReportsListsStaticData } from "../../../api/ReportsLists";
import RevealingLink from "../RevealingLink";
import * as Styled from "./DashboardReports.styled";
import { sortByPublishedFromAndState } from "../../../utils/sort";
import { ReportRoutes } from "../../routes";
import { Entry, isInFuture } from "../../../utils/dates";
import { AdType } from "../../Ads/AdsList/AdsList";

const reportsPipe = (reports: Entry[]) =>
  reports
    .filter((e) => !isInFuture(e))
    .sort(sortByPublishedFromAndState)
    .slice(0, 3);

export default function DashboardReports({
  reportsListsStaticData,
}: {
  reportsListsStaticData?: ReportsListsStaticData;
}) {
  const { t } = useTranslation();

  const adReports = React.useMemo(() => {
    if (!reportsListsStaticData) {
      return [];
    }

    const [ads, stories, cmes, tools] = reportsListsStaticData.map((r) => reportsPipe(r));

    const adsSorted = ads
      .filter((ad) => ad.type !== AdType.SearchAd)
      .map(({ id, title, insights_display_name }) => ({
        title: insights_display_name || title,
        slug: id,
      }));

    const storiesSorted = stories.map(({ title, id }) => ({ title, slug: id }));
    const cmesSorted = cmes.map(({ name, title }) => ({ title, slug: name }));
    const toolsSorted = tools.map(({ id, name }) => ({ title: name, slug: id }));

    return [
      {
        title: t("dashboard.reports.titles.ads"),
        links: adsSorted,
        routerLink: ReportRoutes.ads,
      },
      {
        title: t("dashboard.reports.titles.news"),
        links: storiesSorted,
        routerLink: ReportRoutes.stories,
      },
      {
        title: t("dashboard.reports.titles.education"),
        links: cmesSorted,
        routerLink: ReportRoutes.cmes,
      },
      {
        title: t("dashboard.reports.titles.tools"),
        links: toolsSorted,
        routerLink: ReportRoutes.tools,
      },
    ];
  }, [reportsListsStaticData, t]);

  if (_isEmpty(adReports)) {
    return null;
  }
  return (
    <div data-uf-dashboard-reports>
      <Styled.Container>
        {adReports.map(({ title, routerLink, links }) => (
          <Styled.CardLink to={routerLink} key={title}>
            <Styled.Card data-uf-report-card={title}>
              <Styled.TitleWrapper>
                <Styled.Heading>{title}</Styled.Heading>
                <RevealingLink />
              </Styled.TitleWrapper>

              {links.length ? (
                links.map(({ slug, title: linkTitle }) => (
                  <Styled.AdLink to={`${routerLink}/${slug}`} key={linkTitle}>
                    {linkTitle}
                  </Styled.AdLink>
                ))
              ) : (
                <Styled.InfoText>{t("dashboard.reports.no_data")}</Styled.InfoText>
              )}

              <Styled.Graph />
            </Styled.Card>
          </Styled.CardLink>
        ))}
      </Styled.Container>
    </div>
  );
}
