/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProductState } from './ProductState';

export type NewsResponse = {
  id: number;
  type: NewsResponse.type;
  country: string;
  title: string;
  subtitle?: string;
  content?: string;
  published_from?: string;
  published_until?: string;
  created_at: string;
  updated_at: string;
  category: NewsResponse.category;
  sponsored?: boolean;
  story_name?: string;
  confirmed?: boolean;
  enabled: boolean;
  company?: string;
  international_specializations: Array<string>;
  state?: ProductState;
};

export namespace NewsResponse {

  export enum type {
    LINK_STORY = 'LinkStory',
    DETAIL_STORY = 'DetailStory',
    BUTTON_STORY = 'ButtonStory',
  }

  export enum category {
    MEDICAL_NEWS = 'medical_news',
    MEDIATELY_NEWS = 'mediately_news',
    DRUG_ADDED = 'drug_added',
    DRUG_CHANGED = 'drug_changed',
    DRUG_REMOVED = 'drug_removed',
    INDICATIONS_CHANGED = 'indications_changed',
    CONTRAINDICATIONS_CHANGED = 'contraindications_changed',
    WARNINGS_CHANGED = 'warnings_changed',
    DOSING_CHANGED = 'dosing_changed',
    DRIVING_CHANGED = 'driving_changed',
    INTERACTIONS_CHANGED = 'interactions_changed',
    UNDESIRABLE_CHANGED = 'undesirable_changed',
    OVERDOSE_CHANGED = 'overdose_changed',
    INSURANCE_LIST_CHANGED = 'insurance_list_changed',
    PREGNANCY_CHANGE = 'pregnancy_change',
    ALERT = 'alert',
    CALC = 'calc',
    MAIL = 'mail',
    MEMO = 'memo',
    POLL = 'poll',
    UPDATE = 'update',
    VIDEO = 'video',
  }


}

