import styled, { css } from "styled-components";
import { Container as TableContainer } from "../../../components/Table/Table.styled";
import { FlexCenterCSS, ScrollWrapperMarginCss } from "../../../components/UiKit/Helpers/StyledCss";
import device from "../../../theme/utils/breakpoints";

export const EmptyCard = styled(TableContainer)`
  ${FlexCenterCSS};
  min-height: 260px;
  justify-content: center;
  margin: 0;

  @media ${device.S} {
    margin: 0;
  }
`;

export const ScrollWrapper = styled.div`
  overflow-x: auto;
  ${ScrollWrapperMarginCss};
`;

export const TableWrapper = styled.div<{ $withTabs?: boolean }>`
  overflow: hidden;
  min-width: min-content;
  padding: 0 38px;

  @media ${device.S} {
    padding: 0 20px;
  }

  ${TableContainer} {
    ${({ $withTabs }) =>
      $withTabs &&
      css`
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      `}
  }

  #ads-tabpane-newsfeed {
    &:after,
    &:before {
      right: 0;
    }
  }
  #ads-tabpane-newsfeed {
    position: relative;
    &:after,
    &:before {
      right: 0;
    }
  }
`;
