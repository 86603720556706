import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/UserContext";
import { formatNumber } from "../../../utils/numbers";
import { bugsnagNotify } from "../../../libs/bugsnag";
import RevealingLink from "../RevealingLink";
import * as Styled from "./DashboardSpecializationsCardList.styled";
import { CardTitle } from "../Dashboard.styled";
import { MDsPerSpecializationStats } from "../../../api";
import { ReportRoutes } from "../../routes";

type MDsPerSpecializationStatsType = {
  [key: string]: MDsPerSpecializationStats;
};

export default function DashboardSpecializationsCardList({ mds }: { mds: MDsPerSpecializationStatsType }) {
  const { t } = useTranslation();
  const { user } = React.useContext(UserContext);
  const userSpecInterests = React.useMemo(() => user?.specialization_interests || [], [user?.specialization_interests]);

  const listData = React.useMemo(() => {
    const filteredSpecializations =
      userSpecInterests.length !== 0
        ? userSpecInterests.reduce(
            (acc: MDsPerSpecializationStatsType, curr: string) => ({
              ...acc,
              [curr]: mds[curr],
            }),
            {}
          )
        : mds;

    return Object.values(filteredSpecializations)
      .filter((specialization) => {
        if (!specialization) {
          bugsnagNotify("Specialization was undefined");
        }
        return Boolean(specialization);
      })
      .sort((a, b) => (a.active_mds > b.active_mds ? -1 : 1))
      .splice(0, 4);
  }, [mds, userSpecInterests]);

  return (
    <Styled.Container>
      <Styled.Link to={ReportRoutes.mds_per_specialization}>
        <Styled.Card>
          <CardTitle>
            <Trans i18nKey="dashboard.specializationsCard.header" />
          </CardTitle>
          <Styled.TableWrapper>
            <Styled.Table>
              <Styled.HeaderRow>
                <div>{t("dashboard.specializationsCard.listHeader1")}</div>
                <div>{t("dashboard.specializationsCard.listHeader2")}</div>
              </Styled.HeaderRow>
              {listData.map((md) => (
                <Styled.Row key={md.translation}>
                  <div>{md.translation}</div>
                  <div>{formatNumber(md.active_mds)}</div>
                </Styled.Row>
              ))}
            </Styled.Table>
          </Styled.TableWrapper>
          <Styled.ToolBar>
            <RevealingLink />
          </Styled.ToolBar>
        </Styled.Card>
      </Styled.Link>
    </Styled.Container>
  );
}
