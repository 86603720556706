import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import CmeSpecializationsTable from "./CmeSpecializationsTable";
import ReportPeriodTable from "../../../components/ReportPageComponents/ReportPeriodTable";
import { CME_REPORT_KEYS } from "./utils";
import ReportPeriodChart from "./CmeChart";
import { SectionContainer, SectionTitle } from "../../../components/ReportPageComponents/StyledComponents";
import { DataTypeContext, MONTHLY } from "../../../components/ReportPageComponents/DataTypeSelectionContext";
import { CmeApi, CMEReportResponseSchema, CmeResponse } from "../../../api";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import ReportPage from "../../../components/ReportPageComponents/ReportPage";
import WithLoadingCard from "../../../components/UiKit/WithLoadingCard";
import { getReportPDFExport } from "../../../api/ReportPDFExport";
import { PeriodFilterOptions } from "../../../components/PagePeriodFilter/utils";

const pageType = "cmes.cme";

interface ReportI {
  data: CMEReportResponseSchema | undefined;
  dataType: string;
  show: boolean;
  periodOption: PeriodFilterOptions;
}

function Report({ data, dataType, show, periodOption }: ReportI) {
  const { t } = useTranslation();

  if (!show || !data) {
    return null;
  }
  return (
    <>
      <SectionContainer>
        <SectionTitle>{t(`${pageType}.sectionTitle1`)}</SectionTitle>
        <ReportPeriodChart data={data} periodOption={periodOption} />
        <ReportPeriodTable
          data={dataType === MONTHLY ? data.date_range_by_month : data.date_range_by_day}
          total={data.date_range_total}
          keys={CME_REPORT_KEYS}
        />
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>{t(`${pageType}.sectionTitle2`)}</SectionTitle>
        <CmeSpecializationsTable data={data} />
      </SectionContainer>
    </>
  );
}

export default function Cme() {
  const { t } = useTranslation();
  const { name } = useParams();
  const { selectedPeriod, periodOption } = React.useContext(PagePeriodFilterContext);
  const { type: dataType } = React.useContext(DataTypeContext);

  const { data: cmes } = useQuery<CmeResponse[]>([ApiQueryKeys.cmesStaticData], CmeApi.cmesStaticData);

  const { refetch, data, isFetching, isError } = useQuery<CMEReportResponseSchema>(
    [ApiQueryKeys.getCmeReport, selectedPeriod, name],
    () =>
      CmeApi.getCmeReport({
        name: name!,
        dateFrom: selectedPeriod.from,
        dateUntil: selectedPeriod.until,
      }),
    { enabled: !!name }
  );

  const exportPDF = () => {
    getReportPDFExport({
      type: "cme",
      slug: name!,
      from: selectedPeriod!.from,
      until: selectedPeriod!.until,
      pdf_time_interval: dataType === MONTHLY ? "month" : "day",
      period_option: periodOption,
    });
  };

  const reportHeaderProps = React.useMemo(() => {
    const selectedCme = cmes?.find((cme) => cme.name === name);
    const selectedCmeAd = selectedCme?.ad;

    return {
      title: selectedCme?.title,
      from: selectedCmeAd?.published_from,
      until: selectedCmeAd?.published_until,
      image: selectedCmeAd?.image,
    };
  }, [cmes, name]);

  React.useEffect(() => {
    if (selectedPeriod) {
      refetch();
    }
  }, [refetch, selectedPeriod]);

  const showEmptyState = React.useMemo(() => {
    if (isFetching && !data) {
      return false;
    }

    return _isEmpty(data?.date_range_by_day);
  }, [data, isFetching]);

  return (
    <ReportPage
      reportHeaderProps={reportHeaderProps}
      showEmptyState={showEmptyState}
      isFetching={isFetching}
      isError={isError}
      exportPDF={exportPDF}
      title={t(`${pageType}.title`)}
    >
      <WithLoadingCard isLoading={isFetching && !data}>
        <Report data={data} dataType={dataType} show={!showEmptyState} periodOption={periodOption} />
      </WithLoadingCard>
    </ReportPage>
  );
}
