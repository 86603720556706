import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReportPeriodTable from "../../../components/ReportPageComponents/ReportPeriodTable";
import ReportPeriodChart from "../../../components/ReportPageComponents/ReportPeriodChart";
import ReportSpecializationTable from "../../../components/ReportPageComponents/ReportSpecializationTable";
import ReportSpecializationChart from "../../../components/ReportPageComponents/ReportSpecializationChart";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { SectionContainer, SectionTitle } from "../../../components/ReportPageComponents/StyledComponents";
import { DataTypeContext, MONTHLY } from "../../../components/ReportPageComponents/DataTypeSelectionContext";
import { NewsApi, NewsResponse, StoriesReportResponseSchema } from "../../../api";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import ReportPage from "../../../components/ReportPageComponents/ReportPage";
import WithLoadingCard from "../../../components/UiKit/WithLoadingCard";
import { getReportPDFExport } from "../../../api/ReportPDFExport";

const pageType = "stories.story";

interface ReportI {
  data: StoriesReportResponseSchema | undefined;
  dataType: string;
  show: boolean;
}

function Report({ data, dataType, show }: ReportI) {
  const { t } = useTranslation();

  if (!show || !data) {
    return null;
  }
  return (
    <>
      <SectionContainer>
        <SectionTitle>{t(`${pageType}.sectionTitle1`)}</SectionTitle>

        <ReportPeriodChart
          reportType="stories"
          data={dataType === MONTHLY ? data.by_month : data.by_day}
          keys={["story_views", "views_unique_users"]}
          subtitle={t(`${pageType}.chartSubtitle1`)}
        />

        <ReportPeriodChart
          secondaryColors
          reportType="stories"
          data={dataType === MONTHLY ? data.by_month : data.by_day}
          keys={["story_opens", "opens_unique_users"]}
          subtitle={t(`${pageType}.chartSubtitle2`)}
        />

        <ReportPeriodTable
          data={dataType === MONTHLY ? data.by_month : data.by_day}
          total={data.by_day_total}
          keys={["story_views", "views_unique_users", "story_opens", "opens_unique_users"]}
          showOpens
          totalTooltip
        />
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>{t(`${pageType}.sectionTitle2`)}</SectionTitle>
        <ReportSpecializationChart data={data.by_title_and_spec} keyProp="story_views" type="all" />
        <ReportSpecializationTable
          total={data.by_day_total}
          data={data.by_title_and_spec}
          keys={[
            "translation",
            "story_views",
            "views_unique_users",
            "story_views_per_user",
            "story_opens",
            "opens_unique_users",
          ]}
          type="all"
          defaultSortKey="story_views"
        />
      </SectionContainer>
    </>
  );
}

export default function Story() {
  const { t } = useTranslation();
  const { id: idFromParams } = useParams<{ id: string }>();
  const id: number = React.useMemo(() => Number.parseInt(idFromParams!, 10), [idFromParams]);
  const { selectedPeriod } = React.useContext(PagePeriodFilterContext);
  const { type: dataType } = React.useContext(DataTypeContext);

  const { data: stories } = useQuery<NewsResponse[]>([ApiQueryKeys.newsStaticData], NewsApi.newsStaticData);

  const { refetch, data, isFetching, isError } = useQuery<StoriesReportResponseSchema>(
    [ApiQueryKeys.getNewsReport, id, selectedPeriod],
    () =>
      NewsApi.getNewsReport({
        id,
        dateFrom: selectedPeriod.from,
        dateUntil: selectedPeriod.until,
      }),
    { enabled: !!id }
  );

  const exportPDF = () => {
    getReportPDFExport({
      type: "news",
      slug: id!,
      from: selectedPeriod!.from,
      until: selectedPeriod!.until,
      pdf_time_interval: dataType === MONTHLY ? "month" : "day",
    });
  };

  const reportHeaderProps = React.useMemo(() => {
    const selectedStory = stories?.find((story) => story.id === id);

    return {
      title: selectedStory?.title,
      from: selectedStory?.published_from,
      until: selectedStory?.published_until,
    };
  }, [stories, id]);

  React.useEffect(() => {
    refetch();
  }, [refetch, selectedPeriod]);

  const showEmptyState = React.useMemo(() => {
    if (isFetching && !data) {
      return false;
    }

    return _isEmpty(data?.by_day);
  }, [data, isFetching]);

  return (
    <ReportPage
      reportHeaderProps={reportHeaderProps}
      showEmptyState={showEmptyState}
      isFetching={isFetching}
      isError={isError}
      exportPDF={exportPDF}
      title={t(`${pageType}.title`)}
    >
      <WithLoadingCard isLoading={isFetching && !data}>
        <Report data={data} dataType={dataType} show={!showEmptyState} />
      </WithLoadingCard>
    </ReportPage>
  );
}
