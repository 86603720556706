import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import Dashboard from "../Dashboard";
import LandingPage from "../LandingPage";

export default function Home() {
  const { user, initialUserFetchDone, redirectUrl } = React.useContext(UserContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user && pathname.includes("auth")) {
      navigate(redirectUrl || "/");
    }
  }, [user, redirectUrl, navigate, pathname]);

  if (!initialUserFetchDone || user) {
    return <Dashboard />;
  }
  return <LandingPage />;
}
