import React from "react";
import { useTable, useSortBy, Column, useGlobalFilter, TableState, useExpanded } from "react-table";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ExportContentTypeEnum } from "../../api/utils";
import * as Styled from "./Table.styled";
import { MdsPerSpecExportPropsI } from "../../pages/MdsPerSpecialization/types";
import { OrderByColumn } from "../../api";
import ExportDropdown from "./ExportDropdown/ExportDropdown";
import { SubtleSubtitle } from "../UiKit/Typography";

interface ITable {
  columns: Column[];
  data: any[];
  withGlobalFilter?: boolean;
  globalFilterPlaceholder?: string;
  withExportButton?: boolean;
  disableExport?: boolean;
  setContentType?: (data: any) => void;
  setMdsPerSpecExportProps?: (data: MdsPerSpecExportPropsI) => void;
  initialState?: Partial<TableState>;
  withFooter?: boolean;
  tableTitle?: string;
  tableSubTitle?: string;
  collapseRows?: boolean;
  renderRowSubComponent?: (row: any) => React.ReactNode;
  horizontallyScrollableCard?: boolean;
}

export default function Table({
  columns,
  data,
  withGlobalFilter,
  globalFilterPlaceholder,
  withExportButton,
  setContentType,
  setMdsPerSpecExportProps,
  initialState,
  withFooter,
  tableTitle,
  tableSubTitle,
  collapseRows,
  renderRowSubComponent,
  horizontallyScrollableCard = true,
}: ITable) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [expandRows, setExpandRows] = React.useState<boolean>(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const searchParam = searchParams.get("search");

    if (globalFilter !== searchParam) {
      setGlobalFilter(searchParam || "");
    }
  }, [searchParams, data, setGlobalFilter, globalFilter]);

  const handleClearSearch = React.useCallback(() => {
    setGlobalFilter("");
    setSearchParams({ search: "" });
  }, [setGlobalFilter, setSearchParams]);

  const getCellProps = React.useCallback(
    (cell) => ({
      className: cell.column.id,
      style: cell.column.style,
    }),
    []
  );

  const onExportClick = React.useCallback(
    (contentType: ExportContentTypeEnum) => {
      if (setContentType) {
        setContentType(contentType);
      }
      if (setMdsPerSpecExportProps) {
        const [{ headers }] = headerGroups;
        const sortedHeader = headers.find(({ isSorted }) => isSorted);
        setMdsPerSpecExportProps({
          contentType,
          ascending: !sortedHeader?.isSortedDesc,
          orderBy: sortedHeader?.id as OrderByColumn,
        });
      }
    },
    [setContentType, setMdsPerSpecExportProps, headerGroups]
  );

  return (
    <Styled.ScrollWrapper $horizontallyScrollableCard={horizontallyScrollableCard}>
      <Styled.Container $horizontallyScrollableCard={horizontallyScrollableCard}>
        {(withGlobalFilter || withExportButton || tableTitle) && (
          <Styled.ActionContainer>
            <div>
              {tableTitle && <Styled.TableTitle $withSubtitle={!!tableSubTitle}>{tableTitle}</Styled.TableTitle>}
              {tableSubTitle && <SubtleSubtitle>{tableSubTitle}</SubtleSubtitle>}
              <Styled.InputsContainer>
                {withGlobalFilter && (
                  <Styled.FormControlContainer>
                    <Styled.SearchIcon />
                    <Styled.FormControl
                      placeholder={globalFilterPlaceholder || t("common.table.searchPlaceholder")}
                      type="text"
                      value={globalFilter || ""}
                      onChange={(e: any) => {
                        setGlobalFilter(e.target.value);
                        setSearchParams({ search: e.target.value });
                      }}
                    />
                    {!!globalFilter && <Styled.ClearGlobalFilter onClick={handleClearSearch} />}
                  </Styled.FormControlContainer>
                )}
              </Styled.InputsContainer>
            </div>
            <ExportDropdown onExportClick={onExportClick} show={withExportButton} />
          </Styled.ActionContainer>
        )}
        <Styled.TableWrapper>
          <Styled.Table {...getTableProps()} width="100%" className="table-condensed table dynamic-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                      className={column.id}
                    >
                      {column.render("Header")}
                      <Styled.SortIconsContainer>
                        {column.isSorted && column.isSortedDesc && <i className="bi bi-caret-down-fill" />}
                        {column.isSorted && !column.isSortedDesc && <i className="bi bi-caret-up-fill" />}
                      </Styled.SortIconsContainer>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);

                if (collapseRows && !expandRows) {
                  if (index > 10) {
                    return null;
                  }
                  if (index === 10) {
                    return (
                      <tr>
                        <td colSpan={columns.length} style={{ textAlign: "center" }}>
                          <Styled.Expand onClick={() => setExpandRows(true)}>
                            {t("common.table.showHiddenRows")}
                          </Styled.Expand>
                        </td>
                      </tr>
                    );
                  }
                }

                return (
                  <>
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps(getCellProps(cell))}>{cell.render("Cell")}</td>
                      ))}
                    </tr>

                    {row.isExpanded && renderRowSubComponent && <>{renderRowSubComponent({ row })}</>}
                  </>
                );
              })}
            </tbody>
            {withFooter && (
              <Styled.TableFooter>
                {footerGroups &&
                  footerGroups.map((group) => (
                    <tr {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <td {...column.getFooterProps({ className: column.id })}>{column.render("Footer")}</td>
                      ))}
                    </tr>
                  ))}
              </Styled.TableFooter>
            )}
          </Styled.Table>
        </Styled.TableWrapper>
      </Styled.Container>
    </Styled.ScrollWrapper>
  );
}
