import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LIST_PAGES } from "./utils";
import { ReactComponent as DividerIcon } from "../../../assets/icons/breadcrumbs-divider.svg";
import { FlexCenterCSS } from "../../UiKit/Helpers/StyledCss";

const BreadcrumbsWrapper = styled.div`
  ${FlexCenterCSS}
  gap: 8px;
  margin-bottom: 12px;
  font-size: 12px;

  a {
    display: flex;
    text-decoration: none;
    color: ${(props) => props.theme.text2};
    fill: ${(props) => props.theme.text2};

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.blueSuperDark};
      fill: ${(props) => props.theme.blueSuperDark};
    }
  }
`;

const SelectedRoute = styled.div`
  color: ${(props) => props.theme.blueSuperDark};
`;

function Crumb({ index, routes, route }: { index: number; routes: string[]; route: string }) {
  const { t } = useTranslation();

  if (index === routes.length - 1) {
    if (LIST_PAGES.includes(routes[index - 1])) {
      return <SelectedRoute>{t(`navigation.${routes[index - 1]}Child`)}</SelectedRoute>;
    }
    return <SelectedRoute>{t(`navigation.${route}`)}</SelectedRoute>;
  }
  return <Link to={`/${route}`}>{t(`navigation.${route}`)}</Link>;
}

export default function NavigationBreadcrumbs({ hide }: { hide?: boolean }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const routes = React.useMemo(() => pathname.split("/").filter(Boolean), [pathname]);

  if (hide) {
    return null;
  }
  return (
    <BreadcrumbsWrapper>
      <Link to="/">{t("navigation.home")}</Link>

      {routes.map((route: string, index) => (
        <React.Fragment key={route}>
          <DividerIcon />
          <Crumb index={index} routes={routes} route={route} />
        </React.Fragment>
      ))}
    </BreadcrumbsWrapper>
  );
}
