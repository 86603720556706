import { AdResponse, NewsResponse, CmeResponse, ToolWIthAdResponse } from ".";
import { AdzApi } from "./services/AdzApi";
import { CmeApi } from "./services/CmeApi";
import { NewsApi } from "./services/NewsApi";
import { ToolsApi } from "./services/ToolsApi";

export type ReportsListsStaticData = [AdResponse[], NewsResponse[], CmeResponse[], ToolWIthAdResponse[]];

export const ReportsListsStaticDataApi = (): Promise<ReportsListsStaticData> =>
  Promise.all([AdzApi.adsStaticData(), NewsApi.newsStaticData(), CmeApi.cmesStaticData(), ToolsApi.toolsStaticData()]);
