import React from "react";
import styled from "styled-components";

export const Header = styled.h3`
  margin-bottom: 6px;
  text-transform: uppercase;
  font-size: 10px !important;
  line-height: 14px;
  font-weight: 400;
  font-size: inherit;
`;

export const FooterColl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface FooterColumnI {
  children: React.ReactElement | React.ReactElement[];
  title?: string;
}

export default function FooterColumn({ children, title }: FooterColumnI) {
  return (
    <FooterColl>
      {title && <Header>{title}</Header>}
      {children}
    </FooterColl>
  );
}
