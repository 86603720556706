/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddReportResponseSchema } from '../models/AddReportResponseSchema';
import type { AdResponse } from '../models/AdResponse';
import type { AdSummaryReportSchema } from '../models/AdSummaryReportSchema';
import type { ExportAdType } from '../models/ExportAdType';
import type { PdfReportTimeInterval } from '../models/PdfReportTimeInterval';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdzApi {

  /**
   * Get Ads Report
   *  Using `/adz` because ad blockers block endpoints with `ads` in them.
   * @returns AddReportResponseSchema Returns JSON (default) or PDF; dependent on the Accept header.
   * @throws ApiError
   */
  public static getAdsReport({
    id,
    app = 'Drugs',
    country,
    dateFrom = '2021-01-01',
    dateUntil,
    specs,
    showPenetration = false,
    distributeNoSpec = false,
    pdfTimeInterval,
  }: {
    id: number,
    app?: string,
    country?: string,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
    /**
     * Defaults to product set specs.
     */
    specs?: Array<string>,
    showPenetration?: boolean,
    distributeNoSpec?: boolean,
    /**
     * Affects only pdf exports.
     */
    pdfTimeInterval?: PdfReportTimeInterval,
  }): CancelablePromise<AddReportResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/adz/{id}/report`,
      path: {
        'id': id,
      },
      query: {
        'app': app,
        'country': country,
        'date_from': dateFrom,
        'date_until': dateUntil,
        'specs': specs,
        'show_penetration': showPenetration,
        'distribute_no_spec': distributeNoSpec,
        'pdf_time_interval': pdfTimeInterval,
      },
      errors: {
        401: `Missing token or inactive user.`,
        404: `No ad report with specified id.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Ads:Static-Data
   *  Using `/adz` because ad blockers block endpoints with `ads` in them.
   * @returns AdResponse Successful Response
   * @throws ApiError
   */
  public static adsStaticData(): CancelablePromise<Array<AdResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/adz`,
      errors: {
        401: `Missing token or inactive user.`,
      },
    });
  }

  /**
   * Ads:Summary-Reports
   * Summary report data for users ads. Using `/adz` because ad blockers block endpoints with `ads` in them.
   * @returns AdSummaryReportSchema Returns JSON (default), CSV or XLSX; dependent on the Accept header.
   * @throws ApiError
   */
  public static adsSummaryReports({
    adzType,
    ids,
    dateFrom = '2021-01-01',
    dateUntil,
  }: {
    /**
     * **Mandatory for csv/xlsx.** Affects export title and file name. Using `adz` because of ad blockers.
     */
    adzType?: ExportAdType,
    ids?: Array<number>,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
  }): CancelablePromise<Array<AdSummaryReportSchema>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/adz/reports`,
      query: {
        'adz_type': adzType,
        'ids': ids,
        'date_from': dateFrom,
        'date_until': dateUntil,
      },
      errors: {
        401: `Missing token or inactive user.`,
        422: `Validation Error`,
      },
    });
  }

}
