import dayjs from "dayjs";
import { ProductState } from "../api";

const compareDates = (a: string | undefined, b: string | undefined) => (dayjs(a) > dayjs(b) ? -1 : 1);

type Entry = {
  published_from?: string;
  state?: ProductState;
  ad?: { published_from?: string; state?: ProductState };
} & { [key: string]: any };

export const getProductFromEntry = (entry: Entry) => entry.ad || entry;

export const sortByPublishedFromAndState = (a: Entry, b: Entry) => {
  const productA = getProductFromEntry(a);
  const productB = getProductFromEntry(b);

  if (productA.state === ProductState.ACTIVE && productB.state === ProductState.ACTIVE) {
    return compareDates(productA.published_from, productB.published_from);
  }

  if (productA.state === ProductState.ACTIVE) {
    return -1;
  }
  if (productB.state === ProductState.ACTIVE) {
    return 1;
  }

  return compareDates(productA.published_from, productB.published_from);
};
