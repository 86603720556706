import { ExportAdType } from "./models/ExportAdType";
import { OrderByColumn } from "./models/OrderByColumn";
import { ExportContentTypeEnum, exportsFetcher } from "./utils";

export const getAdsReportsListExport = (
  exportContentType: ExportContentTypeEnum,
  ids: string[],
  from: string,
  until: string,
  ad_type: ExportAdType
) =>
  exportsFetcher(
    `${process.env.REACT_APP_API_SERVER}/adz/reports?date_from=${from}&date_until=${until}&adz_type=${ad_type}`, // Using adz_type because of ad blocker
    exportContentType,
    ids
  );

export const getCmesReportsListExport = (
  exportContentType: ExportContentTypeEnum,
  ids: string[],
  from: string,
  until: string
) =>
  exportsFetcher(
    `${process.env.REACT_APP_API_SERVER}/cme/reports?date_from=${from}&date_until=${until}`,
    exportContentType,
    ids
  );

export const getStoriesReportsListExport = (
  exportContentType: ExportContentTypeEnum,
  ids: string[],
  from: string,
  until: string
) =>
  exportsFetcher(
    `${process.env.REACT_APP_API_SERVER}/news/reports?date_from=${from}&date_until=${until}`,
    exportContentType,
    ids
  );

export const getToolsReportsListExport = (
  exportContentType: ExportContentTypeEnum,
  ids: string[],
  from: string,
  until: string
) =>
  exportsFetcher(
    `${process.env.REACT_APP_API_SERVER}/tools/reports?date_from=${from}&date_until=${until}`,
    exportContentType,
    ids
  );

export const getMdsPerSpecListExport = ({
  exportContentType,
  from,
  until,
  country,
  orderBy,
  ascending,
}: {
  exportContentType: ExportContentTypeEnum;
  from: string;
  until: string;
  country: string;
  orderBy?: OrderByColumn;
  ascending?: boolean;
}) => {
  let url = `${process.env.REACT_APP_API_SERVER}/specializations/mds_per_specialization?country=${country}&date_from=${from}&date_until=${until}`;

  if (orderBy) {
    url += `&order_by=${orderBy}`;

    if (typeof ascending === "boolean") {
      url += `&ascending=${ascending}`;
    }
  }

  return exportsFetcher(url, exportContentType);
};
