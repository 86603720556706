/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductType {
  AD = 'ad',
  NEWS = 'news',
  TOOL = 'tool',
  CME = 'cme',
}
