import styled from "styled-components";
import { ReactComponent as CompleteSvg } from "../../../assets/icons/auth/signup-complete.svg";
import UiKitButton from "../../../components/UiKit/Button";

export const CompleteFigure = styled(CompleteSvg)`
  margin-bottom: 52px;
`;

export const Button = styled(UiKitButton)`
  margin-top: 90px;
`;
