import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DrugsApi, DrugSearchTrendsSchema } from "../../api";
import { ApiQueryKeys } from "../../api/ApiQueryKeys";
import HeaderWithBreadcrumbs from "../../components/Layout/HeaderWithBreadcrumbs";
import PagePeriodFilter from "../../components/PagePeriodFilter/PagePeriodFilter";
import { PagePeriodFilterContext } from "../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { PageFilterContainer } from "../../components/ReportPageComponents/StyledComponents";
import Container from "../../components/UiKit/Container";
import { MainContentWrapper } from "../../components/UiKit/Helpers/StyledCss";
import { Select } from "../../components/UiKit/Select";
import { UserContext } from "../../contexts/UserContext";
import useCountryOptions from "../../utils/hooks/useCountryOptions";
import DrugsTable from "./DrugsTable";
import useSpecializationOptions from "./useSpecializationOptions";

type Form = {
  country: string;
  specialization: string | null;
};

export default function Drugs() {
  const { t } = useTranslation();
  const { user } = React.useContext(UserContext);
  const { dateRangeReadable, selectedPeriod } = React.useContext(PagePeriodFilterContext);

  const { control, watch, setValue } = useForm<Form>({
    defaultValues: {
      country: user?.country || "SI",
      specialization: null,
    },
  });

  const selectedCountry = watch("country");
  const selectedSpecialization = watch("specialization");
  const countryOptions = useCountryOptions();
  const { specializationOptions } = useSpecializationOptions(selectedCountry);

  React.useEffect(() => {
    /*
     * When switching countries, you can hit a country that doesn't have the selected specialization
     */
    if (specializationOptions.length && !specializationOptions.find((s) => s.value === selectedSpecialization)) {
      setValue("specialization", null);
    }
  }, [selectedCountry, selectedSpecialization, setValue, specializationOptions]);

  const { data, isFetching } = useQuery<DrugSearchTrendsSchema>(
    [ApiQueryKeys.getTopDrugsSearch, selectedCountry, selectedSpecialization, selectedPeriod],
    () =>
      DrugsApi.getTopDrugsSearch({
        country: selectedCountry,
        specs: selectedSpecialization ? [selectedSpecialization] : [],
        dateFrom: selectedPeriod.from,
        dateUntil: selectedPeriod.until,
      })
  );

  const tableSubTitle = React.useMemo(() => {
    const spec = specializationOptions.find((s) => s.value === selectedSpecialization);

    return `${dateRangeReadable} in ${countryOptions.find((c) => c.value === selectedCountry)!.label} for ${
      spec?.label
    } `;
  }, [countryOptions, dateRangeReadable, selectedCountry, selectedSpecialization, specializationOptions]);

  return (
    <MainContentWrapper>
      <HeaderWithBreadcrumbs title={t("drugSearchTrends.title")} subtitle={t("drugSearchTrends.subTitle")} />

      <PageFilterContainer>
        <PagePeriodFilter />
        <Select options={countryOptions} name="country" control={control} $forCountry />
        <Select options={specializationOptions} name="specialization" control={control} />
      </PageFilterContainer>

      <Container>
        <DrugsTable isFetching={isFetching} data={data} tableSubTitle={tableSubTitle} />
      </Container>
    </MainContentWrapper>
  );
}
