import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as UserSvg } from "../../../assets/icons/nav-user.svg";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import { User } from "../../../contexts/UserContext";
import { SpecializationSettingsModalContext } from "../../Modals/SpecializationSettingsModal/SpecializationSettingsModalContext";
import * as Styled from "./UserDropdown.styled";

type UserDropdownProps = {
  user: User | null;
  logout: () => void;
};

export default function UserDropdown({ user, logout }: UserDropdownProps) {
  const { openModal } = React.useContext(SpecializationSettingsModalContext);
  const [showUserDropdown, setShowUserDropdown] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const { t } = useTranslation();

  useOutsideClick(dropdownRef, () => {
    setShowUserDropdown(false);
  });

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.nativeEvent.stopPropagation();
    setShowUserDropdown((val) => !val);
  };

  if (!user) {
    return null;
  }
  return (
    <Styled.DDWrapper>
      <Styled.DropdownButton data-cy-nav-user-dd onClick={toggleDropdown} $active={showUserDropdown}>
        <UserSvg />
      </Styled.DropdownButton>
      <Styled.DropdownItem ref={dropdownRef} $active={showUserDropdown}>
        {/* User email */}
        <Styled.DDSection $forUserData>
          <Styled.SectionTitle>{t("navigation.user")}</Styled.SectionTitle>
          {user.email}
        </Styled.DDSection>

        {/* Settings */}
        <Styled.DDSection>
          <Styled.DDButton
            onClick={() => {
              openModal();
              setShowUserDropdown(false);
            }}
          >
            {t("navigation.settings")}
          </Styled.DDButton>
        </Styled.DDSection>

        {/* Logout */}
        <Styled.DDSection>
          <Styled.DDButton
            onClick={() => {
              logout();
              setShowUserDropdown(false);
            }}
          >
            {t("navigation.logout")}
          </Styled.DDButton>
        </Styled.DDSection>
      </Styled.DropdownItem>
    </Styled.DDWrapper>
  );
}
