import styled from "styled-components";
import SearchInputComponent from "../../form/SearchInput";
import SpecializationsSelectorComponent from "../../SpecializationsSelector";
import UiKitCard from "../../UiKit/Card";
import DialogFooterComponent from "../../UiKit/DialogFooter";

export const SearchInput = styled(SearchInputComponent)`
  margin-bottom: 20px;
`;

export const Card = styled(UiKitCard)`
  position: relative;
  min-width: 400px;
  padding-bottom: 0;
`;

export const SpecializationsSelector = styled(SpecializationsSelectorComponent)`
  margin-bottom: 20px;

  > div {
    min-height: 340px;
    max-height: 340px;
  }
`;

export const DialogFooter = styled(DialogFooterComponent)`
  margin: 0 -20px;
`;
