import React from "react";
import styled from "styled-components";
import device from "../../../theme/utils/breakpoints";
import NavigationBreadcrumbs from "../../Navigation/NavigationBreadcrumbs/NavigationBreadcrumbs";
import WithTooltip from "../../Tooltip/WithTooltip";
import { Heading2 } from "../../UiKit/Typography";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 32px 15px;
  border-bottom: 1px solid ${(props) => props.theme.text4};
  height: 85px;
  background: #fff;

  @media ${device.S} {
    display: block;
    height: auto;
  }
`;

const StHeading2 = styled(Heading2)`
  margin: 0;
  display: inline-block;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export default function HeaderWithBreadcrumbs({
  title,
  subtitle = "",
  hideBreadcrumbs,
}: {
  title: string | React.ReactNode;
  subtitle?: string;
  hideBreadcrumbs?: boolean;
}) {
  return (
    <Wrapper>
      <NavigationBreadcrumbs hide={hideBreadcrumbs} />

      <WithTooltip disableTooltip={!subtitle} id={subtitle} tooltip={subtitle} withoutUnderline place="top">
        <StHeading2>{title}</StHeading2>
      </WithTooltip>
    </Wrapper>
  );
}
