import styled from "styled-components";
import { Heading1 } from "../../../components/UiKit/Typography";

export const Heading = styled(Heading1)`
  max-width: 200px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 20px;
`;

export const Subheader = styled.div<{ $noMargin?: boolean; $textLeft?: boolean }>`
  text-align: center;
  font-size: 14px;
  margin-bottom: 43px;
  ${(props) => props.$noMargin && "margin-bottom: 0"}
  ${(props) => props.$textLeft && "text-align: left"}
`;

export const Note = styled(Subheader)`
  color: ${(props) => props.theme.text2};
  margin-top: 24px;
`;
