import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import Table from "../../../components/Table";
import FormCheckController from "../../../components/form/FormCheckController";
import { useCheckItemsForm } from "../../../utils/hooks";
import { TOOLS_SEARCH_ONLY_KEYS, TOOLS_REPORT_KEYS } from "../utils";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { mixpanelTrack, TrackingConstants } from "../../../libs/mixpanel";
import { ToolWIthAdResponse } from "../../../api";
import { UserContext } from "../../../contexts/UserContext";
import { getToolsReportsListExport } from "../../../api/ReportListExport";
import { PUBLISHED_KEY, STATE_KEY } from "../../../components/Table/DataCell/DataCell";
import DataCell from "../../../components/Table/DataCell";
import TitleCell from "../../../components/Table/TitleCell";
import CellWithInfo from "../../../components/Table/CellWithInfo";

interface IToolsListTable {
  data: Array<ToolWIthAdResponse>;
}

export default function ToolsListTable({ data }: IToolsListTable) {
  const { t } = useTranslation();
  const [exportContentType, setContentType] = React.useState(null);
  const { selectedPeriod } = React.useContext(PagePeriodFilterContext);
  const { user } = React.useContext(UserContext);
  const checkItemsIds = React.useMemo(() => (data ? data.map((item) => item.id) : []), [data]);

  const { handleSubmitClick, control, handleCheckAllChange, disableSubmit, handleItemChange } = useCheckItemsForm(
    (selectedItemsIds: string[]) =>
      getToolsReportsListExport(
        exportContentType!,
        _isEmpty(selectedItemsIds) ? checkItemsIds : selectedItemsIds,
        selectedPeriod?.from!,
        selectedPeriod?.until!
      ),
    checkItemsIds
  );

  const hiddenColumns = React.useMemo(() => {
    let columns = [...TOOLS_SEARCH_ONLY_KEYS];

    if (!user?.permissions?.tool_ad_opens) {
      columns = [...columns, "tool_clicks", "tool_ad_opens_unique_users"];
    }

    return columns;
  }, [user]);

  React.useEffect(() => {
    if (exportContentType !== null) {
      mixpanelTrack(TrackingConstants.ExportToolsList, { "Export content type": exportContentType });
      handleSubmitClick();
      setContentType(null);
    }
  }, [exportContentType, handleSubmitClick]);

  const columns = React.useMemo(
    () => [
      {
        Header: <FormCheckController name="master" control={control} customOnChange={handleCheckAllChange} />,
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const record = row.original;
            return <FormCheckController name={`${record.id}`} control={control} customOnChange={handleItemChange} />;
          }, [row]),
        id: "export",
      },
      {
        Header: t("toolsList.table.toolName"),
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const { name, id } = row.original;
            return <TitleCell title={name} link={`/tools/${id}`} />;
          }, [row]),
        accessor: "name",
      },
      ...[PUBLISHED_KEY, STATE_KEY, ...TOOLS_REPORT_KEYS].map((key) => ({
        Header: (
          <CellWithInfo
            id={`toolsList.table.${key}`}
            value={t(`toolsList.table.${key}`)}
            tooltip={t(`toolsList.table.${key}Tooltip`)}
          />
        ),
        accessor: key,
        Cell: ({ row }: any): any => React.useMemo(() => <DataCell record={row.original} prop={key} />, [row]),
      })),
      {
        Header: (
          <CellWithInfo
            id="toolsList.table.country"
            value={t(`toolsList.table.country`)}
            tooltip={t(`toolsList.table.countryTooltip`)}
          />
        ),
        accessor: "country",
      },
      ...TOOLS_SEARCH_ONLY_KEYS.map((key) => ({ accessor: key })),
    ],
    [control, handleCheckAllChange, handleItemChange, t]
  );

  const tableTitle = React.useMemo(() => t("toolsList.table.title"), [t]);

  if (!data) {
    return null;
  }
  return (
    <Table
      columns={columns}
      data={data}
      tableTitle={tableTitle}
      withGlobalFilter
      withExportButton
      disableExport={disableSubmit}
      setContentType={setContentType}
      initialState={{ hiddenColumns }}
    />
  );
}
