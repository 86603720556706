import React from "react";
import styled from "styled-components";
import device from "../../../theme/utils/breakpoints";
import { ReactComponent as BurgerSvg } from "../../../assets/icons/nav/burger.svg";
import { ReactComponent as BurgerCloseSvg } from "../../../assets/icons/nav/burger-close.svg";
import { UserContext } from "../../../contexts/UserContext";

export const StyledBurger = styled.button<{ $navOpened?: boolean }>`
  position: absolute;
  top: 15px;
  right: 14px;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  z-index: 10;

  @media ${device.L} {
    display: flex;
  }
`;

function BurgerButton({ onClick, navOpened }: { onClick: any; navOpened: boolean }) {
  const { user } = React.useContext(UserContext);

  if (!user) {
    return null;
  }
  return (
    <StyledBurger onClick={onClick} $navOpened={navOpened}>
      {navOpened ? <BurgerCloseSvg /> : <BurgerSvg />}
    </StyledBurger>
  );
}

export default BurgerButton;
