import React from "react";
import styled from "styled-components";
import FormControlController, { IFormControlController } from "./FormControlController";
import { ReactComponent as ShowPass } from "../../assets/icons/auth/show-pass.svg";
import { ReactComponent as HidePass } from "../../assets/icons/auth/hide-pass.svg";
import { FlexCenterCSS } from "../UiKit/Helpers/StyledCss";

const Container = styled.div`
  position: relative;
`;

export const TogglePasswordWrapper = styled.div`
  ${FlexCenterCSS}
  color: ${(props) => props.theme.bluePrimary};
  position: absolute;
  top: 22px;
  transform: translateY(50%);
  right: 12px;
  font-size: 10px;
  line-height: 1.4;
  gap: 5px;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  svg {
    fill: ${(props) => props.theme.bluePrimary};
  }
`;

export default function PasswordController(props: IFormControlController) {
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = React.useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);

  const type = React.useMemo(() => (showPassword ? "text" : "password"), [showPassword]);

  return (
    <Container>
      <FormControlController {...props} type={type} passwordController />

      <TogglePasswordWrapper onClick={toggleShowPassword}>
        {showPassword ? <HidePass /> : <ShowPass />}
      </TogglePasswordWrapper>
    </Container>
  );
}
