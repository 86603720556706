import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 60px 0 110px;
`;

export const ContentStyle = styled.div`
  h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 27px;
  }

  img {
    max-width: 100%;
  }

  * + h2 {
    margin-top: 52px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -20px;
      right: -20px;
      top: -20px;
      border-top: ${(props) => props.theme.lightBorder};
    }
  }

  ol {
    padding-left: 19px;
    margin-bottom: 10px;

    + p {
      padding-left: 19px;
    }
  }

  b,
  strong {
    font-weight: 600;
  }
`;
