import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FooterColumn from "./FooterColumn";

export default function FooterInsightsSection() {
  const { t } = useTranslation();

  return (
    <FooterColumn title={t("footer.insights.header")}>
      <Link to="/faq" target="_blank" rel="noopener noreferrer">
        {t("footer.insights.help")}
      </Link>
      <a href="https://mediately.co/business/en/cookie-policy">{t("footer.insights.cookiePolicy")}</a>
      <a href="https://mediately.co/business/en/privacy-policy/">{t("footer.insights.privacyPolicy")}</a>
    </FooterColumn>
  );
}
