import styled from "styled-components";
import ReactSelectController from "../form/ReactSelectController";

export const Select = styled(ReactSelectController)<{ $forCountry?: boolean }>`
  min-width: 200px;
  width: 200px;

  .react-select__control {
    background: #fff;
  }

  ${({ $forCountry }) => $forCountry && "word-spacing: 8px;"}
`;
