import React from "react";
import { useTranslation } from "react-i18next";
import FooterColumn from "./FooterColumn";

export default function FooterProductsSection() {
  const { t } = useTranslation();

  return (
    <FooterColumn title={t("footer.products.header")}>
      <a href="https://mediately.co/si">{t("footer.products.medicalAssistant")}</a>
      <a href="https://rheumahelper.com/">{t("footer.products.rheumaHelper")}</a>
    </FooterColumn>
  );
}
