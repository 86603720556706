import React from "react";
import { To } from "react-router-dom";
import { TableReportTitleColumn, TableReportTitleLink } from "../../ReportPageComponents/StyledComponents";
import WithTooltip from "../../Tooltip/WithTooltip";

export default function TitleCell({ link, title }: { link: To; title: string }) {
  return (
    <TableReportTitleLink to={link}>
      <WithTooltip id={title.slice(0, 10)} delay={650} tooltip={title} place="top">
        <TableReportTitleColumn>{title}</TableReportTitleColumn>
      </WithTooltip>
    </TableReportTitleLink>
  );
}
