import React from "react";
import { useTranslation } from "react-i18next";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { DEFAULT_FROM_DATE, getDatePeriod, PeriodFilterOptions } from "./utils";
import { PagePeriodFilterContext } from "./PagePeriodFilterContextProvider";
import useOutsideClick from "../../utils/hooks/useOutsideClick";
import * as Styled from "./PagePeriodFilter.styled";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar-icon.svg";
import WithTooltip from "../Tooltip/WithTooltip";
import { formatDate, formatDateRange } from "../../utils/numbers";
import { SubtleButton } from "../UiKit/SubtleButton";
import { DialogFooter } from "../UiKit/DialogFooter";

interface DateSelectorI {
  show: boolean;
  close: () => void;
  onSelect: (range: DateRange) => void;
  limitFromDate?: string;
  active?: boolean;
}

function DateSelector({ show, close, onSelect, active, limitFromDate = DEFAULT_FROM_DATE }: DateSelectorI) {
  const { t } = useTranslation();
  const { periodOption, selectedPeriod } = React.useContext(PagePeriodFilterContext);

  const dropdownRef = React.useRef(null);
  useOutsideClick(dropdownRef, () => {
    close();
  });

  const [range, setRange] = React.useState<DateRange | undefined>(
    selectedPeriod && periodOption === PeriodFilterOptions.CUSTOM_DATE_RANGE
      ? { from: new Date(selectedPeriod.from), to: new Date(selectedPeriod.until) }
      : undefined
  );

  const isConfirmDisabled = React.useMemo(() => !range?.from || !range?.to, [range?.from, range?.to]);
  const formattedFromDate = React.useMemo(() => (range?.from ? formatDate(range.from) : ""), [range?.from]);
  const formattedToDate = React.useMemo(() => (range?.to ? formatDate(range.to) : ""), [range?.to]);

  const { fromDate, toDate } = React.useMemo(
    () => ({ fromDate: new Date(limitFromDate), toDate: new Date() }),
    [limitFromDate]
  );

  React.useEffect(() => {
    if (!active) {
      setRange(undefined);
    }
  }, [active]);

  const onConfirm = () => {
    if (!isConfirmDisabled) {
      close();
      onSelect(range!);
    }
  };

  return (
    <Styled.DateSelectorWrapper $active={show} $openRight ref={dropdownRef}>
      <Styled.DSHeader>
        <input placeholder={t("common.dateSelector.from")} disabled type="text" value={formattedFromDate} />
        <input placeholder={t("common.dateSelector.until")} disabled type="text" value={formattedToDate} />
      </Styled.DSHeader>

      <DayPicker mode="range" fromDate={fromDate} toDate={toDate} selected={range} onSelect={setRange} />

      <DialogFooter>
        <SubtleButton onClick={close}>{t("common.dateSelector.cancel")}</SubtleButton>
        <SubtleButton $confirm $disabled={isConfirmDisabled} onClick={onConfirm}>
          {t("common.dateSelector.save")}
        </SubtleButton>
      </DialogFooter>
    </Styled.DateSelectorWrapper>
  );
}

interface DateDropdownI {
  onSelect: (dayRange: any) => void;
  optionLabel: string;
  tooltip: string;
  limitFromDate?: string;
  active?: boolean;
}

function DateDropdown({ onSelect, active, optionLabel, tooltip, limitFromDate }: DateDropdownI) {
  const [showDatePicker, setShowDatePicker] = React.useState(false);

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.nativeEvent.stopPropagation();
    setShowDatePicker((val) => !val);
  };

  return (
    <Styled.DDWrapper>
      <WithTooltip id="dateSelectorTooltip" tooltip={<span>{tooltip}</span>} withoutUnderline place="bottom">
        <Styled.Option $active={showDatePicker || active} $last onClick={toggleDropdown}>
          <CalendarIcon />
          <span>{optionLabel}</span>
          <Styled.DDArrowIcon $active={showDatePicker} />
        </Styled.Option>
      </WithTooltip>
      <DateSelector
        active={active}
        onSelect={onSelect}
        close={() => setShowDatePicker(false)}
        limitFromDate={limitFromDate}
        show={showDatePicker}
      />
    </Styled.DDWrapper>
  );
}

interface PagePeriodFilterI {
  disabledOptions?: PeriodFilterOptions[];
  limitFromDate?: string;
  allTimeLimited?: boolean;
  children?: React.ReactNode;
}

export default function PagePeriodFilter({
  disabledOptions = [],
  limitFromDate = DEFAULT_FROM_DATE,
  allTimeLimited = false,
  children = null,
}: PagePeriodFilterI) {
  const { t } = useTranslation();
  const { periodOption, selectedPeriod, handleSelectChange } = React.useContext(PagePeriodFilterContext);

  const options = React.useMemo(
    () =>
      Object.values(PeriodFilterOptions)
        .map((option: PeriodFilterOptions) => {
          const disabled = disabledOptions.includes(option);
          const label = t(`common.listViewPageFilter.selectOptions.${option}`);
          const mobileLabel = t(`common.listViewPageFilter.selectOptionsMobile.${option}`);

          let tooltip = t("common.listViewPageFilter.disabled");

          if (!disabled) {
            const { from, until } = getDatePeriod(option);

            switch (option) {
              case PeriodFilterOptions.CUSTOM_DATE_RANGE:
                tooltip =
                  periodOption === PeriodFilterOptions.CUSTOM_DATE_RANGE
                    ? formatDateRange(selectedPeriod.from, selectedPeriod.until)
                    : t("common.dateSelector.dateSelectorTooltip");
                break;
              case PeriodFilterOptions.ALL_TIME:
                tooltip = allTimeLimited
                  ? t("common.dateSelector.allTimeLimitedTooltip")
                  : t("common.dateSelector.allTimeTooltip");
                break;
              default:
                tooltip = formatDateRange(from, until);
                break;
            }
          }

          return { option, label, mobileLabel, tooltip, disabled };
        })
        .filter(({ disabled }) => !disabled),
    [t, periodOption, selectedPeriod, disabledOptions, allTimeLimited]
  );

  return (
    <Styled.Container>
      <Styled.FilterContainer data-uf-period-filter>
        {options.map(({ option, label, mobileLabel, tooltip }, index) =>
          option === PeriodFilterOptions.CUSTOM_DATE_RANGE ? (
            <DateDropdown
              key={option}
              optionLabel={label}
              tooltip={tooltip}
              limitFromDate={limitFromDate}
              active={periodOption === PeriodFilterOptions.CUSTOM_DATE_RANGE}
              onSelect={(dayRange) => handleSelectChange(PeriodFilterOptions.CUSTOM_DATE_RANGE, dayRange)}
            />
          ) : (
            <WithTooltip id={label} tooltip={tooltip} key={option} withoutUnderline place="bottom">
              <Styled.Option
                onClick={() => handleSelectChange(option)}
                $active={periodOption === option}
                $position={index}
                $last={index === options.length - 1}
                key={option}
              >
                <Styled.Label $mobile>{mobileLabel}</Styled.Label>
                <Styled.Label>{label}</Styled.Label>
              </Styled.Option>
            </WithTooltip>
          )
        )}
      </Styled.FilterContainer>
      {children}
    </Styled.Container>
  );
}
