import dayjs from "dayjs";
import { DateRange } from "react-day-picker";
import { bugsnagNotify } from "../../libs/bugsnag";

export type Period = {
  from: string;
  until: string;
};

export const DEFAULT_FROM_DATE = "1/1/2021";
export const DEFAULT_FROM_DATE_MD_PER_SPEC = "6/23/2022";

export enum PeriodFilterOptions {
  ALL_TIME = "ALL_TIME",
  LAST_MONTH = "LAST_MONTH",
  D30 = "30_DAYS",
  M3 = "3_MONTHS",
  M6 = "6_MONTHS",
  M12 = "12_MONTHS",
  CUSTOM_DATE_RANGE = "CUSTOM_DATE_RANGE",
}

export enum DateSearchParams {
  FROM = "date_from",
  UNTIL = "date_until",
}

export const getDatePeriod = (value: PeriodFilterOptions, dateRange?: DateRange): Period => {
  let from = dayjs();
  let until = dayjs();

  switch (value) {
    case PeriodFilterOptions.ALL_TIME:
      from = dayjs(DEFAULT_FROM_DATE);
      break;
    case PeriodFilterOptions.LAST_MONTH:
      from = dayjs().subtract(1, "month").startOf("month");
      until = dayjs().subtract(1, "month").endOf("month");
      break;
    case PeriodFilterOptions.D30:
      from = dayjs().subtract(30, "day");
      break;
    case PeriodFilterOptions.M3:
      from = dayjs().subtract(3, "month");
      break;
    case PeriodFilterOptions.M6:
      from = dayjs().subtract(6, "month");
      break;
    case PeriodFilterOptions.M12:
      from = dayjs().subtract(12, "month");
      break;
    case PeriodFilterOptions.CUSTOM_DATE_RANGE:
      if (dateRange) {
        from = dayjs(dateRange.from);
        until = dayjs(dateRange.to);
      }
      break;
    default:
      bugsnagNotify("Unsupported period filter option was passed as value.");
      break;
  }

  return { from: from.format("YYYY-MM-DD"), until: until.format("YYYY-MM-DD") };
};

export const limitDatePeriod = (period: Period, _startingDateLimit: string) => {
  const startingDate = dayjs(period.from);
  const startingDateLimit = dayjs(_startingDateLimit);

  if (startingDate.isBefore(startingDateLimit)) {
    return {
      ...period,
      from: startingDateLimit.format("YYYY-MM-DD"),
    };
  }

  return period;
};

export const getPeriodFromSearchParams = (searchParams: URLSearchParams) => {
  const from = searchParams.get(DateSearchParams.FROM);
  const until = searchParams.get(DateSearchParams.UNTIL);

  if (!from || !until) {
    return null;
  }

  return {
    from,
    until,
  };
};

export const getAllSearchParams = (searchParams: URLSearchParams) => {
  const params: { [key: string]: string } = {};
  searchParams.forEach((val, key) => {
    params[key] = val;
  });

  return params;
};
