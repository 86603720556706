/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveUsersPerCountrySchema } from '../models/ActiveUsersPerCountrySchema';
import type { MDsCardTotalsSchema } from '../models/MDsCardTotalsSchema';
import type { MDSpecializationSchema } from '../models/MDSpecializationSchema';
import type { MDsPerSpecializationSchema } from '../models/MDsPerSpecializationSchema';
import type { OrderByColumn } from '../models/OrderByColumn';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpecializationsApi {

  /**
   * Specializations:Active Users Per Country
   * @returns ActiveUsersPerCountrySchema Successful Response
   * @throws ApiError
   */
  public static specializationsActiveUsersPerCountry({
    dateFrom = '2022-06-23',
    dateUntil,
  }: {
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
  }): CancelablePromise<ActiveUsersPerCountrySchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/specializations/active-users-per-country`,
      query: {
        'date_from': dateFrom,
        'date_until': dateUntil,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Specializations:Mds Per Specialization
   * @returns MDsPerSpecializationSchema Returns JSON (default), CSV or XLSX; dependent on the Accept header.
   * @throws ApiError
   */
  public static specializationsMdsPerSpecialization({
    country,
    dateFrom = '2022-06-23',
    dateUntil,
    orderBy,
    ascending = true,
  }: {
    country: string,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
    /**
     * Affects only exports.
     */
    orderBy?: OrderByColumn,
    /**
     * Affects only exports.
     */
    ascending?: boolean,
  }): CancelablePromise<MDsPerSpecializationSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/specializations/mds_per_specialization`,
      query: {
        'country': country,
        'date_from': dateFrom,
        'date_until': dateUntil,
        'order_by': orderBy,
        'ascending': ascending,
      },
      errors: {
        401: `Missing token or inactive user.`,
        403: `Missing permission.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Specializations:Mds Cards Totals
   * @returns MDsCardTotalsSchema Successful Response
   * @throws ApiError
   */
  public static specializationsMdsCardsTotals(): CancelablePromise<MDsCardTotalsSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/specializations/mds_cards_totals`,
      errors: {
        401: `Missing token or inactive user.`,
        403: `Missing permission.`,
      },
    });
  }

  /**
   * Specializations:Specializations List
   * @returns MDSpecializationSchema Successful Response
   * @throws ApiError
   */
  public static specializationsSpecializationsList({
    country,
  }: {
    country: string,
  }): CancelablePromise<Array<MDSpecializationSchema>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/specializations/specializations_list`,
      query: {
        'country': country,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
