import React from "react";
import _omit from "lodash/omit";
import { useSearchParams } from "react-router-dom";
import { getAllSearchParams } from "../../PagePeriodFilter/utils";

const searchParamName = "specializationSettingsModal";

export const SpecializationSettingsModalContext = React.createContext<{
  showModal: boolean;
  openModal: () => void;
  closeModal: () => void;
}>({
  showModal: false,
  openModal: () => null,
  closeModal: () => null,
});

export default function SpecializationSettingsModalContextProvider({ children }: { children: React.ReactElement }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    const searchParamNameInSearchParams = searchParams.get(searchParamName);
    if ((searchParamNameInSearchParams && !showModal) || (!searchParamNameInSearchParams && showModal)) {
      setShowModal((value) => !value);
    }
  }, [searchParams, setShowModal, showModal]);

  const openModal = React.useCallback(() => {
    setSearchParams({
      ...getAllSearchParams(searchParams),
      ...{ [searchParamName]: "true" },
    });
  }, [searchParams, setSearchParams]);

  const closeModal = React.useCallback(() => {
    setSearchParams(_omit(getAllSearchParams(searchParams), searchParamName));
  }, [searchParams, setSearchParams]);

  const contextValue = React.useMemo(
    () => ({
      showModal,
      openModal,
      closeModal,
    }),
    [showModal, openModal, closeModal]
  );

  return (
    <SpecializationSettingsModalContext.Provider value={contextValue}>
      {children}
    </SpecializationSettingsModalContext.Provider>
  );
}
