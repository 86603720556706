import styled from "styled-components";
import { ProductState } from "../../../api";

export const NumWrapper = styled.span`
  white-space: nowrap;
`;

export const State = styled.div<{ $state: ProductState }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 18px;
  border-radius: 9px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;

  ${(props) => {
    switch (props.$state) {
      case ProductState.ACTIVE:
        return `
          background: #e6f8ee;
          color: #01b662;
        `;
      case ProductState.CONCLUDED:
        return `
          background: #e4e5e5;
          color: #000000;
        `;
      case ProductState.UPCOMING:
        return `
          background: #eaf0fd;
          color: #2d6be7;
        `;
      case ProductState.DISABLED:
        return `
          background: #fff3e5;
          color: #ff8d00;
        `;
      default:
        return null;
    }
  }};
`;
