/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductState {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
  CONCLUDED = 'Concluded',
  UPCOMING = 'Upcoming',
}
