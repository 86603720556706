import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useQuery } from "@tanstack/react-query";
import StoriesListTable from "./StoriesListTable";
import TrafficData from "../../../components/ListViewPartials/TrafficData";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { isInsideTheTimeRange } from "../../../utils/dates";
import { NewsApi, NewsResponse, NewsSummaryReportSchema } from "../../../api";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import WithLoadingCard from "../../../components/UiKit/WithLoadingCard";
import ListPage from "../../../components/Layout/ListPage";
import { sortByPublishedFromAndState } from "../../../utils/sort";

export default function StoriesList() {
  const { periodOption, selectedPeriod } = React.useContext(PagePeriodFilterContext);

  const { data: stories, isFetching: isFetchingStories } = useQuery<NewsResponse[]>(
    [ApiQueryKeys.newsStaticData],
    NewsApi.newsStaticData
  );

  const { data: storiesReports, isFetching: isFetchingStoriesReports } = useQuery<NewsSummaryReportSchema[]>(
    [ApiQueryKeys.newsSummaryReports, selectedPeriod],
    () => NewsApi.newsSummaryReports({ dateFrom: selectedPeriod.from, dateUntil: selectedPeriod.until }),
    { enabled: !!selectedPeriod }
  );

  const data = React.useMemo(() => {
    if (!stories || !storiesReports) {
      return [];
    }

    return stories
      .map((story) => ({
        ...storiesReports.find((report) => report.news_id === story.id),
        ...story,
      }))
      .filter((entry) => isInsideTheTimeRange(entry, selectedPeriod))
      .sort(sortByPublishedFromAndState);
  }, [stories, storiesReports, selectedPeriod]);

  const isFetchingTrafficData = React.useMemo(
    () => isFetchingStoriesReports && !storiesReports,
    [isFetchingStoriesReports, storiesReports]
  );

  const isFetchingTable = React.useMemo(
    () => (isFetchingStories || isFetchingStoriesReports) && !(stories && storiesReports),
    [isFetchingStories, isFetchingStoriesReports, stories, storiesReports]
  );

  const showEmptyState = React.useMemo(() => !isFetchingTable && _isEmpty(data), [isFetchingTable, data]);

  return (
    <ListPage pageType="storiesList" showEmptyState={showEmptyState}>
      <TrafficData
        reportType="stories"
        periodOption={periodOption}
        selectedPeriodReports={storiesReports}
        trafficDataEntryProps={["news_views", "views_unique_users", "news_opens"]}
        isLoading={isFetchingTrafficData}
      />
      <WithLoadingCard isLoading={isFetchingTable}>
        <StoriesListTable data={data} />
      </WithLoadingCard>
    </ListPage>
  );
}
