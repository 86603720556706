import React from "react";
import { PeriodFilterOptions } from "../../components/PagePeriodFilter/utils";
import { useMountEffect } from "../../utils/hooks/useMountEffect";

export const DISABLED_PERIOD_OPTIONS = [
  PeriodFilterOptions.M12,
  PeriodFilterOptions.CUSTOM_DATE_RANGE,
  PeriodFilterOptions.LAST_MONTH,
];

export const MDS_PER_SPECIALIZATION_KEYS: [
  "translation",
  "active_mds",
  "with_email_consent",
  "sessions",
  "sessions_per_active_mds"
] = ["translation", "active_mds", "with_email_consent", "sessions", "sessions_per_active_mds"];

export function isValidPeriod(periodOption: PeriodFilterOptions) {
  return !DISABLED_PERIOD_OPTIONS.includes(periodOption);
}

export const useValidatePeriod = (
  periodOption: PeriodFilterOptions,
  handleSelectChange: (value: PeriodFilterOptions) => void
) => {
  const [wasPeriodValidated, setWasPeriodValidated] = React.useState(false);

  useMountEffect(() => {
    if (!isValidPeriod(periodOption)) {
      handleSelectChange(PeriodFilterOptions.ALL_TIME);
    }
    setWasPeriodValidated(true);
  });

  return wasPeriodValidated;
};
