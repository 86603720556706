import React from "react";
import styled from "styled-components";
import WithTooltip from "../../Tooltip/WithTooltip";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon.svg";

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  svg {
    color: ${(props) => props.theme.text3};
  }
`;

const InfoIconWrapper = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
`;

export default function CellWithInfo({
  id,
  value,
  tooltip,
  withoutUnderline = true,
}: {
  id: string;
  value: string;
  tooltip: string;
  withoutUnderline?: boolean;
}) {
  return (
    <WithTooltip id={id} tooltip={tooltip} withoutUnderline={withoutUnderline} place="top">
      <Wrapper>
        <span>{value}</span>
        <InfoIconWrapper>
          <InfoIcon />
        </InfoIconWrapper>
      </Wrapper>
    </WithTooltip>
  );
}
