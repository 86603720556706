/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsResponse } from '../models/NewsResponse';
import type { NewsSummaryReportSchema } from '../models/NewsSummaryReportSchema';
import type { PdfReportTimeInterval } from '../models/PdfReportTimeInterval';
import type { StoriesReportResponseSchema } from '../models/StoriesReportResponseSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NewsApi {

  /**
   * Get News Report
   * @returns StoriesReportResponseSchema Returns JSON (default) or PDF; dependent on the Accept header.
   * @throws ApiError
   */
  public static getNewsReport({
    id,
    app = 'Drugs',
    country,
    dateFrom = '2021-01-01',
    dateUntil,
    specs,
    showPenetration = false,
    distributeNoSpec = false,
    pdfTimeInterval,
  }: {
    id: number,
    app?: string,
    country?: string,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
    /**
     * Defaults to product set specs.
     */
    specs?: Array<string>,
    showPenetration?: boolean,
    distributeNoSpec?: boolean,
    /**
     * Affects only pdf exports.
     */
    pdfTimeInterval?: PdfReportTimeInterval,
  }): CancelablePromise<StoriesReportResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/news/{id}/report`,
      path: {
        'id': id,
      },
      query: {
        'app': app,
        'country': country,
        'date_from': dateFrom,
        'date_until': dateUntil,
        'specs': specs,
        'show_penetration': showPenetration,
        'distribute_no_spec': distributeNoSpec,
        'pdf_time_interval': pdfTimeInterval,
      },
      errors: {
        401: `Missing token or inactive user.`,
        404: `No news report with specified id.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * News:Static-Data
   * @returns NewsResponse Successful Response
   * @throws ApiError
   */
  public static newsStaticData(): CancelablePromise<Array<NewsResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/news`,
      errors: {
        401: `Missing token or inactive user.`,
      },
    });
  }

  /**
   * News:Summary-Reports
   * Summary report data for users news.
   * @returns NewsSummaryReportSchema Returns JSON (default), CSV or XLSX; dependent on the Accept header.
   * @throws ApiError
   */
  public static newsSummaryReports({
    ids,
    dateFrom = '2021-01-01',
    dateUntil,
  }: {
    ids?: Array<number>,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
  }): CancelablePromise<Array<NewsSummaryReportSchema>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/news/reports`,
      query: {
        'ids': ids,
        'date_from': dateFrom,
        'date_until': dateUntil,
      },
      errors: {
        401: `Missing token or inactive user.`,
        422: `Validation Error`,
      },
    });
  }

}
