import React from "react";
import styled from "styled-components";
import { FlexCenterCSS } from "../UiKit/Helpers/StyledCss";
import { ReactComponent as EmptyStateIcon } from "../../assets/icons/empty-state.svg";
import { ReactComponent as ErrorStateIcon } from "../../assets/icons/error-state.svg";

const Wrapper = styled.div`
  ${FlexCenterCSS};
  justify-content: center;
  padding: 165px 30px;
`;

const InnerWrapper = styled.div`
  ${FlexCenterCSS};
  flex-direction: column;
  max-width: 320px;
  color: #6c6b98;
  text-align: center;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-top: 44px;
`;

const Subtitle = styled.div`
  font-weight: 300;
  margin-top: 18px;
`;

interface ReportEmptyStateI {
  title: string;
  subtitle: string;
  show?: boolean;
  type?: "error" | "empty";
}

export default function EmptyState({ title, subtitle, show, type = "empty" }: ReportEmptyStateI) {
  if (!show) {
    return null;
  }
  return (
    <Wrapper>
      <InnerWrapper>
        {type === "empty" ? <EmptyStateIcon /> : <ErrorStateIcon />}
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </InnerWrapper>
    </Wrapper>
  );
}
