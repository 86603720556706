import React from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
import styled, { css } from "styled-components";
import { ButtonV2ColorsCss } from "../UiKit/Button";
import FormGroupWrapper from "./FormGroupWrapper";

const SelectContainer = styled.div<{ $invalid: boolean }>`
  & .react-select__control {
    ${ButtonV2ColorsCss}
    border-radius: 12px;
    border-color: ${(props) => props.theme.text4};

    &:hover {
      border-color: ${(props) => props.theme.text4};
    }
  }

  .react-select__placeholder {
    font-weight: 300;
    color: inherit;
  }

  .react-select__input {
    &:focus {
      box-shadow: none;
    }
  }

  .react-select__control--is-focused {
    box-shadow: none;
    border-color: ${(props) => props.theme.text4};
    background: ${(props) => props.theme.blueFair50};

    &:active {
      border-color: ${(props) => props.theme.text4};
    }

    &:hover {
      background: ${(props) => props.theme.blueFair50};
    }
  }

  .react-select__single-value {
    color: inherit;
  }

  .react-select__value-container {
    padding: 2px 8px 2px 14px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding: 8px 10px 8px 8px;
  }
  .react-select__indicator {
    color: inherit;
    transition: none;

    &:hover {
      color: inherit;
    }
  }

  ${(props: any) =>
    props.$invalid &&
    css`
      & .react-select__control {
        border: 1px #dc3545 solid !important;
      }
    `}
`;

interface IReactSelectController {
  options: Array<any>;
  className?: string;
  name: string;
  control?: Control<any>;
  customOnChange?: (value: any) => any;
  label?: string;
  text?: string;
  placeholder?: string;
  isMulti?: boolean;
  required?: boolean;
}

type Option = { label: string; value: string };

export default function ReactSelectController({
  className,
  control,
  customOnChange,
  name,
  label,
  text,
  options,
  isMulti,
  placeholder,
  required,
}: IReactSelectController) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, fieldState: { invalid, error } }) => {
        const handleSingleChange = (targetValue: Option) => {
          onChange(targetValue.value);
          if (customOnChange) {
            customOnChange(targetValue.value);
          }
        };
        const handleMultiChange = (targetValue: Array<Option>) => {
          onChange(targetValue.map((x) => x.value));
          if (customOnChange) {
            customOnChange(targetValue.map((x) => x.value));
          }
        };
        const handleChange = isMulti ? handleMultiChange : handleSingleChange;
        const resolvedValue = isMulti
          ? options.filter((obj) => value.includes(obj.value))
          : options.filter((o) => o.value === value);
        return (
          <FormGroupWrapper
            className={className}
            text={text}
            invalid={invalid}
            label={label}
            error={error}
            required={required}
          >
            <SelectContainer $invalid={invalid}>
              <Select
                classNamePrefix="react-select"
                onChange={handleChange}
                value={resolvedValue}
                menuPlacement="auto"
                ref={ref}
                options={options}
                isMulti={isMulti}
                placeholder={placeholder}
              />
            </SelectContainer>
          </FormGroupWrapper>
        );
      }}
    />
  );
}
