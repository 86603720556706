import styled from "styled-components";
import device from "../../theme/utils/breakpoints";

const Container = styled.div`
  max-width: calc(${(props) => props.theme.containerMaxWidth} + 60px);
  padding: 0 32px;
  margin: 0 auto;
  width: 100%;

  @media ${device.S} {
    padding: 0 20px;
  }
`;

export default Container;
