import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { FlexCenterCSS } from "../../../components/UiKit/Helpers/StyledCss";
import { RegisterSteps } from "./types";
import { ReactComponent as CheckMark } from "../../../assets/icons/auth/check-mark.svg";

const badgeCSS = css`
  background: ${(props) => props.theme.text4};
  color: ${(props) => props.theme.text2};
`;

const doneBadgeCSS = css`
  background: ${(props) => props.theme.bluePrimary};
  color: #fff;
`;

const activeBadgeCSS = css`
  background: ${(props) => props.theme.blueFair};
  color: ${(props) => props.theme.bluePrimary};
`;

const StepBadge = styled.div<{ active?: boolean; done?: boolean }>`
  ${FlexCenterCSS}
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  z-index: 1;
  font-weight: 600;
  font-size: 14px;

  ${(props) => {
    if (props.active) {
      return activeBadgeCSS;
    }
    if (props.done) {
      return doneBadgeCSS;
    }
    return badgeCSS;
  }};
`;

const StepWrapper = styled.div`
  ${FlexCenterCSS}
  flex-direction: column;
  gap: 7px;
  width: 86px;
  height: 65px;
`;
const StepTitle = styled.div<{ active?: boolean }>`
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.active ? props.theme.bluePrimary : props.theme.text2)};
`;

const Line = styled.div<{ done?: boolean }>`
  height: 2px;
  background: ${(props) => (props.done ? props.theme.bluePrimary : props.theme.text4)};
  z-index: 0;
  width: 82px;
  align-self: flex-start;
  margin-top: 12px;
  margin: 12px -40px 0;
`;

function StepIndicator({ step, active, done }: { step: number; active?: boolean; done?: boolean }) {
  const { t } = useTranslation();

  return (
    <StepWrapper>
      <StepBadge done={done} active={active}>
        {done ? <CheckMark /> : step}
      </StepBadge>
      <StepTitle active={active || done}>{t(`auth.register.step${step}.stepTitle`)}</StepTitle>
    </StepWrapper>
  );
}

const Container = styled.div`
  ${FlexCenterCSS}
  justify-content: space-between;
  width: 100%;
  padding: 0 25px;
  margin-bottom: 28px;
`;

export default function Progress({ step }: { step: RegisterSteps }) {
  return (
    <Container>
      {[1, 2, 3].map((s, index, array) => (
        <React.Fragment key={s}>
          <StepIndicator step={s} active={step === s} done={step > s} />
          {index < array.length - 1 && <Line done={step > s} />}
        </React.Fragment>
      ))}
    </Container>
  );
}
