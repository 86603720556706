import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CHART_COLORS, CHART_COLORS_SECONDARY } from "../../utils/constants";
import { DataTypeContext, MONTHLY } from "./DataTypeSelectionContext";
import { getChartOptions } from "./utils";

const ChartContainer = styled.div`
  height: 350px;
  width: 100%;
`;

const Subtitle = styled.div`
  margin-left: 32px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin: 12px 32px;
  font-size: 13px;
`;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface ReportChartInterface {
  reportType: "ads" | "stories" | "cmes" | "tools";
  keys: Array<string>;
  data?: { [key: string]: any };
  hide?: boolean;
  subtitle?: string;
  secondaryColors?: boolean;
}

export default function ReportPeriodChart({
  data,
  reportType,
  keys,
  hide,
  subtitle,
  secondaryColors,
}: ReportChartInterface) {
  const { t } = useTranslation();
  const { type: dataType } = React.useContext(DataTypeContext);
  const options = React.useMemo(() => getChartOptions(data), [data]);

  const chartData = React.useMemo(() => {
    if (!data) {
      return null;
    }

    const sortedKeys = Object.keys(data).sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
    const colors = secondaryColors ? CHART_COLORS_SECONDARY : CHART_COLORS;

    return {
      labels: sortedKeys.map((key) => dayjs(key).format(dataType === MONTHLY ? "MMM YYYY" : "DD.MM.YYYY")),
      datasets: keys
        .filter((key) => Object.values(data)[0]?.[key] !== undefined)
        .map((key, index) => ({
          backgroundColor: colors[index],
          label: t(`${reportType}.graph.${key}`),
          data: sortedKeys.reduce((acc: Array<number>, curr) => [...acc, data[curr][key]], []),
        })),
    };
  }, [data, t, reportType, dataType, keys, secondaryColors]);

  const isChartEmpty = chartData?.datasets.every((dataset: any) => dataset.data.length === 0);

  if (!chartData || isChartEmpty || hide) {
    return null;
  }
  return (
    <div>
      <ChartContainer>
        <Bar options={options} data={chartData} />
      </ChartContainer>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
  );
}
