import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { getChartOptions, getSpecializationsData, SpecializationDataType } from "./utils";
import { CHART_COLORS } from "../../utils/constants";
import useReportType from "../../utils/hooks/useReportType";

const ChartContainer = styled.div<{
  numberOfSpecializations: number;
}>`
  height: ${(props) => props.numberOfSpecializations * 35 + 100}px;
`;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IReportSpecializationChart {
  data: {
    [key: string]: { [key: string]: { [key: string]: number | string } };
  };
  keyProp: string;
  type: SpecializationDataType;
}

export default function ReportSpecializationChart({ data, type, keyProp }: IReportSpecializationChart) {
  const { t } = useTranslation();
  const reportType = useReportType();

  const specializationsData = React.useMemo(
    () => getSpecializationsData(data, type).sort((a, b) => (b[keyProp] > a[keyProp] ? 1 : -1)),
    [data, keyProp, type]
  );

  const labels = React.useMemo(() => specializationsData.map((spec) => spec.translation), [specializationsData]);
  const dataset = React.useMemo(() => specializationsData.map((spec) => spec[keyProp]), [keyProp, specializationsData]);
  const options = React.useMemo(
    () => ({
      ...getChartOptions(),
      indexAxis: "y",
    }),
    []
  );

  const chartData = {
    labels,
    datasets: [
      {
        backgroundColor: CHART_COLORS[0],
        label: t(`${reportType}.specializationsChart.${keyProp}`),
        data: dataset,
      },
    ],
  };

  if (labels.length === 0) {
    return null;
  }
  return (
    <ChartContainer numberOfSpecializations={labels.length}>
      {/* @types package not up to date */}
      {/* @ts-ignore */}
      <Bar options={options} data={chartData} />
    </ChartContainer>
  );
}
