import styled from "styled-components";
import { ButtonV2ColorsCss } from "../../components/UiKit/Button";
import { ButtonResetCSS, FlexCenterCSS } from "../../components/UiKit/Helpers/StyledCss";
import Container from "../../components/UiKit/Container";

export const SettingsButton = styled.button`
  ${ButtonResetCSS}
  ${FlexCenterCSS}
  ${ButtonV2ColorsCss}
  gap: 8px;
  height: 38px;
  padding: 0 24px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  font-weight: 300;
  border: 1px solid ${(props) => props.theme.text4};
`;

export const TrafficDataWrapper = styled(Container)`
  margin-top: 24px;
`;
