import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TrafficDataEntry from "./TrafficDataEntry";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
  min-height: 184px;

  & > {
    flex: 1 1 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface ITrafficData {
  reportType: "tools" | "ads" | "cmes" | "stories" | "mdsPerSpec";
  selectedPeriodReports: Array<{ [key: string]: any }> | undefined;
  trafficDataEntryProps: Array<string>;
  isLoading: boolean;
  periodOption?: string;
}

export default function TrafficData({
  reportType,
  periodOption,
  selectedPeriodReports,
  trafficDataEntryProps,
  isLoading,
}: ITrafficData) {
  const { t } = useTranslation();

  return (
    <Container>
      {trafficDataEntryProps.map((dataProperty) => (
        <TrafficDataEntry
          key={dataProperty}
          title={`${t(`common.trafficData.${reportType}.${dataProperty}.title`)}`}
          titleTooltip={`${t(`common.trafficData.${reportType}.${dataProperty}.titleTooltip`)}`}
          timePeriod={
            periodOption
              ? `${t(`common.trafficData.${reportType === "cmes" ? "cmes." : ""}${periodOption}`)}`
              : `${t(`common.trafficData.${reportType}.${dataProperty}.period`)}`
          }
          timePeriodPrefix={reportType === "cmes" ? t("common.trafficData.cmes.timePeriodPrefix") : ""}
          selectedPeriodReports={selectedPeriodReports}
          dataProperty={dataProperty}
          iconKey={`${reportType}-${dataProperty}`}
          isLoading={isLoading}
        />
      ))}
    </Container>
  );
}
