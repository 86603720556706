import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table";
import WithTooltip from "../../components/Tooltip/WithTooltip";
import WithLoadingCard from "../../components/UiKit/WithLoadingCard";
import { PagePeriodFilterContext } from "../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import * as Styled from "./Drugs.styled";
import { DrugSearchTrendsSchema } from "../../api";

export default function DrugsTable({
  data,
  isFetching,
  tableSubTitle,
}: {
  data: DrugSearchTrendsSchema | undefined;
  isFetching?: boolean;
  tableSubTitle: string;
}) {
  const { dateRangeReadable } = React.useContext(PagePeriodFilterContext);
  const { t } = useTranslation();

  const formattedData = React.useMemo(() => {
    if (!data) {
      return undefined;
    }

    const maxTotalClicks = Math.max(...data.drug_search_trends.map((drug: any) => drug.merged.total_clicks));

    return data.drug_search_trends.map((drug: any, index: number) => ({
      ...drug.merged,
      prefix: `${index + 1}. `,
      name: `${index + 1}. ${drug.merged.name}`,
      percentage: (drug.merged.total_clicks / maxTotalClicks) * 100,
      breakdown: drug.breakdown,
    }));
  }, [data]);

  const columns = React.useMemo(
    () => [
      ...["name", "producer", "unique_clicks", "total_clicks"].map((key) => ({
        Header: <span>{t(`drugSearchTrends.table.${key}`)}</span>,
        accessor: key,
        style: ["name", "producer"].includes(key) ? { width: "22%" } : {},
        Cell: ({ row }: any): any => React.useMemo(() => row.original[key], [row]),
      })),

      /*
       * Total clicks percentage bar
       */
      {
        Header: () => null,
        style: { width: "18%" },
        id: "percentage",
        Cell: ({ row }: any): any =>
          React.useMemo(
            () => (
              <WithTooltip
                id={`percentage-${row.index}`}
                type="light"
                tooltip={
                  <Styled.PercentageTTWrapper>
                    <h3>{row.original.name}</h3>
                    <p>{t("drugSearchTrends.table.percentageTooltip")}</p>
                    <p>{dateRangeReadable}</p>
                    <p>
                      <strong>{row.original.total_clicks}</strong> Total clicks
                    </p>
                  </Styled.PercentageTTWrapper>
                }
              >
                <Styled.Percentage $width={`${row.original.percentage}%`} />
              </WithTooltip>
            ),
            [row.original, row.index]
          ),
      },

      /*
       * Sub row toggle
       */
      {
        Header: () => null,
        style: { width: "12px" },
        id: "toggle",
        Cell: ({ row }: any): any =>
          React.useMemo(
            () => (
              <span {...row.getToggleRowExpandedProps()}>
                <Styled.Arrow $down={row.isExpanded} />
              </span>
            ),
            // It doesn't toggle without row.isExpanded
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [row, row.isExpanded]
          ),
      },
    ],
    [dateRangeReadable, t]
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {row.original.breakdown.map((item: any, index: number, arr: any) => (
          <tr className={index === arr.length - 1 ? "sub-row last" : "sub-row"}>
            <td colSpan={2}>
              <Styled.HiddenPrefix>{row.original.prefix} </Styled.HiddenPrefix>
              {item.name}
            </td>
            <td>{item.unique_clicks}</td>
            <td>{item.total_clicks}</td>
            <td colSpan={2} />
          </tr>
        ))}
      </>
    ),
    []
  );

  const tableTitle = React.useMemo(() => t("drugSearchTrends.table.title"), [t]);

  if (!isFetching && !formattedData) {
    return null;
  }
  return (
    <WithLoadingCard isLoading={!!isFetching && !formattedData}>
      <Table
        columns={columns}
        data={formattedData!}
        tableTitle={tableTitle}
        tableSubTitle={tableSubTitle}
        renderRowSubComponent={renderRowSubComponent}
      />
    </WithLoadingCard>
  );
}
