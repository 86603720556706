import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/icons/mediately-logo.svg";
import FooterColumn from "./FooterColumn";

const StyledLogo = styled(Logo)`
  width: 104px;
  height: auto;
  margin-bottom: 12px;
`;

const Text = styled.div`
  margin-bottom: 8px;
  p {
    margin: 0;
  }
`;

export default function FooterMediatelySection() {
  const { t } = useTranslation();

  return (
    <FooterColumn>
      <StyledLogo />
      <Text>
        <p>{t("footer.mediately.services")}</p>
        <p>{t("footer.mediately.reachOut")}</p>
      </Text>
      <div>
        <div>
          {t("footer.mediately.email")} <a href="mailto: sales@mediately.co">sales@mediately.co</a>
        </div>
        <div>
          {t("footer.mediately.phone")} <a href="tel: +386 30 710 976">+386 30 710 976</a>
        </div>
      </div>
    </FooterColumn>
  );
}
