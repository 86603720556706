import React from "react";
import { useTranslation } from "react-i18next";
import { COUNTRIES } from "../constants";

const useCountryOptions = () => {
  const { t } = useTranslation();

  const countryOptions = React.useMemo(
    () =>
      COUNTRIES.map((country) => ({
        value: country,
        label: t(`common.countries.${country}`),
      })),
    [t]
  );

  return countryOptions;
};

export default useCountryOptions;
