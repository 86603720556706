import React from "react";
import { useTranslation } from "react-i18next";
import EnContent from "./en.md";
import StaticPageLayout from "../StaticPageLayout";

export default function ReleaseNotes() {
  const { t } = useTranslation();
  return (
    <StaticPageLayout title={t("releaseNotes.title")} subtitle={t("releaseNotes.subtitle")} mdContent={EnContent} />
  );
}
