import React from "react";

export const MONTHLY = "monthly";
export const DAILY = "daily";
export type DataType = "monthly" | "daily";

export const DataTypeContext = React.createContext<{
  type: "monthly" | "daily";
  setType: (type: DataType) => void;
}>({ type: MONTHLY, setType: () => {} });

export default function DataTypeSelectionContextProvider({ children }: { children: React.ReactElement }) {
  const [type, setType] = React.useState<DataType>(MONTHLY);

  const value = React.useMemo(() => ({ type, setType }), [type, setType]);
  return <DataTypeContext.Provider value={value}>{children}</DataTypeContext.Provider>;
}
