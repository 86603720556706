import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as Styled from "./LandingPage.styled";
import Button from "../../components/UiKit/Button";
import HeroImage from "../../assets/landing-page/landing-page-hero.webp";
import FeatureImg1 from "../../assets/landing-page/landing_page1.webp";
import FeatureImg2 from "../../assets/landing-page/landing_page2.webp";
import FeatureImg3 from "../../assets/landing-page/landing_page3.webp";
import Partners from "../../assets/landing-page/partners.webp";
import VideoPresentationWebm from "../../assets/landing-page/Insights_video.webm";
import VideoPresentationMp4 from "../../assets/landing-page/Insights_video.mp4";
import AuthDialog from "./AuthDialog";
import { AuthRoutes } from "../routes";

function CtaSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Styled.Background $white>
      <Styled.SectionContainer $section="cta">
        <Styled.Text $xl>{t("landingPage.ctaSection.title")}</Styled.Text>
        <Button big cta onClick={() => navigate(AuthRoutes.register)}>
          {t("landingPage.ctaSection.cta")}
        </Button>
      </Styled.SectionContainer>
    </Styled.Background>
  );
}

function PartnersSection() {
  const { t } = useTranslation();

  return (
    <Styled.Background>
      <Styled.SectionContainer $section="partners">
        <Styled.FeaturesRow>
          <Styled.FeaturesColumn>
            <Styled.Heading2>{t("landingPage.partners.title")}</Styled.Heading2>
            <Trans i18nKey="landingPage.partners.text">
              <p />
              <p />
              <p />
            </Trans>
          </Styled.FeaturesColumn>
          <Styled.FeaturesColumn $img>
            <Styled.Image loading="lazy" src={Partners} />
          </Styled.FeaturesColumn>
        </Styled.FeaturesRow>
      </Styled.SectionContainer>
    </Styled.Background>
  );
}

function FeaturesSection() {
  const { t } = useTranslation();

  return (
    <Styled.Background $white>
      <Styled.SectionContainer $section="features">
        {[FeatureImg1, FeatureImg2, FeatureImg3].map((img, index) => (
          <Styled.FeaturesRow $reverse={index % 2 === 0}>
            <Styled.FeaturesColumn>
              <Styled.Heading2>{t(`landingPage.features.feature${index + 1}.title`)}</Styled.Heading2>
              <Trans i18nKey={`landingPage.features.feature${index + 1}.text`}>
                <li />
                <li />
                <p />
                <p />
              </Trans>
            </Styled.FeaturesColumn>

            <Styled.FeaturesColumn $img>
              <Styled.Image loading="lazy" $withShadow src={img} />
            </Styled.FeaturesColumn>
          </Styled.FeaturesRow>
        ))}
      </Styled.SectionContainer>
    </Styled.Background>
  );
}

function VideoSection() {
  const { t } = useTranslation();

  return (
    <Styled.Background>
      <Styled.Container $morePadding>
        <Styled.Column>
          <Styled.Heading2 $center>{t("landingPage.sectionVideo.title")}</Styled.Heading2>

          <Styled.Video controls>
            <source src={VideoPresentationWebm} type="video/webm" />
            <source src={VideoPresentationMp4} type="video/mp4" />
          </Styled.Video>
        </Styled.Column>
      </Styled.Container>
    </Styled.Background>
  );
}

function HeroSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Styled.Background $white>
      <Styled.Container>
        <Styled.Column>
          <Styled.Heading1>
            <Trans i18nKey="landingPage.title" />
          </Styled.Heading1>

          <Styled.Text>{t("landingPage.subTitle")}</Styled.Text>

          <Styled.ButtonContainer>
            <Button big cta onClick={() => navigate(AuthRoutes.register)}>
              {t("landingPage.buttons.signUp")}
            </Button>
            <Styled.SubButton>
              {t("landingPage.haveAccount")} <Link to={AuthRoutes.login}>{t("landingPage.buttons.login")}</Link>
            </Styled.SubButton>
          </Styled.ButtonContainer>
        </Styled.Column>

        <Styled.Column>
          <Styled.HeroImage src={HeroImage} alt="landing page" />
        </Styled.Column>
      </Styled.Container>
    </Styled.Background>
  );
}

export default function LandingPage() {
  return (
    <>
      <HeroSection />
      <VideoSection />
      <FeaturesSection />
      <PartnersSection />
      <CtaSection />

      <AuthDialog />
    </>
  );
}
