import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi/dist/joi";
import { rememberMeLSKey, UserContext } from "../../../contexts/UserContext";
import FormControlController from "../../../components/form/FormControlController";
import FormCheckController from "../../../components/form/FormCheckController";
import PasswordController from "../../../components/form/PasswordController";
import { mixpanelTrack, TrackingConstants } from "../../../libs/mixpanel";
import { ApiError, AuthUserApi, Body_auth_session_login, UsersApi } from "../../../api";
import { Heading1 } from "../../../components/UiKit/Typography";
import { Form, FormRow, FormRowLink, Subheader } from "../Auth.styled";
import { SignUpButton } from "../AuthCommon/AuthCommon";
import { bugsnagNotify } from "../../../libs/bugsnag";
import Alert from "../../../components/UiKit/Alert";
import * as Styled from "./Login.styled";

type LoginFormValues = Body_auth_session_login & {
  rememberMe: boolean;
};

export default function Login() {
  const { t } = useTranslation();
  const { setUserAndInit } = React.useContext(UserContext);
  const [responseError, setResponseError] = React.useState<string | null>(null);
  const navigate = useNavigate();

  const onSuccess = React.useCallback(async () => {
    try {
      const currentUser = await UsersApi.usersCurrentUser();
      mixpanelTrack(TrackingConstants.LoginSuccess);
      setUserAndInit(currentUser, true);
    } catch (e: any) {
      bugsnagNotify(e);
    }
  }, [setUserAndInit]);

  const onError = React.useCallback((e: ApiError) => {
    setResponseError(e.body?.detail);
  }, []);

  const { mutate: sessionLogin } = useMutation(AuthUserApi.authSessionLogin, { onSuccess, onError });
  const { mutate: rememberMeLogin } = useMutation(AuthUserApi.authRememberMeLogin, { onSuccess, onError });

  const validationSchema = React.useMemo(
    () =>
      Joi.object({
        username: Joi.string()
          .required()
          .email({ tlds: { allow: false } })
          .label(t("auth.login.email")),
        password: Joi.string().required().label(t("auth.login.password")),
      }).unknown(true),
    [t]
  );

  const { handleSubmit, control, watch } = useForm<LoginFormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      username: "",
      password: "",
      rememberMe: false,
    },
  });

  const onSubmit = (formData: LoginFormValues) => {
    setResponseError(null);
    const { rememberMe, ...loginData } = formData;

    localStorage.setItem(rememberMeLSKey, rememberMe.toString());

    if (rememberMe) {
      rememberMeLogin({ formData: loginData });
    } else {
      sessionLogin({ formData: loginData });
    }
  };
  const email = watch("username");

  const { mutate: mutateRequestVerifyToken } = useMutation(AuthUserApi.verifyRequestToken, {
    onSuccess: () => {
      navigate("/auth/register", { state: { email, step: 5 } });
    },
    onError: (e: Error) => {
      bugsnagNotify(e);
    },
  });

  const handleResendEmailClick = React.useCallback(() => {
    mutateRequestVerifyToken({ requestBody: { email } });
  }, [email, mutateRequestVerifyToken]);

  const userNotVerified = React.useMemo(() => responseError === "LOGIN_USER_NOT_VERIFIED", [responseError]);

  return (
    <>
      <Styled.LoginIllustration />
      <Heading1 style={{ marginBottom: "8px" }}>{t("auth.login.header")}</Heading1>
      <Subheader>{t("auth.login.subheader1")}</Subheader>
      <Subheader $noMaxWidth>{t("auth.login.subheader2")}</Subheader>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControlController name="username" control={control} type="email" label={t("auth.login.email")} required />
        <PasswordController name="password" control={control} label={t("auth.login.password")} required />
        <FormRow>
          <FormCheckController name="rememberMe" control={control} label={t("auth.login.rememberMe")} />
          <FormRowLink style={{ marginLeft: "auto" }} as={Link} to="/auth/forgot-password">
            {t("auth.login.forgotPassword")}
          </FormRowLink>
        </FormRow>

        <Styled.Submit>{t("auth.login.submit")}</Styled.Submit>

        {responseError && (
          <Alert>
            <span>{t(`auth.login.${responseError as string}`)}</span>
          </Alert>
        )}

        {userNotVerified && (
          <FormRow center style={{ marginTop: "16px" }}>
            <span>{t("auth.login.verification")}</span>
            <FormRowLink onClick={handleResendEmailClick}>{t("auth.login.resendEmail")}</FormRowLink>
          </FormRow>
        )}

        <SignUpButton />
      </Form>
    </>
  );
}
