import React from "react";
import { mixpanelTrack, TrackingConstants } from "../../../libs/mixpanel";
import { FormStateType, RegisterSteps } from "./types";

interface IRegisterContextProvider {
  children: JSX.Element;
}

const defaultFormState = {
  name: "",
  lastName: "",
  password: "",
  email_consent: false,
  country: "",
  email: "",
};

export const RegisterContext = React.createContext<{
  step: RegisterSteps;
  setStep: (step: RegisterSteps) => void;
  formState: FormStateType;
  setFormState: (formState: FormStateType) => void;
}>({
  step: 1,
  setStep: () => null,
  formState: defaultFormState,
  setFormState: () => null,
});

export default function RegisterContextProvider({ children }: IRegisterContextProvider) {
  const [step, setStep] = React.useState<RegisterSteps>(1);
  const [formState, setFormState] = React.useState<FormStateType>(defaultFormState);

  React.useEffect(() => {
    mixpanelTrack(TrackingConstants.RegisterFlow, { step });
  }, [step]);

  const contextValue = React.useMemo(
    () => ({
      formState,
      setFormState,
      step,
      setStep,
    }),
    [formState, setFormState, step, setStep]
  );

  return <RegisterContext.Provider value={contextValue}>{children}</RegisterContext.Provider>;
}
