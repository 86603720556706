import mixpanel from "mixpanel-browser";
import _omit from "lodash/omit";
import packageJson from "../../package.json";
import { bugsnagNotify } from "./bugsnag";

export enum TrackingConstants {
  PageView = "PageView",
  PeriodFilterChange = "PeriodFilterChange",
  LoginSuccess = "LoginSuccess",
  ResetPasswordClick = "ResetPasswordClick",
  LanguageFilterChange = "LanguageFilterChange",
  ExportAdsList = "ExportAdsList",
  ExportCmesList = "ExportCmesList",
  ExportStoriesList = "ExportStoriesList",
  ExportToolsList = "ExportToolsList",
  ExportPdf = "ExportPdf",
  RegisterFlow = "RegisterFlow",
}

const UNWANTED_PROPERTIES = ["suggested_email"];

const token = process.env.REACT_APP_MIXPANEL_TOKEN;
const isTrackingEnabled = !!token;

(function initMixpanel() {
  if (!isTrackingEnabled) {
    return;
  }

  mixpanel.init(token, { property_blacklist: ["$current_url"] });
  mixpanel.identify();
  mixpanel.register({ AppVersion: packageJson.version });
})();

// Replaces the report id with "report" in path
const mapPathName = (path: string, loggedIn: boolean) => {
  // Landing page and dashboard have the same path, so we need to differentiate them
  if (path === "/") {
    return loggedIn ? "/[dashboard]" : "/[landing-page]";
  }

  const pathsToMap = ["tools", "ads", "cmes", "stories"];

  if (pathsToMap.some((p) => path.includes(p))) {
    return path
      .split("/")
      .map((p, index) => (index === 2 ? "report" : p))
      .join("/");
  }

  return path;
};

export const mixpanelTrack = (event: TrackingConstants, _props: Object = {}) => {
  if (!isTrackingEnabled) {
    return;
  }

  // Redact known unwanted properties
  const props = _omit(_props, UNWANTED_PROPERTIES);

  const stringifiedProps = JSON.stringify(props);

  const denyList = stringifiedProps.match(/pass|username|email|name|company|address|token/g);
  const allowList = stringifiedProps.match(/hs_email/g); // HubSpot newsletter

  if (!allowList && denyList && denyList?.length > 0) {
    mixpanel.track(event, { "Redacted properties": true });
    bugsnagNotify(`Mixpanel: redacted properties: ${denyList.join(", ")}`);
    return;
  }

  mixpanel.track(event, props);
};

export const mixpanelSetUser = (email: string) => {
  if (!isTrackingEnabled) {
    return;
  }

  mixpanel.people.set({ MediatelyUser: email.includes("@mediately.co") });
};

export const trackPageView = (pathname: string, searchParams: URLSearchParams, loggedIn: boolean) => {
  if (!isTrackingEnabled) {
    return;
  }

  const params = Object.fromEntries(searchParams.entries());

  mixpanelTrack(TrackingConstants.PageView, {
    "Path Name Aggregated": mapPathName(pathname, loggedIn),
    ...params,
  });
};
