import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "../../../components/UiKit/Dialog";
import ForgotPassword from "../../Auth/ForgotPassword";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";
import RegisterContextProvider from "../../Auth/Register/RegisterContext";
import ResetPassword from "../../Auth/ResetPassword";
import Verify from "../../Auth/Verify";
import { Card } from "../../Auth/Auth.styled";
import { AuthRoutes } from "../../routes";
import { CloseButton } from "../../../components/UiKit/CloseButton";

function AuthDialogOutlet({ path }: { path: string }) {
  switch (path) {
    case AuthRoutes.login:
      return <Login />;
    case AuthRoutes.register:
      return (
        <RegisterContextProvider>
          <Register />
        </RegisterContextProvider>
      );
    case AuthRoutes.resetPassword:
      return <ResetPassword />;
    case AuthRoutes.forgotPassword:
      return <ForgotPassword />;
    case AuthRoutes.verify:
      return <Verify />;
    default:
      return null;
  }
}

export default function AuthDialog() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (!pathname.startsWith("/auth")) {
    return null;
  }
  return (
    <Dialog>
      <Card>
        <CloseButton onClick={() => navigate("/")} />
        <AuthDialogOutlet path={pathname} />
      </Card>
    </Dialog>
  );
}
