import React from "react";
import styled from "styled-components";
import { FlexCenterCSS } from "./Helpers/StyledCss";

const AlertWrapper = styled.div`
  ${FlexCenterCSS}
  color: ${(props) => props.theme.redDangerDark};
  background: ${(props) => props.theme.redDangerBackground};
  padding: 22px 25px;
  border-radius: 12px;
  line-height: 1;
`;

export default function Alert({ children }: { children: JSX.Element | JSX.Element[] }) {
  return <AlertWrapper>{children}</AlertWrapper>;
}
