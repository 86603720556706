import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Styled from "./NavigationBar.styled";
import { ReactComponent as ToolsSvg } from "../../assets/icons/nav/tools.svg";
import { ReactComponent as HomeSvg } from "../../assets/icons/nav/home.svg";
import { ReactComponent as EducationSvg } from "../../assets/icons/nav/education.svg";
import { ReactComponent as NewsfeedSvg } from "../../assets/icons/nav/newsfeed-banner.svg";
import { ReactComponent as SearchAdsSvg } from "../../assets/icons/nav/search-ads.svg";
import { ReactComponent as DrugsMaterialSvg } from "../../assets/icons/nav/drugs-material.svg";
import { ReactComponent as DoctorsSvg } from "../../assets/icons/nav/doctors.svg";
import { ReactComponent as ReportsSvg } from "../../assets/icons/nav/reports.svg";
import { ReactComponent as NewsSvg } from "../../assets/icons/nav/news.svg";
import { ReactComponent as ToggleSvg } from "../../assets/icons/nav/toggle.svg";
import { ReactComponent as DrugsSvg } from "../../assets/icons/nav/drugs.svg";
import { ReportRoutes } from "../../pages/routes";
import { UserContext } from "../../contexts/UserContext";
import NavActions from "./NavActions/NavActions";

export default function SideNavigationBar({
  setNavOpened,
  navOpened,
}: {
  setNavOpened: React.Dispatch<React.SetStateAction<boolean>>;
  navOpened: boolean;
}) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = React.useContext(UserContext);

  const activeItem = React.useMemo(() => `/${pathname.split("/")[1]}`, [pathname]);
  const showMdsPerSpec = React.useMemo(() => user?.permissions?.mds_per_spec, [user]);

  if (!user) {
    return null;
  }
  return (
    <Styled.SideBarWrapper $opened={navOpened}>
      <Styled.Link $active={activeItem === "/"} to="/">
        <HomeSvg />
        <span>{t("navigation.home")}</span>
      </Styled.Link>

      <Styled.Separator />

      <Styled.Level2Wrapper>
        <Styled.Link as="div" $disabled $hide={!navOpened}>
          <ReportsSvg />
          <span>{t("navigation.reports")}</span>
        </Styled.Link>

        <Styled.Link $level2 to={{ pathname: ReportRoutes.ads }} $active={activeItem === ReportRoutes.ads}>
          <NewsfeedSvg />
          <span>{t("navigation.ads")}</span>
        </Styled.Link>

        <Styled.Link $level2 to={{ pathname: ReportRoutes.searchAds }} $active={activeItem === ReportRoutes.searchAds}>
          <SearchAdsSvg />
          <span>{t("navigation.search-ads")}</span>
        </Styled.Link>

        <Styled.Link
          $level2
          to={{ pathname: ReportRoutes.drugMaterials }}
          $active={activeItem === ReportRoutes.drugMaterials}
        >
          <DrugsMaterialSvg />
          <span>{t("navigation.drug-materials")}</span>
        </Styled.Link>

        <Styled.Link $level2 to={ReportRoutes.stories} $active={activeItem === ReportRoutes.stories}>
          <NewsSvg />
          <span>{t("navigation.stories")}</span>
        </Styled.Link>

        <Styled.Link $level2 to={ReportRoutes.cmes} $active={activeItem === ReportRoutes.cmes}>
          <EducationSvg />
          <span>{t("navigation.cmes")}</span>
        </Styled.Link>

        <Styled.Link $level2 to={ReportRoutes.tools} $active={activeItem === ReportRoutes.tools}>
          <ToolsSvg />
          <span>{t("navigation.tools")}</span>
        </Styled.Link>
      </Styled.Level2Wrapper>

      <Styled.Separator />

      {showMdsPerSpec && (
        <Styled.Link
          to={ReportRoutes.mds_per_specialization}
          $active={activeItem === ReportRoutes.mds_per_specialization}
        >
          <DoctorsSvg />
          <span>{t("navigation.mds-per-specialization")}</span>
        </Styled.Link>
      )}

      <Styled.Link to={ReportRoutes.drugs} $active={activeItem === ReportRoutes.drugs}>
        <DrugsSvg />
        <span>{t("navigation.drug-search-trends")}</span>
      </Styled.Link>

      <Styled.Separator $onlyOnSmallScreens />

      <NavActions forSideNav />

      <Styled.ToggleButton onClick={() => setNavOpened((opened) => !opened)} $active={navOpened}>
        <ToggleSvg />
      </Styled.ToggleButton>
    </Styled.SideBarWrapper>
  );
}
