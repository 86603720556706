import React from "react";

const useInitialFetchDone = (isFetching: boolean) => {
  const [initialFetchDone, setInitialFetchDone] = React.useState(false);

  React.useEffect(() => {
    if (!initialFetchDone && !isFetching) {
      setInitialFetchDone(true);
    }
  }, [initialFetchDone, isFetching]);

  return initialFetchDone;
};

export default useInitialFetchDone;
