export enum ExportContentTypeEnum {
  Csv = "text/csv",
  Xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  Json = "application/json",
  PDF = "application/pdf",
}

export const exportsFetcher = async (
  url: string,
  exportContentType: ExportContentTypeEnum,
  ids: string[] = [],
  init?: RequestInit
) => {
  const query_ids = ids.length ? `&ids=${ids.join("&ids=")}` : "";
  const response = await fetch(`${url}${query_ids}`, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": exportContentType, Accept: exportContentType },
    ...init,
  });
  if (response.ok) {
    const contentHeader = response.headers.get("content-disposition");
    if (!contentHeader) {
      throw Error("Missing content-disposition header!");
    }

    const fileNameMatch = contentHeader.match(/filename="(.+)"/);
    if (!fileNameMatch || !fileNameMatch[1]) {
      throw Error("No file name found in header!");
    }

    const fileName = decodeURIComponent(fileNameMatch[1]);
    const dl_url = window.URL.createObjectURL(new Blob([await response.blob()]));
    const link = document.createElement("a");
    link.href = dl_url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  } else {
    const error = await response.text();
    throw Error(error);
  }
};
