import React from "react";
import WithLoadingSpinner from "../Layout/WithLoadingSpinner";
import { formatNumber } from "../../utils/numbers";
import { ReactComponent as ReachedUsersIcon } from "../../assets/icons/traffic-data/ads-reached-users.svg";
import { ReactComponent as ViewsIcon } from "../../assets/icons/traffic-data/ads-views.svg";
import { ReactComponent as OpensIcon } from "../../assets/icons/traffic-data/ads-opens.svg";
import { ReactComponent as CmesStarted } from "../../assets/icons/traffic-data/cmes-started.svg";
import { ReactComponent as CmesReadIcon } from "../../assets/icons/traffic-data/cmes-read.svg";
import { ReactComponent as CmesAnsweredIcon } from "../../assets/icons/traffic-data/cmes-answered.svg";
import WithTooltip from "../Tooltip/WithTooltip";
import * as Styled from "./TrafficDataEntry.styled";

function TrafficDataIcon({ iconKey }: { iconKey: string }) {
  switch (iconKey) {
    case "cmes-answered":
      return <CmesAnsweredIcon />;
    case "cmes-read_materials":
      return <CmesReadIcon />;
    case "cmes-started":
      return <CmesStarted />;

    case "mdsPerSpec-active_mds_30d":
    case "ads-ad_views":
    case "tools-tool_opens":
    case "stories-news_views":
      return <ViewsIcon />;

    case "mdsPerSpec-registered_mds_all_time":
    case "ads-views_unique_users":
    case "tools-unique_users":
    case "stories-views_unique_users":
      return <ReachedUsersIcon />;

    case "mdsPerSpec-total_mds_sessions_30d":
    case "tools-tool_ad_opens":
    case "ads-ad_opens":
    case "stories-news_opens":
      return <OpensIcon />;
    default:
      return null;
  }
}

interface ITrafficDataEntry {
  title: string;
  titleTooltip: string;
  timePeriod: string;
  timePeriodPrefix?: string;
  iconKey: string;
  selectedPeriodReports: Array<{ [key: string]: any }> | undefined;
  dataProperty: string;
  isLoading?: boolean;
}

export default function TrafficDataEntry({
  title,
  titleTooltip,
  timePeriod,
  timePeriodPrefix,
  selectedPeriodReports,
  dataProperty,
  iconKey,
  isLoading,
}: ITrafficDataEntry) {
  const selectedPeriodDataCount = React.useMemo(() => {
    if (selectedPeriodReports) {
      return selectedPeriodReports.reduce((acc, current) => {
        if (typeof current[dataProperty] === "string") {
          return acc;
        }
        return acc + current[dataProperty] || 0;
      }, 0);
    }
    return 0;
  }, [dataProperty, selectedPeriodReports]);

  return (
    <Styled.Card>
      <WithLoadingSpinner isLoading={isLoading}>
        <>
          <TrafficDataIcon iconKey={iconKey} />
          <Styled.TDContent>
            <WithTooltip id={iconKey} tooltip={titleTooltip} withoutUnderline place="top">
              <Styled.Title>{title}</Styled.Title>
            </WithTooltip>
            <Styled.TrafficData>{formatNumber(selectedPeriodDataCount)}</Styled.TrafficData>
            <Styled.TimePeriod>
              {timePeriodPrefix && <Styled.Strong>{timePeriodPrefix} </Styled.Strong>}
              {timePeriod}
            </Styled.TimePeriod>
          </Styled.TDContent>
        </>
      </WithLoadingSpinner>
    </Styled.Card>
  );
}
