import React from "react";
import Tooltip from "./Tooltip";

interface WithTooltipI {
  id: string;
  tooltip: React.ReactElement | string;
  children: React.ReactElement;
  className?: string;
  place?: "top" | "right" | "bottom" | "left";
  disableTooltip?: boolean;
  withoutUnderline?: boolean;
  delay?: number;
  type?: "dark" | "light";
}

export default function WithTooltip({
  children,
  id,
  tooltip,
  className,
  place,
  disableTooltip,
  withoutUnderline,
  delay,
  type,
}: WithTooltipI) {
  return (
    <>
      {React.cloneElement(children, {
        id,
        style: !withoutUnderline
          ? {
              cursor: "pointer",
              textDecoration: "underline",
              textDecorationStyle: "dotted",
            }
          : {},
      })}
      {!disableTooltip && (
        <Tooltip id={id} className={className} delay={delay} place={place} type={type}>
          <div>{tooltip}</div>
        </Tooltip>
      )}
    </>
  );
}
