import axios from "axios";
import React from "react";
import { UserContext } from "../../contexts/UserContext";

export default function Interceptor() {
  const { setUserToNull, user } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    axios.interceptors.response.use(undefined, (error) => {
      if (error.response.status === 401) {
        setUserToNull();
        // Router will handle rerouting to login
      }
      return error;
    });
  }, [setUserToNull, user]);

  return null;
}
