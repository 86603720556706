export enum ApiQueryKeys {
  // SpecializationsApi
  specializationsMdsPerSpecialization = "specializationsMdsPerSpecialization",
  specializationsSpecializationsList = "specializationsSpecializationsList",
  specializationsActiveUsersPerCountry = "specializationsActiveUsersPerCountry",
  specializationsMdsCardsTotals = "specializationsMdsCardsTotals",

  // AdzApi
  adsStaticData = "adsStaticData",
  adsSummaryReports = "adsSummaryReports",
  getAdsReport = "getAdsReport",

  // ToolsApi
  toolsSummaryReports = "toolsSummaryReports",
  toolsStaticData = "toolsStaticData",
  getToolsReport = "getToolsReport",

  // CmeApi
  cmesStaticData = "cmesStaticData",
  cmesSummaryReports = "cmesSummaryReports",
  getCmeReport = "getCmeReport",

  // NewsApi
  newsStaticData = "newsStaticData",
  newsSummaryReports = "newsSummaryReports",
  getNewsReport = "getNewsReport",

  // AuthUserApi
  verifyVerify = "verifyVerify",

  // Drugs
  getTopDrugsSearch = "getTopDrugsSearch",
}
