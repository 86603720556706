import React from "react";
import { useTranslation } from "react-i18next";
import FooterColumn from "./FooterColumn";

export default function FooterAboutUsSection() {
  const { t } = useTranslation();

  return (
    <FooterColumn title={t("footer.aboutUs.header")}>
      <a href="https://mediately.co/business/en/#whoarewe">{t("footer.aboutUs.whoWeAre")}</a>
      <a href="https://mediately.co/business/en/#whydigital">{t("footer.aboutUs.digitalMarketing")}</a>
      <a href="https://mediately.co/business/en/#whyworkwithus">{t("footer.aboutUs.workWithUs")}</a>
      <a href="https://mediately.co/business/en/#team">{t("footer.aboutUs.outTeam")}</a>
      <a href="https://mediately.co/business/en/careers">{t("footer.aboutUs.careers")}</a>
    </FooterColumn>
  );
}
