import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/numbers";
import Card from "../../../components/UiKit/Card";
import { CardTitle } from "../Dashboard.styled";
import { ReactComponent as ReachedUsersIcon } from "../../../assets/icons/traffic-data/ads-reached-users.svg";
import { ReactComponent as SessionsIcon } from "../../../assets/icons/md-sessions-icon.svg";
import { ReactComponent as SessionsPerMDIcon } from "../../../assets/icons/md-sessions-per-md-icon.svg";
import { FlexCenterCSS } from "../../../components/UiKit/Helpers/StyledCss";
import { MDsPerSpecializationTotalStats } from "../../../api";
import { Heading2 } from "../../../components/UiKit/Typography";
import WithTooltip from "../../../components/Tooltip/WithTooltip";

const StyledCard = styled(Card)`
  ${FlexCenterCSS}
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0 30px;
  flex: 1;
  gap: 33px;
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: min-content;
  padding: 15px 0;

  & + & {
    border-left: ${(props) => props.theme.lightBorder};
  }
`;

const CardValue = styled(Heading2)`
  margin: 25px 0 11px;
`;

const CardDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.text2};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export default function DashboardActiveMds({ data }: { data: MDsPerSpecializationTotalStats }) {
  const { t } = useTranslation();
  const { active_mds, sessions, sessions_per_active_mds } = data;

  return (
    <Container>
      <StyledCard>
        <CardTitle $marginLeft>
          <Trans i18nKey="dashboard.activeMds.header" />
        </CardTitle>
        <CardsWrapper>
          <CardItem>
            <ReachedUsersIcon />
            <CardValue>{formatNumber(active_mds)}</CardValue>
            <WithTooltip
              id="mdsPerSpecialization.table.active_mds"
              tooltip={t(`mdsPerSpecialization.table.active_mdsTooltip`)}
              place="top"
              withoutUnderline
            >
              <CardDescription>{t("dashboard.activeMds.active_mds")}</CardDescription>
            </WithTooltip>
          </CardItem>
          <CardItem>
            <SessionsIcon />
            <CardValue>{formatNumber(sessions)}</CardValue>
            <WithTooltip
              id="mdsPerSpecialization.table.sessionsTooltip"
              tooltip={t(`mdsPerSpecialization.table.sessionsTooltip`)}
              place="top"
              withoutUnderline
            >
              <CardDescription>{t("dashboard.activeMds.sessions")}</CardDescription>
            </WithTooltip>
          </CardItem>
          <CardItem>
            <SessionsPerMDIcon />
            <CardValue>{formatNumber(sessions_per_active_mds)}</CardValue>
            <WithTooltip
              id="mdsPerSpecialization.table.sessions_per_active_mdsTooltip"
              tooltip={t(`mdsPerSpecialization.table.sessions_per_active_mdsTooltip`)}
              place="top"
              withoutUnderline
            >
              <CardDescription>{t("dashboard.activeMds.sessions_per_active_mds")}</CardDescription>
            </WithTooltip>
          </CardItem>
        </CardsWrapper>
      </StyledCard>
    </Container>
  );
}
