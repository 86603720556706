import React from "react";
import styled from "styled-components";
import { FlexCenterCSS } from "./Helpers/StyledCss";

const DialogWrapper = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(14, 11, 77, 0.5);
  ${FlexCenterCSS}
  justify-content: center;
  z-index: 500;
  backdrop-filter: blur(6px);
`;

export default function Dialog({ children }: { children: JSX.Element | JSX.Element[] }) {
  return (
    <DialogWrapper role="dialog" aria-modal="true">
      {children}
    </DialogWrapper>
  );
}
