import styled from "styled-components";

const Card = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.text4};
`;

export default Card;
