import styled from "styled-components";
import Button from "../../components/UiKit/Button";
import UiKitCard from "../../components/UiKit/Card";
import { FlexCenterCSS } from "../../components/UiKit/Helpers/StyledCss";

export const Card = styled(UiKitCard)`
  ${FlexCenterCSS}
  flex-direction: column;
  position: relative;
  padding: 25px 25px 36px;
  width: 400px;
  min-height: 640px;
  justify-content: center;
`;

export const Subheader = styled.div<{ $noMaxWidth?: boolean }>`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  ${({ $noMaxWidth }) => !$noMaxWidth && "max-width: 270px;"}
`;

export const Form = styled.form`
  width: 100%;

  > * {
    margin-top: 15px;
  }
`;

export const Submit = styled(Button).attrs({ type: "submit", big: true })`
  margin-top: 33px;
`;

export const FormRow = styled.div<{ center?: boolean }>`
  ${({ center }) => center && "justify-content: center;"}
  display: flex;
  align-items: baseline;
  font-size: 12px;
  color: ${(props) => props.theme.text2};

  & + & {
    margin-top: 13px;
  }
`;

export const FormRowLink = styled.a`
  font-size: 12px;
  color: ${(props) => props.theme.bluePrimary};
  margin: 0 4px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.bluePrimary};
    text-decoration: none;
  }
`;

export const Text = styled.p<{ center?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  ${({ center }) => center && "text-align: center;"}
`;
