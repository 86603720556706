import styled, { css } from "styled-components";
import { ButtonV2ColorsActiveCss, ButtonV2ColorsCss, NavSmallIconButton } from "../../UiKit/Button";
import { DropdownWrapperCSS, FlexCenterCSS, LinkColorCSS } from "../../UiKit/Helpers/StyledCss";

export const DropdownItem = styled.div<{ $active?: boolean }>`
  ${DropdownWrapperCSS}
  width: 320px;
  padding: 20px;
`;

export const DDWrapper = styled.div`
  position: relative;
`;

export const DropdownButton = styled(NavSmallIconButton)<{ $active?: boolean }>`
  margin-left: auto;
  position: relative;

  ${(props) =>
    props.$active &&
    css`
      ${ButtonV2ColorsActiveCss}
      &:hover {
        ${ButtonV2ColorsActiveCss}
      }
    `}

  svg {
    color: inherit;
  }
`;

export const DDSection = styled.div<{ $forUserData?: boolean }>`
  ${(props) =>
    props.$forUserData &&
    css`
      padding-left: 17px;
      padding-right: 17px;
      padding-bottom: 9px;
    `}

  & + & {
    border-top: ${(props) => props.theme.lightBorder};
    margin-top: 12px;
    padding-top: 12px;
  }
`;
export const SectionTitle = styled.div`
  margin-bottom: 4px;
  font-weight: 600;
  color: ${(props) => props.theme.text1};
`;

export const DDButton = styled.a`
  ${LinkColorCSS}
  ${ButtonV2ColorsCss}
  ${FlexCenterCSS}
  height: 36px;
  border-radius: 12px;
  padding: 0 17px;
  cursor: pointer;
`;
