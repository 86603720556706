import React from "react";
import _debounce from "lodash/debounce";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState<{
    width: number;
    height: number;
    widthDecrees: boolean;
  }>({
    width: 0,
    height: 0,
    widthDecrees: false,
  });

  React.useEffect(() => {
    const handleResize = _debounce(() => {
      setWindowSize((value) => ({
        width: window.innerWidth,
        height: window.innerHeight,
        widthDecrees: window.innerWidth < value.width,
      }));
    }, 250);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
