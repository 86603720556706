import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import DashboardActiveMds from "./DashboardActiveMds";
import { UserContext } from "../../contexts/UserContext";
import DashboardSpecializationsCardList from "./DashboardSpecializationsCardList";
import { getDatePeriod, PeriodFilterOptions } from "../../components/PagePeriodFilter/utils";
import { SpecializationsApi } from "../../api/services/SpecializationsApi";
import { MDsPerSpecializationSchema } from "../../api";
import { ApiQueryKeys } from "../../api/ApiQueryKeys";
import * as Styled from "./Dashboard.styled";
import DashboardReports from "./DashboardReports";
import Loader from "../../components/Loader";
import HeaderWithBreadcrumbs from "../../components/Layout/HeaderWithBreadcrumbs";
import Container from "../../components/UiKit/Container";
import { ReactComponent as HandSvg } from "../../assets/icons/hand.svg";
import { ReportsListsStaticData, ReportsListsStaticDataApi } from "../../api/ReportsLists";

export default function Dashboard() {
  const { t } = useTranslation();
  const { user } = React.useContext(UserContext);
  const period = React.useMemo(() => getDatePeriod(PeriodFilterOptions.D30), []);

  const { data: mdsPerSpecializationData, isFetching: isFetchingMdsPerSpec } = useQuery<MDsPerSpecializationSchema>(
    [
      ApiQueryKeys.specializationsMdsPerSpecialization,
      period.from,
      period.until,
      user?.country,
      user?.specialization_interests,
    ],
    () =>
      SpecializationsApi.specializationsMdsPerSpecialization({
        dateFrom: period.from,
        dateUntil: period.until,
        country: user!.country,
      }),
    { enabled: !!user?.country && user?.permissions.mds_per_spec }
  );

  const { data: reportsListsStaticData, isFetching: isFetchingReportsListsStaticData } =
    useQuery<ReportsListsStaticData>(["ReportsListsStaticDataApi"], ReportsListsStaticDataApi, { enabled: !!user });

  const headerTitle = React.useMemo(
    () =>
      reportsListsStaticData?.every(_isEmpty) ? (
        <>
          {t("dashboard.banner.welcome")} <HandSvg />{" "}
          <Styled.SmallTitleText>{t("dashboard.banner.no_data")}</Styled.SmallTitleText>
        </>
      ) : (
        <>
          {t("dashboard.banner.welcomeBack")} <HandSvg />
        </>
      ),
    [reportsListsStaticData, t]
  );

  const showLoader = React.useMemo(
    () =>
      !user ||
      (isFetchingMdsPerSpec && !mdsPerSpecializationData) ||
      (isFetchingReportsListsStaticData && !reportsListsStaticData),
    [user, isFetchingMdsPerSpec, isFetchingReportsListsStaticData, mdsPerSpecializationData, reportsListsStaticData]
  );

  return (
    <>
      <HeaderWithBreadcrumbs title={headerTitle} hideBreadcrumbs />

      <Container>
        <Styled.Wrapper>
          <Loader show={showLoader} />
          <DashboardReports reportsListsStaticData={reportsListsStaticData} />
          <Styled.SpecializationsDataContainer data-uf-dashboard-spec-data>
            {mdsPerSpecializationData && (
              <>
                <DashboardActiveMds data={mdsPerSpecializationData.total} />
                <DashboardSpecializationsCardList mds={mdsPerSpecializationData.per_spec} />
              </>
            )}
          </Styled.SpecializationsDataContainer>
        </Styled.Wrapper>
      </Container>
    </>
  );
}
