import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthUserApi, UserReadWithoutInternals, UsersApi } from "../api";
import { bugsnagNotify } from "../libs/bugsnag";
import { mixpanelSetUser } from "../libs/mixpanel";

interface IUserContextProvider {
  children: React.ReactElement;
}

export const rememberMeLSKey = "rememberMe";

export type User = UserReadWithoutInternals;

interface UserContextI {
  user: User | null;
  setUserAndInit: (user: User, init?: boolean) => void;
  logout: () => void;
  setUserToNull: () => void;
  redirectUrl: string | null;
  setRedirectUrl: (url: string) => void;
  initialUserFetchDone: boolean;
}

export const UserContext = React.createContext<UserContextI>({
  user: null,
  setUserAndInit: () => null,
  logout: () => null,
  setUserToNull: () => null,
  redirectUrl: null,
  setRedirectUrl: () => null,
  initialUserFetchDone: false,
});

export default function UserContextProvider({ children }: IUserContextProvider) {
  const [user, setUser] = React.useState<User | null>(null);
  const [initialUserFetchDone, setInitialUserFetchDone] = React.useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState<string | null>(null);
  const queryClient = useQueryClient();

  const setUserToNull = React.useCallback(() => {
    if (!user) {
      return;
    }
    setUser(null);
  }, [setUser, user]);

  const logout = React.useCallback(async () => {
    try {
      await queryClient.cancelQueries();

      const rememberMeFromLs = localStorage.getItem(rememberMeLSKey);
      const rememberMe = rememberMeFromLs ? JSON.parse(rememberMeFromLs) : false;

      /**
       * If wrong logout will be called, the cookie will not be deleted
       * But BE will invalidate the token, so user will not be logged in anyway
       */
      if (rememberMe) {
        await AuthUserApi.authRememberMeLogout();
      } else {
        await AuthUserApi.authSessionLogout();
      }

      setUser(null);
      setRedirectUrl(null);
    } catch (e: any) {
      bugsnagNotify(e);
    }
  }, [setUser, queryClient]);

  const { data: fetchedUser, error } = useQuery(["UsersApi.usersCurrentUser"], UsersApi.usersCurrentUser, {
    retryOnMount: false,
    retry: false,
  });

  const setUserAndInit = React.useCallback(
    (_user: User, init?: boolean) => {
      setUser(_user);

      if (init) {
        mixpanelSetUser(_user.email);
      }
    },
    [setUser]
  );

  React.useEffect(() => {
    if (error) {
      setInitialUserFetchDone(true);
    }
    if (!error && fetchedUser) {
      setInitialUserFetchDone(true);
      setUserAndInit(fetchedUser, true);
    }
  }, [fetchedUser, error, setUserAndInit, setInitialUserFetchDone]);

  const contextValue = React.useMemo(
    () => ({
      user,
      initialUserFetchDone,
      setUserAndInit,
      logout,
      setUserToNull,
      redirectUrl,
      setRedirectUrl,
    }),
    [user, initialUserFetchDone, setUserAndInit, logout, setUserToNull, redirectUrl, setRedirectUrl]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
