/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PdfReportTimeInterval } from '../models/PdfReportTimeInterval';
import type { ToolReportResponseSchema } from '../models/ToolReportResponseSchema';
import type { ToolsSummaryReportSchema } from '../models/ToolsSummaryReportSchema';
import type { ToolWIthAdResponse } from '../models/ToolWIthAdResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ToolsApi {

  /**
   * Get Tools Report
   * @returns ToolReportResponseSchema Returns JSON (default) or PDF; dependent on the Accept header.
   * @throws ApiError
   */
  public static getToolsReport({
    id,
    app = 'Drugs',
    country,
    dateFrom = '2021-01-01',
    dateUntil,
    specs,
    pdfTimeInterval,
  }: {
    /**
     * `id = {tool_name}_{tool_country}_ad{tool_ad_id}`
     */
    id: string,
    app?: string,
    country?: string,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
    /**
     * Defaults to all specs.
     */
    specs?: Array<string>,
    /**
     * Affects only pdf exports.
     */
    pdfTimeInterval?: PdfReportTimeInterval,
  }): CancelablePromise<ToolReportResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/tools/{id}/report`,
      path: {
        'id': id,
      },
      query: {
        'app': app,
        'country': country,
        'date_from': dateFrom,
        'date_until': dateUntil,
        'specs': specs,
        'pdf_time_interval': pdfTimeInterval,
      },
      errors: {
        401: `Missing token or inactive user.`,
        404: `No tool report with specified id.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Tools:Static-Data
   * @returns ToolWIthAdResponse Successful Response
   * @throws ApiError
   */
  public static toolsStaticData(): CancelablePromise<Array<ToolWIthAdResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/tools`,
      errors: {
        401: `Missing token or inactive user.`,
      },
    });
  }

  /**
   * Tools:Summary-Reports
   * Summary report data for users tools.
   * @returns ToolsSummaryReportSchema Returns JSON (default), CSV or XLSX; dependent on the Accept header.
   * @throws ApiError
   */
  public static toolsSummaryReports({
    ids,
    dateFrom = '2021-01-01',
    dateUntil,
  }: {
    /**
     * `id = {tool_name}_{tool_country}_ad{tool_ad_id}`
     */
    ids?: Array<string>,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
  }): CancelablePromise<Array<ToolsSummaryReportSchema>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/tools/reports`,
      query: {
        'ids': ids,
        'date_from': dateFrom,
        'date_until': dateUntil,
      },
      errors: {
        401: `Missing token or inactive user.`,
        422: `Validation Error`,
      },
    });
  }

}
