/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PdfReportTimeInterval {
  DAY = 'day',
  MONTH = 'month',
}
