import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useQuery } from "@tanstack/react-query";
import CmesListTable from "./CmesListTable";
import TrafficData from "../../../components/ListViewPartials/TrafficData";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { isInsideTheTimeRange } from "../../../utils/dates";
import { CmeApi, CmeReportSummarySchema, CmeResponse } from "../../../api";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import WithLoadingCard from "../../../components/UiKit/WithLoadingCard";
import ListPage from "../../../components/Layout/ListPage";
import { sortByPublishedFromAndState } from "../../../utils/sort";

export default function CmeList() {
  const { periodOption, selectedPeriod } = React.useContext(PagePeriodFilterContext);

  const { data: cmes, isFetching: isFetchingCmes } = useQuery<CmeResponse[]>(
    [ApiQueryKeys.cmesStaticData],
    CmeApi.cmesStaticData
  );

  const { data: cmesReports, isFetching: isFetchingCmesReports } = useQuery<CmeReportSummarySchema[]>(
    [ApiQueryKeys.cmesStaticData, selectedPeriod],
    () => CmeApi.cmesSummaryReports({ dateFrom: selectedPeriod.from, dateUntil: selectedPeriod.until }),
    { enabled: !!selectedPeriod }
  );

  const data = React.useMemo(() => {
    if (!cmes || !cmesReports) {
      return [];
    }

    return cmes
      .map((cme) => ({
        ...cmesReports.find((report) => report.cme_id === cme.id),
        ...cme,
        published_from: cme.ad.published_from,
        published_until: cme.ad.published_until,
      }))
      .filter((entry) => isInsideTheTimeRange(entry, selectedPeriod))
      .sort(sortByPublishedFromAndState);
  }, [cmes, cmesReports, selectedPeriod]);

  const isFetchingTrafficData = React.useMemo(
    () => isFetchingCmesReports && !cmesReports,
    [isFetchingCmesReports, cmesReports]
  );

  const isFetchingTable = React.useMemo(
    () => (isFetchingCmes || isFetchingCmesReports) && !(cmes && cmesReports),
    [isFetchingCmes, isFetchingCmesReports, cmes, cmesReports]
  );

  const showEmptyState = React.useMemo(() => !isFetchingTable && _isEmpty(data), [isFetchingTable, data]);

  return (
    <ListPage pageType="cmesList" showEmptyState={showEmptyState}>
      <TrafficData
        reportType="cmes"
        periodOption={periodOption}
        selectedPeriodReports={cmesReports}
        trafficDataEntryProps={["started", "read_materials", "answered"]}
        isLoading={isFetchingTrafficData}
      />

      <WithLoadingCard isLoading={isFetchingTable}>
        <CmesListTable data={data} />
      </WithLoadingCard>
    </ListPage>
  );
}
