import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const bugsnagClient = process.env.REACT_APP_BUGSNAG_API_KEY
  ? Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: process.env.NODE_ENV === "production" ? "production" : "development",
      redactedKeys: [
        "access_token",

        // case insensitive match
        /^email$/i,
        /^url$/i,
        /^password$/i,
        /^pass$/i,
        /^secret$/i,
        /^name$/i,
        /^company$/i,
        /^address$/i,
        /^user$/i,
        /^username$/i,
        /^token$/i,

        // prefix match
        /^cc_/,
      ],
    })
  : null;

export const ErrorBoundary = bugsnagClient
  ? bugsnagClient.getPlugin("react")?.createErrorBoundary(React)
  : React.Fragment;

export const bugsnagNotify = (message: string | Error) => {
  bugsnagClient?.notify(message);
};
