import styled, { css } from "styled-components";
import { ButtonResetCSS, DropdownWrapperCSS, FlexCenterCSS } from "../UiKit/Helpers/StyledCss";
import { ReactComponent as ArrowIcon } from "../../assets/icons/dropdown-arrow.svg";
import device from "../../theme/utils/breakpoints";
import { ButtonV2ColorsCss } from "../UiKit/Button";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  flex-wrap: wrap;

  @media ${device.L} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1px;

  @media ${device.M} {
    position: relative;
    display: grid;
    max-width: 450px;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      ". . . ."
      ". . custom custom";
  }
`;

const OptionActive = css`
  color: ${(props) => props.theme.text1};
  background: ${(props) => props.theme.blueFair50};

  svg {
    fill: ${(props) => props.theme.text1};
  }

  &:hover {
    color: ${(props) => props.theme.text1};
    background: ${(props) => props.theme.blueFair50};
  }
`;

export const Option = styled.button<{ $active?: boolean; $last?: boolean; $position?: number }>`
  ${ButtonResetCSS}
  ${FlexCenterCSS}
  ${ButtonV2ColorsCss}
  margin-left: -1px;
  justify-content: center;
  font-weight: 300;
  gap: 8px;
  height: 38px;
  padding: 0 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.text4};
  min-width: 98px;
  white-space: normal;

  &:focus-visible {
    position: relative;
    z-index: 1;
  }

  @media ${device.M} {
    width: 100%;
    padding: 0 8px;
    min-width: 72px;

    ${(props) => {
      switch (props.$position) {
        case 4:
          return "border-radius: 12px 0 0 12px;";
        case 3:
          return "border-radius: 0 12px 12px 0;";
        default:
          return "";
      }
    }}
  }

  svg {
    transition: transform 0.3s;
    color: inherit;
  }

  ${(props) => props.$active && OptionActive}
  ${(props) => props.$position === 0 && "border-radius: 12px 0 0 12px;"}
  ${(props) => props.$last && "border-radius: 0 12px 12px 0;"}
`;

export const DDArrowIcon = styled(ArrowIcon)<{ $active?: boolean }>`
  ${(props) => props.$active && "transform: rotate(180deg)"}
`;

export const Label = styled.span<{ $mobile?: boolean }>`
  ${(props) => props.$mobile && "display: none;"}

  @media ${device.M} {
    ${(props) => !props.$mobile && "display: none;"}
    ${(props) => props.$mobile && "display: inline;"}
  }
`;

export const DDWrapper = styled.div`
  position: relative;
  grid-area: custom;

  @media ${device.M} {
    position: static;
  }
`;

export const DateSelectorWrapper = styled.div`
  ${DropdownWrapperCSS}
  width: 326px;

  /* Date picker overrides */
  .rdp {
    --rdp-cell-size: 42px;
    --rdp-accent-color: ${(props) => props.theme.blueFair50};
    --rdp-background-color: ${(props) => props.theme.blueFair20} !important;
    --rdp-accent-color-dark: red !important;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);

    .rdp-day_selected {
      color: ${(props) => props.theme.text1};
    }

    .rdp-cell {
      padding: 4px 0;
    }

    .rdp-caption_label {
      font-size: 19px;
    }
  }
`;

export const DSHeader = styled.div`
  height: 60px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  ${FlexCenterCSS}
  padding: 0 16px;
  gap: 20px;

  input {
    width: 100%;
    height: 30px;
    padding: 0 12px;
    border-radius: 8px;
    border: none;
  }
`;
