import styled, { css } from "styled-components";
import device from "../../theme/utils/breakpoints";
import { ButtonResetCSS, FlexCenterCSS } from "./Helpers/StyledCss";

const primary = css`
  background: ${(props) => props.theme.bluePrimary};
  color: #fff;

  &:hover {
    background: ${(props) => props.theme.bluePrimaryHover};
  }
`;

const secondary = css`
  background: ${(props) => props.theme.blueHealthy};
  color: #fff;

  &:hover {
    background: ${(props) => props.theme.blueHealthyHover};
    color: ${(props) => props.theme.bluePrimary};
  }
`;

const disabledCSS = css`
  background: ${(props) => props.theme.text4};
  color: ${(props) => props.theme.androidGray};
  box-shadow: none;
  cursor: default;
`;

const ctaCss = css`
  height: 50px;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 500;
`;

const Button = styled.button<{
  cta?: boolean;
  big?: boolean;
  variant?: "primary" | "secondary";
  $disabled?: boolean;
  $hideOnSmallScreen?: boolean;
}>`
  ${ButtonResetCSS}
  ${FlexCenterCSS}
  justify-content: center;
  gap: 8px;
  height: 40px;
  padding: 0 24px;
  border-radius: 12px;
  transition: color, background 0.2s;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 2px 2px 6px #00000026;
  white-space: nowrap;
  max-width: 100%;

  ${({ big }) => big && "width: 350px;"}
  ${({ cta }) => cta && ctaCss}
  
  ${({ $hideOnSmallScreen }) =>
    $hideOnSmallScreen &&
    css`
      @media ${device.XS} {
        display: none;
      }
    `}

  ${({ variant, $disabled }) => {
    if ($disabled) {
      return disabledCSS;
    }

    switch (variant) {
      case "secondary":
        return secondary;
      case "primary":
      default:
        return primary;
    }
  }}

  &:active {
    ${({ $disabled }) =>
      !$disabled &&
      css`
        outline: 2px solid ${(props) => props.theme.blueFair} !important;
        box-shadow: none;
      `}
  }
`;

export const ButtonV2ColorsCss = css`
  background: transparent;
  color: ${(props) => props.theme.androidGray};

  &:hover {
    background: ${(props) => props.theme.blueFair20};
    color: ${(props) => props.theme.bluePrimary};
  }

  &:active {
    background: ${(props) => props.theme.blueFair50};
    color: ${(props) => props.theme.bluePrimary};
  }
`;

export const ButtonV2ColorsActiveCss = css`
  background: ${(props) => props.theme.blueFair50};
  color: ${(props) => props.theme.blueSuperDark};
`;

export const NavSmallIconButton = styled.button`
  ${ButtonResetCSS}
  ${ButtonV2ColorsCss}
  width: 32px;
  height: 32px;
  border-radius: 6px;
`;

export default Button;
