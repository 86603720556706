import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
  }

  body {
    background: #fff;
    overflow-x: hidden;
  }

  a {
    color: unset;
    
    &:hover {
      color: unset;
    }
  }

  * {
    :focus-visible {
      box-shadow: 0 0 0 2px ${(props) => props.theme.blueFair};
      outline: none;
    }
  }

  ::selection {
    background: ${(props) => props.theme.lavenderGrayFair};;
  }
`;
