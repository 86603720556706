/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DrugSearchTrendsSchema } from '../models/DrugSearchTrendsSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DrugsApi {

  /**
   * Get Top Drugs Search
   * @returns DrugSearchTrendsSchema Successful Response
   * @throws ApiError
   */
  public static getTopDrugsSearch({
    country,
    specs,
    nTop = 10,
    dateFrom = '2021-01-01',
    dateUntil,
  }: {
    country: string,
    specs?: Array<string>,
    nTop?: number,
    dateFrom?: string,
    /**
     * Defaults to today's date
     */
    dateUntil?: string,
  }): CancelablePromise<DrugSearchTrendsSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/drugs/top-drugs-search-trends`,
      query: {
        'country': country,
        'specs': specs,
        'n_top': nTop,
        'date_from': dateFrom,
        'date_until': dateUntil,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

}
