import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useQuery } from "@tanstack/react-query";
import ToolsListTable from "./ToolsListTable";
import TrafficData from "../../../components/ListViewPartials/TrafficData";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { ToolsApi } from "../../../api/services/ToolsApi";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import { isInsideTheTimeRange } from "../../../utils/dates";
import WithLoadingCard from "../../../components/UiKit/WithLoadingCard";
import { sortByPublishedFromAndState } from "../../../utils/sort";
import { UserContext } from "../../../contexts/UserContext";
import { ToolsSummaryReportSchema, ToolWIthAdResponse } from "../../../api";
import ListPage from "../../../components/Layout/ListPage";

export default function ToolsList() {
  const { periodOption, selectedPeriod } = React.useContext(PagePeriodFilterContext);
  const { user } = React.useContext(UserContext);

  const { data: tools, isFetching: isFetchingTools } = useQuery<ToolWIthAdResponse[]>(
    [ApiQueryKeys.toolsStaticData],
    ToolsApi.toolsStaticData
  );

  const { data: toolsReports, isFetching: isFetchingToolsReports } = useQuery<ToolsSummaryReportSchema[]>(
    [ApiQueryKeys.toolsSummaryReports, selectedPeriod],
    () => ToolsApi.toolsSummaryReports({ dateFrom: selectedPeriod?.from!, dateUntil: selectedPeriod?.until! }),
    { enabled: !!selectedPeriod }
  );

  const data = React.useMemo(() => {
    if (tools && toolsReports) {
      return tools
        .map((tool) => ({
          ...toolsReports.find((report) => report.tool_id === tool.id),
          ...tool,
          published_from: tool.ad.published_from,
          published_until: tool.ad.published_until,
        }))
        .filter((entry) => isInsideTheTimeRange(entry, selectedPeriod))
        .sort(sortByPublishedFromAndState);
    }
    return [];
  }, [tools, toolsReports, selectedPeriod]);

  const trafficDataEntryProps = React.useMemo(
    () => ["tool_opens", "unique_users", ...(user?.permissions?.tool_ad_opens ? ["tool_ad_opens"] : [])],
    [user?.permissions?.tool_ad_opens]
  );

  const isFetchingTrafficData = React.useMemo(
    () => isFetchingToolsReports && !toolsReports,
    [isFetchingToolsReports, toolsReports]
  );

  const isFetchingTable = React.useMemo(
    () => (isFetchingTools || isFetchingToolsReports) && !(tools && toolsReports),
    [isFetchingTools, isFetchingToolsReports, tools, toolsReports]
  );

  const showEmptyState = React.useMemo(() => !isFetchingTable && _isEmpty(data), [isFetchingTable, data]);

  return (
    <ListPage pageType="toolsList" showEmptyState={showEmptyState}>
      <TrafficData
        reportType="tools"
        periodOption={periodOption}
        selectedPeriodReports={toolsReports}
        trafficDataEntryProps={trafficDataEntryProps}
        isLoading={isFetchingTrafficData}
      />

      <WithLoadingCard isLoading={isFetchingTable}>
        <ToolsListTable data={data} />
      </WithLoadingCard>
    </ListPage>
  );
}
