export enum AuthRoutes {
  login = "/auth/login",
  register = "/auth/register",
  resetPassword = "/auth/reset-password",
  forgotPassword = "/auth/forgot-password",
  verify = "/auth/verify",
}

export enum ReportRoutes {
  tools = "/tools",
  ads = "/ads",
  searchAds = "/search-ads",
  drugMaterials = "/drug-materials",
  cmes = "/cmes",
  stories = "/stories",
  mds_per_specialization = "/mds-per-specialization",
  drugs = "/drug-search-trends",
}
