import React from "react";
import { Control, useForm } from "react-hook-form";

type ListPageTableFormReturnType = {
  control: Control;
  handleSubmitClick: () => any;
  handleCheckAllChange: (value: boolean) => any;
  handleItemChange: (value: boolean) => any;
  disableSubmit: boolean;
  resetForm: () => void;
};

export const useCheckItemsForm = (
  submitHandler: (ids: Array<string>, additionalData?: { [key: string]: any }) => any,
  ids: Array<string | number> | undefined,
  checkedIds?: Array<string | number>
): ListPageTableFormReturnType => {
  const defaultValues = React.useMemo(() => {
    if (!ids) {
      return {};
    }

    return ids.reduce(
      (acc: { [key: string]: any }, current: any) => ({
        ...acc,
        [current]: checkedIds ? checkedIds.includes(current) : false,
      }),
      {}
    );
  }, [ids, checkedIds]);

  const { control, handleSubmit, reset, watch, setValue } = useForm<{ [key: string]: any }>({
    defaultValues,
  });

  const allValues = watch();

  const resetForm = React.useCallback(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleCheckAllChange = React.useCallback(
    (e) => {
      const { master, ...rest } = allValues;
      const newValues = Object.keys(rest).reduce(
        (acc: { [key: string]: any }, current: string) => ({
          ...acc,
          [current]: e.target.checked,
        }),
        {}
      );
      reset({ master: e.target.checked, ...newValues });
    },
    [allValues, reset]
  );

  const handleItemChange = React.useCallback(
    (e) => {
      const { name, checked } = e.target;
      const { master, ...rest } = allValues;
      const updatedValues = {
        ...rest,
        [name]: checked,
      };
      const masterValue = Object.values(updatedValues).filter(Boolean).length === Object.values(updatedValues).length;
      setValue("master", masterValue);
    },
    [allValues, setValue]
  );

  const onSubmit = React.useCallback(
    (values, additionalData) => {
      const { master, ...restValues } = values;
      const checkedItemIds = Object.keys(restValues).filter((key) => restValues[key]);
      submitHandler(checkedItemIds, additionalData);
    },
    [submitHandler]
  );

  const disableSubmit = React.useMemo(() => {
    const { master, ...rest } = allValues;
    return Object.values(rest).filter(Boolean).length === 0;
  }, [allValues]);

  return {
    handleSubmitClick: handleSubmit(onSubmit),
    control,
    handleCheckAllChange,
    disableSubmit,
    handleItemChange,
    resetForm,
  };
};
