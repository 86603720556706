import styled from "styled-components";
import { FlexCenterCSS } from "./Helpers/StyledCss";

export const DialogFooter = styled.div`
  ${FlexCenterCSS}
  height: 48px;
  border-top: ${(props) => props.theme.lightBorder};
  justify-content: end;
  padding: 0 32px;
  gap: 34px;
`;

export default DialogFooter;
