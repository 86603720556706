import styled, { css } from "styled-components";
import { Table as BSTable, FormControl as BSFormControl } from "react-bootstrap";
import { ReactComponent as SearchIconSvg } from "../../assets/icons/search-icon.svg";
import { ScrollWrapperMarginCss, SectionWrapperMarginCss } from "../UiKit/Helpers/StyledCss";

export const ScrollWrapper = styled.div<{ $horizontallyScrollableCard?: boolean }>`
  overflow-x: auto;
  ${({ $horizontallyScrollableCard }) => $horizontallyScrollableCard && ScrollWrapperMarginCss};
`;

export const Container = styled.div<{ $horizontallyScrollableCard?: boolean }>`
  min-width: fit-content;
  background: #fff;
  padding: 20px 0;
  border-radius: 12px;

  ${({ $horizontallyScrollableCard }) =>
    $horizontallyScrollableCard &&
    css`
      padding: 20px;
      border: 1px solid ${(props) => props.theme.text4};
      ${SectionWrapperMarginCss}
    `};
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const Table = styled(BSTable)`
  --bs-table-border-color: ${(props) => props.theme.text4};

  a {
    color: ${(props) => props.theme.bluePrimary};
  }

  th {
    font-weight: 600;
    text-decoration: none !important;
    user-select: none;

    i {
      margin-left: 8px;
    }

    > * {
      text-decoration: none !important;
    }
  }

  th,
  td {
    vertical-align: middle;
    padding: 11px;
    text-align: center;
    text-align: right;
  }

  td {
    font-size: 14px;
    font-variant-numeric: tabular-nums;
  }

  tr.sub-row {
    border: transparent;

    td {
      font-size: 14px;
      padding: 6px 11px;
    }

    &.last {
      border-bottom: 1px solid ${({ theme }) => theme.text4};
    }
  }

  th.state,
  td.state,
  th.country,
  td.country {
    text-align: center;
    font-variant-numeric: inherit;
  }

  th.published,
  td.published {
    text-align: left;
    white-space: nowrap;
  }

  th:first-child,
  td:first-child,
  th.title,
  td.title,
  th.name,
  td.name {
    text-align: left;
    font-variant-numeric: inherit;
    font-size: inherit;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  tfoot td {
    border-top-width: 1px;
    border-bottom: none;
  }
`;

export const FormControlContainer = styled.div`
  position: relative;
  width: 318px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;
`;

export const SortIconsContainer = styled.span`
  font-size: 12px;
`;

export const TableFooter = styled.tfoot`
  font-weight: bold;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
`;

export const TableTitle = styled.div<{ $withSubtitle?: boolean }>`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 36px;

  ${({ $withSubtitle }) => $withSubtitle && "margin-bottom: 8px"}
`;

export const FormControl = styled(BSFormControl)`
  padding: 7px 36px 7px 44px;
  border-radius: 12px;
  font-size: 16px;

  &:focus {
    background: #fff;
    box-shadow: none;
  }
`;

export const SearchIcon = styled(SearchIconSvg)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Expand = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.bluePrimary};
  text-decoration: underline;
  font-size: 18px;
`;

export const ClearGlobalFilter = styled.i.attrs(() => ({
  className: "bi bi-x",
}))`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
`;
