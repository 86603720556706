import styled from "styled-components";
import device from "../../theme/utils/breakpoints";

export const CardTitle = styled.div<{ $marginLeft?: boolean }>`
  margin-top: -6px;
  font-size: 18px;
  line-height: 24px;
  padding-right: 90px;

  ${(props) => props.$marginLeft && "margin-left: 20px;"}

  strong {
    font-weight: 500;
  }
`;

export const Wrapper = styled.div<{ loading?: boolean }>`
  padding-top: 34px;
  padding-bottom: 62px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${(props) => props.loading && "min-height: 700px"}
`;

export const SpecializationsDataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media ${device.XL} {
    display: flex;
    flex-direction: column;
  }
`;

export const SmallTitleText = styled.span`
  font-size: 16px;
  font-weight: 400;
`;
