import React from "react";
import WithTooltip from "../../Tooltip/WithTooltip";
import CellWithInfo from "../CellWithInfo";

function HeaderCell({
  id,
  title,
  tooltipText,
  tooltipEnabled,
  withoutUnderline,
}: {
  id: string;
  title: string;
  tooltipText: string;
  tooltipEnabled: boolean;
  withoutUnderline?: boolean;
}) {
  if (tooltipEnabled) {
    return <CellWithInfo id={id} value={title} tooltip={tooltipText} withoutUnderline={withoutUnderline} />;
  }

  return (
    <WithTooltip id={id} tooltip={tooltipText} withoutUnderline={withoutUnderline} disableTooltip place="top">
      <span>{title}</span>
    </WithTooltip>
  );
}

export default HeaderCell;
