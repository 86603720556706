/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_auth_rememberMe_login } from '../models/Body_auth_rememberMe_login';
import type { Body_auth_session_login } from '../models/Body_auth_session_login';
import type { Body_reset_forgotPassword } from '../models/Body_reset_forgotPassword';
import type { Body_reset_resetPassword } from '../models/Body_reset_resetPassword';
import type { Body_verify_request_token } from '../models/Body_verify_request_token';
import type { Body_verify_verify } from '../models/Body_verify_verify';
import type { UserCreateWithoutInternals } from '../models/UserCreateWithoutInternals';
import type { UserReadWithoutInternals } from '../models/UserReadWithoutInternals';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthUserApi {

  /**
   * Auth:Session.Login
   * @returns any Successful Response
   * @throws ApiError
   */
  public static authSessionLogin({
    formData,
  }: {
    formData: Body_auth_session_login,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/session/login`,
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Auth:Session.Logout
   * @returns any Successful Response
   * @throws ApiError
   */
  public static authSessionLogout(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/session/logout`,
      errors: {
        401: `Missing token or inactive user.`,
      },
    });
  }

  /**
   * Auth:Remember Me.Login
   * @returns any Successful Response
   * @throws ApiError
   */
  public static authRememberMeLogin({
    formData,
  }: {
    formData: Body_auth_rememberMe_login,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/remember-me/login`,
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Auth:Remember Me.Logout
   * @returns any Successful Response
   * @throws ApiError
   */
  public static authRememberMeLogout(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/remember-me/logout`,
      errors: {
        401: `Missing token or inactive user.`,
      },
    });
  }

  /**
   * Register:Register
   * @returns UserReadWithoutInternals Successful Response
   * @throws ApiError
   */
  public static registerRegister({
    requestBody,
  }: {
    requestBody: UserCreateWithoutInternals,
  }): CancelablePromise<UserReadWithoutInternals> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/register`,
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Verify:Request-Token
   * @returns any Successful Response
   * @throws ApiError
   */
  public static verifyRequestToken({
    requestBody,
  }: {
    requestBody: Body_verify_request_token,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/request-verify-token`,
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Verify:Verify
   * @returns UserReadWithoutInternals Successful Response
   * @throws ApiError
   */
  public static verifyVerify({
    requestBody,
  }: {
    requestBody: Body_verify_verify,
  }): CancelablePromise<UserReadWithoutInternals> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/verify`,
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reset:Forgot Password
   * @returns any Successful Response
   * @throws ApiError
   */
  public static resetForgotPassword({
    requestBody,
  }: {
    requestBody: Body_reset_forgotPassword,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/forgot-password`,
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reset:Reset Password
   * @returns any Successful Response
   * @throws ApiError
   */
  public static resetResetPassword({
    requestBody,
  }: {
    requestBody: Body_reset_resetPassword,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/reset-password`,
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Check Email In Use
   * @returns any Successful Response
   * @throws ApiError
   */
  public static checkEmailInUse({
    email,
  }: {
    email: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/auth/user/check_email`,
      query: {
        'email': email,
      },
      errors: {
        409: `Email in use.`,
        422: `Validation Error`,
      },
    });
  }

}
