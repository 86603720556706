import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled, { css } from "styled-components";
import "react-tooltip/dist/react-tooltip.css";

const LightTooltipCss = css`
  background: #fff;
  color: ${({ theme }) => theme.text1};
  border: 2px solid ${({ theme }) => theme.bluePrimary};
  border-radius: 12px;
  padding: 9px 12px;
  opacity: 1;
  z-index: 99;
`;

const STTooltip = styled(ReactTooltip)<{ $light?: boolean }>`
  border-radius: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  max-width: 204px;
  z-index: 1000;
  background: ${({ theme }) => theme.blueSuperDark};

  ${({ $light }) => $light && LightTooltipCss}
`;

const ChildrenContainer = styled.div`
  text-align: center;
  white-space: pre-line;
`;

interface ITooltip {
  id: string;
  children: React.ReactElement;
  place?: "top" | "right" | "bottom" | "left";
  delay?: number;
  className?: string;
  type?: "dark" | "light";
}

export default function Tooltip({ id, children, place = "bottom", className, delay = 0, type = "dark" }: ITooltip) {
  return (
    <STTooltip
      $light={type === "light"}
      noArrow={type === "light"}
      anchorId={id}
      place={place}
      delayShow={delay}
      className={className}
    >
      <ChildrenContainer>{children}</ChildrenContainer>
    </STTooltip>
  );
}
