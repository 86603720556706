/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CMEReportResponseSchema } from '../models/CMEReportResponseSchema';
import type { CmeReportSummarySchema } from '../models/CmeReportSummarySchema';
import type { CmeResponse } from '../models/CmeResponse';
import type { PdfReportTimeInterval } from '../models/PdfReportTimeInterval';
import type { PeriodOptionsEnum } from '../models/PeriodOptionsEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CmeApi {

  /**
   * Get Cme Report
   * @returns CMEReportResponseSchema Returns JSON (default) or PDF; dependent on the Accept header.
   * @throws ApiError
   */
  public static getCmeReport({
    name,
    app = 'Drugs',
    country,
    dateFrom = '2021-01-01',
    dateUntil,
    specs,
    pdfTimeInterval,
    periodOption,
  }: {
    name: string,
    app?: string,
    country?: string,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
    /**
     * Defaults to product set specs.
     */
    specs?: Array<string>,
    /**
     * Affects only pdf exports.
     */
    pdfTimeInterval?: PdfReportTimeInterval,
    /**
     * Required only for pdf exports. For `ALL_TIME` only one bar is plotted.
     */
    periodOption?: PeriodOptionsEnum,
  }): CancelablePromise<CMEReportResponseSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/cme/{name}/report`,
      path: {
        'name': name,
      },
      query: {
        'app': app,
        'country': country,
        'date_from': dateFrom,
        'date_until': dateUntil,
        'specs': specs,
        'pdf_time_interval': pdfTimeInterval,
        'period_option': periodOption,
      },
      errors: {
        401: `Missing token or inactive user.`,
        404: `No cme report with specified id.`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Cmes:Static-Data
   * @returns CmeResponse Successful Response
   * @throws ApiError
   */
  public static cmesStaticData(): CancelablePromise<Array<CmeResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/cme`,
      errors: {
        401: `Missing token or inactive user.`,
      },
    });
  }

  /**
   * Cmes:Summary-Reports
   * Summary report data for users cmes.
   * @returns CmeReportSummarySchema Returns JSON (default), CSV or XLSX; dependent on the Accept header.
   * @throws ApiError
   */
  public static cmesSummaryReports({
    ids,
    dateFrom = '2021-01-01',
    dateUntil,
  }: {
    ids?: Array<number>,
    dateFrom?: string,
    /**
     * Default to todays date
     */
    dateUntil?: string,
  }): CancelablePromise<Array<CmeReportSummarySchema>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `${process.env.REACT_APP_API_SERVER}/cme/reports`,
      query: {
        'ids': ids,
        'date_from': dateFrom,
        'date_until': dateUntil,
      },
      errors: {
        401: `Missing token or inactive user.`,
        422: `Validation Error`,
      },
    });
  }

}
