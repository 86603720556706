import styled from "styled-components";
import Button from "../../UiKit/Button";
import { FlexCenterCSS } from "../../UiKit/Helpers/StyledCss";
import { ReactComponent as CookieSvg } from "../../../assets/icons/cookie.svg";
import device from "../../../theme/utils/breakpoints";

export const Cookie = styled(CookieSvg)`
  flex-shrink: 0;
`;

export const DialogWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  background: ${(props) => props.theme.blueFair};
  box-shadow: 0px -2px 5px rgba(196, 196, 198, 0.3);
`;

export const InnerWrapper = styled.div`
  ${FlexCenterCSS};
  padding: 25px 0;
  gap: 24px;

  ${Button} {
    margin-left: auto;
  }

  @media ${device.S} {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
`;

export const Text = styled.div`
  font-weight: 500;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.bluePrimary};
  }
`;
