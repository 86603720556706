import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActiveUsersPerCountrySchema, SpecializationsApi } from "../../api";
import { ApiQueryKeys } from "../../api/ApiQueryKeys";
import { PagePeriodFilterContext } from "../PagePeriodFilter/PagePeriodFilterContextProvider";
import SpinnerLoader from "../UiKit/SpinnerLoader";
import { CountryContext } from "./CountryContext";

const Tooltip = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  left: var(--tooltip-x);
  top: calc(var(--tooltip-y));
  background: #fff;
  border: 2px solid ${({ theme }) => theme.bluePrimary};
  border-radius: 12px;
  z-index: 99;
  padding: 9px 12px;
  text-align: left;

  h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
    line-height: 1;
  }

  p {
    margin: 0;
  }
`;

const Date = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.text2};
`;

const ActiveUsers = styled.div`
  margin-top: 8px;
  font-size: 10px;
  color: ${({ theme }) => theme.text2};
  line-height: 1;
`;

const Strong = styled.strong`
  display: inline-block;
  margin-right: 4px;
  font-size: 21px;
  color: ${({ theme }) => theme.text1};
`;

export default function CountryTooltip() {
  const { mdsLimitedDateRangeReadable, mdsLimitedSelectedPeriod: selectedPeriod } =
    React.useContext(PagePeriodFilterContext);
  const { tooltipCountry, showCountryTooltip } = React.useContext(CountryContext);
  const { t } = useTranslation();

  const { data: activeUsersPerCountry } = useQuery<ActiveUsersPerCountrySchema>(
    [ApiQueryKeys.specializationsActiveUsersPerCountry, selectedPeriod.from, selectedPeriod.until],
    () =>
      SpecializationsApi.specializationsActiveUsersPerCountry({
        dateFrom: selectedPeriod.from,
        dateUntil: selectedPeriod.until,
      }),
    { enabled: showCountryTooltip }
  );

  const activeUsers = React.useMemo(() => {
    if (!activeUsersPerCountry) {
      return null;
    }

    return activeUsersPerCountry.active_users_per_country[tooltipCountry] || 0;
  }, [activeUsersPerCountry, tooltipCountry]);

  if (!tooltipCountry || !showCountryTooltip) {
    return null;
  }
  return (
    <Tooltip>
      <h4>{t(`common.countriesWithoutFlag.${tooltipCountry}`)}</h4>
      <Date>{mdsLimitedDateRangeReadable}</Date>
      <ActiveUsers>
        {activeUsers === null ? (
          <SpinnerLoader size={14} />
        ) : (
          <>
            <Strong>{activeUsers}</Strong>
            <span>{t("mdsPerSpecialization.map.tooltip.activeUsers")}</span>
          </>
        )}
      </ActiveUsers>
    </Tooltip>
  );
}
