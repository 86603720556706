import React from "react";
import { useTranslation } from "react-i18next";
import * as Styled from "./SearchInput.styled";

interface SearchInputI {
  value: string;
  onChange?: (event: any) => void;
  handleClearSearch?: () => void;
  placeholder?: string;
  className?: string;
}

export function SearchInput({ value, onChange, handleClearSearch, placeholder, className }: SearchInputI) {
  const { t } = useTranslation();

  return (
    <Styled.FormControlContainer className={className}>
      <Styled.SearchIcon />
      <Styled.FormControl
        placeholder={placeholder || t("common.table.searchPlaceholder")}
        type="text"
        value={value}
        onChange={onChange}
      />
      {!!value && <Styled.ClearGlobalFilter onClick={handleClearSearch} />}
    </Styled.FormControlContainer>
  );
}
