import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/UserContext";
import { ReactComponent as AppziLogo } from "../../../assets/icons/appzi-logo.svg";
import UserDropdown from "../UserDropdown/UserDropdown";
import * as Styled from "../NavigationBar.styled";
import { AuthRoutes } from "../../../pages/routes";
import Button from "../../UiKit/Button";

const appziToken = process.env.REACT_APP_APPZI_WIDGET;

function RegisterCta() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Styled.NavActions $smallGap>
      <Button onClick={() => navigate(AuthRoutes.login)} variant="secondary">
        {t("navigation.login")}
      </Button>
      <Button $hideOnSmallScreen onClick={() => navigate(AuthRoutes.register)}>
        {t("navigation.signUp")}
      </Button>
    </Styled.NavActions>
  );
}

function NavActions({ forSideNav }: { forSideNav?: boolean }) {
  const { user, initialUserFetchDone, logout } = React.useContext(UserContext);

  if (!initialUserFetchDone) {
    return null;
  }
  if (!user) {
    return <RegisterCta />;
  }
  return (
    <Styled.NavActions $forSideNav={forSideNav}>
      {/* Apzi */}
      <Styled.Button data-az-l={appziToken}>
        <AppziLogo />
      </Styled.Button>

      <UserDropdown user={user} logout={logout} />
    </Styled.NavActions>
  );
}

export default NavActions;
