import styled, { css } from "styled-components";
import device from "../../../theme/utils/breakpoints";

export const FlexCenterCSS = css`
  display: flex;
  align-items: center;
`;

export const FlexCenterWrapper = styled.div`
  ${FlexCenterCSS}
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
`;

export const ButtonResetCSS = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

export const DropdownWrapperCSS = css<{ $active?: boolean; $openRight?: boolean }>`
  background: #ffffff;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 200;
  bottom: 0px;
  right: 0;
  position: absolute;
  opacity: 0;
  transform: translate(-0%, calc(100% - 8px));
  pointer-events: none;
  transition: all 0.3s;

  @media ${device.L} {
    left: unset;
  }

  @media ${device.S} {
    left: 0;
  }

  ${(props) =>
    props.$openRight
      ? `
    left: 0;
    transform: translate(-0%, 100%);
    `
      : `
    right: 0;
    transform: translate(-0%, 100%);
  `}

  ${(props) =>
    props.$active &&
    `
  pointer-events: all;
  opacity: 1;
  bottom: -8px;
`};
`;

export const LinkColorCSS = css`
  color: ${(props) => props.theme.text2};
  transition: all 0.3s;

  &:hover {
    color: ${(props) => props.theme.text1};
  }
`;

export const ScrollWrapperMarginCss = css`
  margin: 0 -32px;

  @media ${device.S} {
    margin: 0 -20px;
  }
`;

export const SectionWrapperMarginCss = css`
  margin: 0 32px;

  @media ${device.S} {
    margin: 0 20px;
  }
`;
