import React from "react";
import styled, { css } from "styled-components";
import { FlexCenterCSS } from "../../../components/UiKit/Helpers/StyledCss";
import { ReactComponent as RLIcon } from "../../../assets/icons/arrow-right.svg";

const RLText = styled.div`
  font-size: 14px;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s;
  white-space: nowrap;
`;

export const RLTextOnHover = css`
  ${RLText} {
    transform: none;
    opacity: 1;
  }
`;

const RLWrapper = styled.div`
  ${FlexCenterCSS}
  gap: 4px;
  margin-top: 5px;
  overflow: hidden;
`;

export default function RevealingLink() {
  return (
    <RLWrapper>
      <RLText>See full list</RLText>
      <RLIcon />
    </RLWrapper>
  );
}
