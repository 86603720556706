import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi/dist/joi";
import styled from "styled-components";
import PasswordController from "../../../components/form/PasswordController";
import { Form, Submit, Text } from "../Auth.styled";
import { ReactComponent as SetNewPassIllustrations } from "../../../assets/icons/auth/set-new-pass.svg";
import { ReactComponent as SuccessIllustrations } from "../../../assets/icons/auth/set-new-pass-success.svg";
import { Heading1 } from "../../../components/UiKit/Typography";
import { AuthUserApi, Body_reset_resetPassword } from "../../../api";

const StyledPassword = styled(PasswordController)`
  margin-bottom: 0.5rem;
  min-width: 350px;
`;

const StyledIllustration = styled(SetNewPassIllustrations)`
  margin: 60px 0 52px;
`;

function Success() {
  const { t } = useTranslation();

  return (
    <>
      <SuccessIllustrations />
      <Heading1 style={{ margin: "40px 0" }}>{t("auth.resetPassword.headerSuccess")}</Heading1>
      <Text center style={{ padding: "0 20px" }}>
        {t("auth.resetPassword.successText")}
      </Text>
    </>
  );
}

function Reset({ setSuccess }: { setSuccess: React.Dispatch<React.SetStateAction<boolean>> }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = React.useMemo(() => searchParams.get("token"), [searchParams]);

  const validationSchema = React.useMemo(
    () =>
      Joi.object({
        password: Joi.string()
          .required()
          .min(8)
          .regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/)
          .messages({
            "string.pattern.base": t("auth.resetPassword.complexPassword"),
            "string.min": t("auth.resetPassword.complexPassword"),
            "string.empty": t("auth.resetPassword.complexPassword"),
          }),
        confirmPassword: Joi.any()
          .valid(Joi.ref("password"))
          .messages({
            "any.only": t("auth.register.step1.confirmPasswordError"),
          }),
      }).unknown(true),
    [t]
  );

  const { control, handleSubmit } = useForm<Body_reset_resetPassword & { confirmPassword: string }>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const { mutate } = useMutation(AuthUserApi.resetResetPassword, {
    onSuccess: async () => {
      setSuccess(true);
    },
  });

  const onSubmit = ({ password }: any) => {
    mutate({ requestBody: { password, token: token as string } });
  };

  return (
    <>
      <StyledIllustration />
      <Heading1>{t("auth.resetPassword.header")}</Heading1>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledPassword name="password" control={control} label={t("auth.resetPassword.password")} required />
        <StyledPassword
          name="confirmPassword"
          control={control}
          label={t("auth.resetPassword.confirmPassword")}
          required
        />
        <Submit style={{ marginTop: "60px" }}>{t("auth.resetPassword.submit") as string}</Submit>
      </Form>
    </>
  );
}

export default function ResetPassword() {
  const [success, setSuccess] = React.useState(false);

  if (success) {
    return <Success />;
  }
  return <Reset setSuccess={setSuccess} />;
}
