import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi/dist/joi";
import FormControlController from "../../../components/form/FormControlController";
import { mixpanelTrack, TrackingConstants } from "../../../libs/mixpanel";
import { Form, Submit } from "../Auth.styled";
import { ReactComponent as ForgotPassIllustrations } from "../../../assets/icons/auth/forgot-pass.svg";
import { ReactComponent as ForgotPassIllustrationsSuccess } from "../../../assets/icons/auth/forgot-pass-success.svg";
import { SignUpButton } from "../AuthCommon/AuthCommon";
import { AuthUserApi, Body_reset_forgotPassword } from "../../../api";
import * as Styled from "./ForgotPassword.styled";

function Success() {
  const { t } = useTranslation();

  return (
    <>
      <ForgotPassIllustrationsSuccess />
      <Styled.Heading>{t("auth.forgotPassword.success.header")}</Styled.Heading>
      <Styled.Subheader $noMargin>{t("auth.forgotPassword.success.subheader")}</Styled.Subheader>
      <Styled.Note>{t("auth.forgotPassword.success.note")}</Styled.Note>
    </>
  );
}

export default function ForgotPassword() {
  const [succeed, setSucceed] = React.useState(false);
  const { t } = useTranslation();

  const validationSchema = React.useMemo(
    () =>
      Joi.object({
        email: Joi.string()
          .required()
          .email({ tlds: { allow: false } })
          .label(t("auth.forgotPassword.email")),
      }).unknown(true),
    [t]
  );

  const { control, handleSubmit } = useForm<Body_reset_forgotPassword>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const { mutate } = useMutation(AuthUserApi.resetForgotPassword, {
    onSuccess: async () => {
      setSucceed(true);
    },
  });

  const onSubmit = ({ email }: Body_reset_forgotPassword) => {
    mixpanelTrack(TrackingConstants.ResetPasswordClick);
    mutate({ requestBody: { email } });
  };

  if (succeed) {
    return <Success />;
  }
  return (
    <>
      <ForgotPassIllustrations />
      <Styled.Heading>{t("auth.forgotPassword.header")}</Styled.Heading>
      <Styled.Subheader>{t("auth.forgotPassword.subheader")}</Styled.Subheader>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControlController name="email" control={control} label={t("auth.forgotPassword.email")} required />
        <Styled.Subheader $textLeft>{t("auth.forgotPassword.formText")}</Styled.Subheader>
        <Submit type="submit">{t("auth.forgotPassword.submit") as string}</Submit>
      </Form>

      <SignUpButton />
    </>
  );
}
