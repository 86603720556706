import { mixpanelTrack, TrackingConstants } from "../libs/mixpanel";
import { ExportContentTypeEnum, exportsFetcher } from "./utils";

export const getReportPDFExport = ({
  type,
  slug,
  from,
  until,
  pdf_time_interval,
  period_option,
}: {
  type: "adz" | "cme" | "tools" | "news";
  slug: number | string;
  from: string;
  until: string;
  pdf_time_interval: string;
  period_option?: string;
}) => {
  mixpanelTrack(TrackingConstants.ExportPdf, { ReportType: type });

  exportsFetcher(
    `${process.env.REACT_APP_API_SERVER}/${type}/${slug}/report?date_from=${from}&date_until=${until}&pdf_time_interval=${pdf_time_interval}&period_option=${period_option}`,
    ExportContentTypeEnum.PDF
  );
};
