import React from "react";
import { DateRange } from "react-day-picker";
import { useSearchParams } from "react-router-dom";
import {
  DateSearchParams,
  DEFAULT_FROM_DATE_MD_PER_SPEC,
  getAllSearchParams,
  getDatePeriod,
  getPeriodFromSearchParams,
  limitDatePeriod,
  Period,
  PeriodFilterOptions,
} from "./utils";
import { mixpanelTrack, TrackingConstants } from "../../libs/mixpanel";
import { formatDateRange } from "../../utils/numbers";

const DEFAULT_PERIOD_OPTION = PeriodFilterOptions.D30;

export const PagePeriodFilterContext = React.createContext<{
  periodOption: PeriodFilterOptions;
  selectedPeriod: Period;
  mdsLimitedSelectedPeriod: Period;
  dateRangeReadable: string;
  mdsLimitedDateRangeReadable: string;
  handleSelectChange: (value: PeriodFilterOptions, dateRange?: DateRange) => void;
}>({
  periodOption: DEFAULT_PERIOD_OPTION,
  selectedPeriod: getDatePeriod(DEFAULT_PERIOD_OPTION),
  dateRangeReadable: "",
  mdsLimitedSelectedPeriod: limitDatePeriod(getDatePeriod(DEFAULT_PERIOD_OPTION), DEFAULT_FROM_DATE_MD_PER_SPEC),
  mdsLimitedDateRangeReadable: "",
  handleSelectChange: () => {},
});

export default function PagePeriodFilterContextProvider({ children }: { children: React.ReactElement }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [periodOption, setPeriodOption] = React.useState<PeriodFilterOptions>(
    getPeriodFromSearchParams(searchParams) ? PeriodFilterOptions.CUSTOM_DATE_RANGE : DEFAULT_PERIOD_OPTION
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState<Period>(
    getPeriodFromSearchParams(searchParams) || getDatePeriod(DEFAULT_PERIOD_OPTION)
  );

  const handleSelectChange = React.useCallback(
    (value: PeriodFilterOptions, dateRange?: DateRange) => {
      mixpanelTrack(TrackingConstants.PeriodFilterChange, { "Filter Value": value });
      setPeriodOption(value);

      const dataPeriod = getDatePeriod(value, dateRange);

      setSearchParams((params) => ({
        ...getAllSearchParams(params),
        [DateSearchParams.FROM]: dataPeriod.from,
        [DateSearchParams.UNTIL]: dataPeriod.until,
      }));

      setSelectedPeriod(dataPeriod);
    },
    [setSelectedPeriod, setSearchParams]
  );

  const mdsLimitedSelectedPeriod = React.useMemo(
    () => limitDatePeriod(selectedPeriod, DEFAULT_FROM_DATE_MD_PER_SPEC),
    [selectedPeriod]
  );
  const mdsLimitedDateRangeReadable = React.useMemo(
    () => formatDateRange(mdsLimitedSelectedPeriod.from, mdsLimitedSelectedPeriod.until),
    [mdsLimitedSelectedPeriod]
  );

  const dateRangeReadable = React.useMemo(
    () => formatDateRange(selectedPeriod.from, selectedPeriod.until),
    [selectedPeriod.from, selectedPeriod.until]
  );

  const contextValue = React.useMemo(
    () => ({
      periodOption,
      selectedPeriod,
      handleSelectChange,
      dateRangeReadable,
      mdsLimitedSelectedPeriod,
      mdsLimitedDateRangeReadable,
    }),
    [
      periodOption,
      selectedPeriod,
      handleSelectChange,
      dateRangeReadable,
      mdsLimitedSelectedPeriod,
      mdsLimitedDateRangeReadable,
    ]
  );

  return <PagePeriodFilterContext.Provider value={contextValue}>{children}</PagePeriodFilterContext.Provider>;
}
