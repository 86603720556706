import React from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { ReportRoutes } from "../../pages/routes";

interface ICountryContextProvider {
  children: JSX.Element;
}

export const CountryContext = React.createContext<{
  tooltipCountry: string;
  selectedCountry: string;
  setSelectedCountry: (selectedCountry: string) => void;
  setTooltipCountry: (selectedCountry: string) => void;
  showCountryTooltip: boolean;
}>({
  tooltipCountry: "",
  selectedCountry: "SI",
  setSelectedCountry: () => null,
  setTooltipCountry: () => null,
  showCountryTooltip: false,
});

export default function CountryContextProvider({ children }: ICountryContextProvider) {
  const { user } = React.useContext(UserContext);
  const [defaultCountrySet, setDefaultCountrySet] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState<string>(user?.country || "SI");
  const [tooltipCountry, setTooltipCountry] = React.useState("");
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (!defaultCountrySet && user?.country) {
      setSelectedCountry(user.country);
      setDefaultCountrySet(true);
    }
  }, [defaultCountrySet, user?.country]);

  const showCountryTooltip = React.useMemo(() => pathname.includes(ReportRoutes.mds_per_specialization), [pathname]);

  React.useEffect(() => {
    setTooltipCountry("");
  }, [pathname]);

  const contextValue = React.useMemo(
    () => ({
      selectedCountry,
      setSelectedCountry,
      tooltipCountry,
      setTooltipCountry,
      showCountryTooltip,
    }),
    [selectedCountry, setSelectedCountry, tooltipCountry, setTooltipCountry, showCountryTooltip]
  );

  return <CountryContext.Provider value={contextValue}>{children}</CountryContext.Provider>;
}
