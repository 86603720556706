export type ThemeType = typeof InsightsTheme;

const InsightsTheme = {
  text1: "#212529",
  text2: "#8A8A8E",
  text3: "#C4C4C6",
  text4: "#DCDCDD",
  androidGray: "#666666",
  systemBackground: "#FAFAFA",

  redDanger: "#FF2D26",
  redDangerDark: "#B30600",
  redDangerBackground: "rgba(255, 45, 38, 0.1)",

  bluePrimary: "#2E6BE6",
  bluePrimaryHover: "#8CB1FF",
  bluePrimaryOutline: "#bcb1ff",

  blueFair: "#CCDDFF",
  blueFair20: "#ccddff33",
  blueFair50: "#ccddff80",

  blueSuperDark: "#0E0B4D",

  blueHealthy: "#8CB1FF",
  blueHealthyHover: "#EAF1FF",

  lightGray: "#E5E7F4",
  lavenderGrayLight: "#9B9BCC",
  lavenderGrayFair: "#C9CDED",
  navBoxShadow: "0px 1px 5px rgba(196, 196, 198, 0.3)",

  lightBorder: "1px solid rgba(138, 138, 142, 0.2)",
  containerMaxWidth: "1788px",
  smallContainerMaxWidth: "1380px",
  footerBackground: "#d2d2e5",

  fontSourceSans: '"Source Sans Pro", sans-serif',

  navBar: {
    height: "60px",
  },
};

export default InsightsTheme;
