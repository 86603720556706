import React from "react";
import styled from "styled-components";
import ActiveCountries from "./ActiveCountries/ActiveCountries";
import InactiveCountries from "./InactiveCountries";

const MapWrapper = styled.div`
  margin-bottom: 12px;
  display: block;
  overflow: hidden;

  g[id] {
    :hover {
      path {
        transition: all 0.15s ease-in-out;
        fill: ${({ theme }) => theme.bluePrimary};
        cursor: pointer;
      }
    }
  }

  g[data-selected="true"] {
    path {
      fill: ${({ theme }) => theme.bluePrimary};
    }
  }
`;

const MapSvg = styled.svg`
  transform: translateX(80px);
  display: block;
  margin: -52px auto;
  transform: scale(0.8);
`;

export default function CountriesMap() {
  return (
    <MapWrapper>
      <MapSvg width="947" height="490" viewBox="0 0 947 490" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ActiveCountries />
        <InactiveCountries />
      </MapSvg>
    </MapWrapper>
  );
}
