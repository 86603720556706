import * as React from "react";
import { FormCheck } from "react-bootstrap";
import { Control, Controller } from "react-hook-form";
import styled from "styled-components";
import FormGroupWrapper from "./FormGroupWrapper";

const StyledFormCheck = styled(FormCheck)<{ signUp: boolean }>`
  font-size: 14px;
  padding-left: 24px;
  line-height: 18px;
  color: ${(props) => props.theme.text2};
  min-height: unset;
  margin-bottom: 0;

  input.form-check-input {
    width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-left: -24px;
  }
`;

interface IReactSelectController {
  className?: string;
  name: string;
  control: Control<any>;
  type?: "checkbox" | "radio";
  label?: string | React.ReactNode;
  customOnChange?: (targetValue: any) => any;
}

export default function FormCheckController({
  className,
  control,
  name,
  label,
  type = "checkbox",
  customOnChange,
}: IReactSelectController) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, fieldState: { invalid, error } }) => {
        const handleChange = (e: any) => {
          onChange(e);
          if (customOnChange) {
            customOnChange(e);
          }
        };
        return (
          <FormGroupWrapper invalid={invalid} className={className} error={error}>
            <StyledFormCheck
              type={type}
              id={name}
              name={name}
              checked={value || false}
              ref={ref}
              value={name}
              onChange={handleChange}
              label={label}
              isInvalid={invalid}
            />
          </FormGroupWrapper>
        );
      }}
    />
  );
}
