import styled from "styled-components";
import { ReactComponent as SuccessIllustrationSVG } from "../../../assets/icons/auth/set-new-pass-success.svg";
import { Heading1 as UiKitHeading1 } from "../../../components/UiKit/Typography";
import { FormRow } from "../Auth.styled";

export const Container = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: -12px;
`;

export const InfoContainer = styled.div<{ $left?: boolean }>`
  font-size: 14px;

  & + & {
    margin-top: 20px;
  }

  ${(props) => props.$left && "text-align: left;"}
`;

export const StyledFormRow = styled(FormRow)`
  margin-top: 41px;
  position: relative;

  /* Spinner */
  svg {
    position: absolute;
    top: -40px;
  }
`;

export const Heading1 = styled(UiKitHeading1)`
  margin-bottom: 12px;
`;

export const SuccessIllustration = styled(SuccessIllustrationSVG)`
  margin-top: 60px;
`;

export const List = styled.ol`
  margin-top: 12px;
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
`;

export const ContentContainer = styled.div`
  padding: 0 15px;
`;

export const RegisterHeading1 = styled(UiKitHeading1)`
  margin: 15px 0 28px;
`;
