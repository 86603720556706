import styled from "styled-components";
import { ReactComponent as DDArrow } from "../../assets/icons/dropdown-arrow.svg";

export const Arrow = styled(DDArrow)<{ $down?: boolean }>`
  fill: ${({ theme }) => theme.bluePrimary};
  width: 26px;
  height: 26px;
  padding: 6px;

  ${({ $down }) => $down && "transform: rotate(180deg);"}
`;

export const Percentage = styled.div<{ $width: string }>`
  background: ${({ theme }) => theme.blueHealthy};
  height: 18px;
  width: ${({ $width }) => $width};
  cursor: pointer;
  transition: border 0.2s ease-in-out;
  border: 2px solid transparent;

  &:hover {
    border: ${({ theme }) => `2px solid ${theme.bluePrimary}`};
  }
`;

export const PercentageTTWrapper = styled.div`
  text-align: left;

  h3 {
    font-size: 14px;
    margin-bottom: 2px;
  }

  p {
    margin-bottom: 2px;
    line-height: 1.2;
    font-size: 10px;
    color: ${({ theme }) => theme.text2};

    &:last-child {
      margin-top: 12px;
    }

    strong {
      font-size: 21px;
      color: ${({ theme }) => theme.text1};
    }
  }
`;

export const TableWrapper = styled.div`
  margin: 0 -20px;
`;

export const HiddenPrefix = styled.span`
  opacity: 0;
  pointer-events: none;
  font-size: 16px;
`;
