import styled from "styled-components";
import { FormControl as BSFormControl } from "react-bootstrap";
import { ReactComponent as SearchIconSvg } from "../../../assets/icons/search-icon.svg";

export const FormControlContainer = styled.div`
  position: relative;
  width: 318px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
`;

export const SearchIcon = styled(SearchIconSvg)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ClearGlobalFilter = styled.i.attrs(() => ({
  className: "bi bi-x",
}))`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
`;

export const FormControl = styled(BSFormControl)`
  background: ${(props) => props.theme.systemBackground};
  border: none;
  padding: 7px 36px 7px 44px;
  border-radius: 12px;
  font-size: 16px;

  &:focus {
    background: ${(props) => props.theme.systemBackground};
  }
`;
