import dayjs from "dayjs";
import _isUndefined from "lodash/isUndefined";
import _isNaN from "lodash/isNaN";
import _isNumber from "lodash/isNumber";
import { bugsnagNotify } from "../libs/bugsnag";

export const formatNumber = (value: string | number): string => {
  if (_isUndefined(value)) {
    bugsnagNotify("Undefined was passed as value");
    return "0";
  }

  const number = Number(value);

  if (_isNaN(number)) {
    bugsnagNotify(`Not a number was passed as value. Value: ${value}`);
    return value as string;
  }
  if (_isNumber(number)) {
    return number
      .toString()
      .split("")
      .reverse()
      .map((l, i) => {
        if ((i + 1) % 3 === 0) {
          return ` ${l}`;
        }
        return l;
      })
      .reverse()
      .join("");
  }

  return value as string;
};

export const formatDate = (date: string | Date): string => dayjs(date).format("DD.MM.YYYY");
export const formatDateRange = (from: string, to: string): string => `${formatDate(from)} - ${formatDate(to)}`;
