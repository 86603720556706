import styled from "styled-components";
import { Submit as AuthSubmit } from "../Auth.styled";
import { ReactComponent as LoginIllustrationSvg } from "../../../assets/icons/auth/login.svg";

export const Submit = styled(AuthSubmit)`
  margin-top: 56px;
`;

export const LoginIllustration = styled(LoginIllustrationSvg)`
  margin-top: 18px;
  margin-bottom: 20px;
`;
