/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PeriodOptionsEnum {
  ALL_TIME = 'ALL_TIME',
  LAST_MONTH = 'LAST_MONTH',
  _30_DAYS = '30_DAYS',
  _3_MONTHS = '3_MONTHS',
  _6_MONTHS = '6_MONTHS',
  _12_MONTHS = '12_MONTHS',
  CUSTOM_DATE_RANGE = 'CUSTOM_DATE_RANGE',
}
