import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import TopNavigationBar from "../Navigation/TopNavigationBar";
import Footer from "./Footer";
import SpecializationSettingsModal from "../Modals/SpecializationSettingsModal";
import CookiesConsent from "../Modals/CookiesConsent";
import SideNavigationBar from "../Navigation/SideNavigationBar";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { UserContext } from "../../contexts/UserContext";
import CountryTooltip from "../CountriesMap/CountryTooltip";
import device from "../../theme/utils/breakpoints";

const navMobileThreshold = 1131;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.systemBackground};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding: 0;
`;

const OutletContainer = styled.div<{ isLoading?: boolean }>`
  position: relative;
  min-height: calc(100vh - ${(props) => props.theme.navBar.height} - 260px);
`;

const MainWrapper = styled.div<{ $withSideNav?: boolean }>`
  ${(props) =>
    props.$withSideNav &&
    css`
      display: grid;
      grid-template-columns: auto minmax(0, 1fr);

      @media ${device.L} {
        display: block;
      }
    `}
`;

const ContentWrapper = styled.div`
  max-width: 100vw;
`;

export default function Layout() {
  const [navOpened, setNavOpened] = React.useState(window.innerWidth >= navMobileThreshold);
  const { pathname } = useLocation();
  const windowSize = useWindowSize();
  const { user } = React.useContext(UserContext);

  // Close on navigation
  React.useEffect(() => {
    if (window.innerWidth < navMobileThreshold) {
      setNavOpened(false);
    }
  }, [pathname]);

  // Close on window resize
  React.useEffect(() => {
    if (windowSize.widthDecrees && windowSize.width < navMobileThreshold) {
      setNavOpened(false);
    }
  }, [windowSize.widthDecrees, windowSize.width]);

  return (
    <Wrapper>
      <TopNavigationBar setNavOpened={setNavOpened} navOpened={navOpened} />
      <MainWrapper $withSideNav={!!user}>
        <SideNavigationBar setNavOpened={setNavOpened} navOpened={navOpened} />
        <ContentWrapper>
          <OutletContainer>
            <Outlet />
            <SpecializationSettingsModal />
            <CookiesConsent />
          </OutletContainer>
          <Footer />
          <CountryTooltip />
        </ContentWrapper>
      </MainWrapper>
    </Wrapper>
  );
}
