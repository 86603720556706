import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FormRow, FormRowLink } from "../Auth.styled";

export function SignUpButton() {
  const { t } = useTranslation();

  return (
    <FormRow center style={{ marginTop: "16px" }}>
      <span>{t("auth.login.dontHaveAcc")}</span>
      <FormRowLink as={Link} to="/auth/register">
        {t("auth.login.signup")}
      </FormRowLink>
    </FormRow>
  );
}

export function LoginButton({ noText }: { noText?: boolean }) {
  const { t } = useTranslation();

  return (
    <FormRow center style={{ marginTop: "16px" }}>
      {!noText && <span>{t("auth.register.footer.haveAccount")}</span>}
      <FormRowLink as={Link} to="/auth/login">
        {t("auth.register.footer.login")}
      </FormRowLink>
    </FormRow>
  );
}
