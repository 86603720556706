import React from "react";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi/dist/joi";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormControlController from "../../../components/form/FormControlController";
import FormCheckController from "../../../components/form/FormCheckController";
import PasswordController from "../../../components/form/PasswordController";
import { Form, FormRow, FormRowLink, Submit } from "../Auth.styled";
import { RegisterContext } from "./RegisterContext";
import { AuthUserApi } from "../../../api";

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  agreement: string;
  email_consent: boolean;
};

export default function RegisterStepOne() {
  const [searchParams] = useSearchParams();
  const { formState, setFormState, setStep } = React.useContext(RegisterContext);
  const { t } = useTranslation();

  const validationSchema = React.useMemo(
    () =>
      Joi.object({
        email: Joi.string()
          .required()
          .email({ tlds: { allow: false } })
          .messages({
            "string.email": t("auth.register.step1.validEmail"),
            "string.empty": t("auth.register.step1.validEmail"),
          }),
        password: Joi.string()
          .required()
          .min(8)
          .regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/)
          .messages({
            "string.pattern.base": t("auth.register.step1.complexPassword"),
            "string.min": t("auth.register.step1.complexPassword"),
            "string.empty": t("auth.register.step1.complexPassword"),
          })
          .label(t("auth.register.step1.password")),
        confirmPassword: Joi.any()
          .valid(Joi.ref("password"))
          .messages({
            "any.only": t("auth.register.step1.confirmPasswordError"),
          }),
        agreement: Joi.boolean()
          .invalid(false)
          .messages({
            "any.invalid": t("auth.register.step1.agreement.validation"),
          }),
        email_consent: Joi.boolean(),
      }).unknown(true),
    [t]
  );

  const { handleSubmit, control, setError } = useForm<any>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      email: searchParams.get("suggested_email") || formState.email || "",
      password: formState.password || "",
      confirmPassword: formState.password || "",
      agreement: false,
      email_consent: false,
    },
  });

  const onSubmit = async (data: FormValues) => {
    const { email, password, email_consent } = data;
    try {
      await AuthUserApi.checkEmailInUse({ email });
      setFormState({ ...formState, email, password, email_consent });
      setStep(2);
    } catch (e) {
      setError("email", { type: "custom", message: t("auth.register.step1.emailAlreadyInUse") }, { shouldFocus: true });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControlController name="email" control={control} label={t("auth.register.step1.email")} required />
      <PasswordController
        data-cy-reg-step1-pass
        name="password"
        control={control}
        label={t("auth.register.step1.password")}
        required
      />
      <PasswordController
        data-cy-reg-step1-confirm-pass
        name="confirmPassword"
        control={control}
        label={t("auth.register.step1.confirmPassword")}
        required
      />

      <FormRow>
        <FormCheckController
          name="agreement"
          control={control}
          label={
            <>
              {t("auth.register.step1.agreement.label")}
              <FormRowLink href="https://mediately.co/si/docs/eula">
                {t("auth.register.step1.agreement.termsAndConditions")}
              </FormRowLink>
              <span> {t("auth.register.step1.agreement.and")}</span>
              <FormRowLink href="https://mediately.co/business/en/privacy-policy/">
                {t("auth.register.step1.agreement.privacyPolicy")}
              </FormRowLink>
            </>
          }
        />
      </FormRow>

      <FormRow>
        <FormCheckController name="email_consent" control={control} label={t("auth.register.step1.emailConsent")} />
      </FormRow>

      <Submit>{t("auth.register.step1.submit")}</Submit>
    </Form>
  );
}
