import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReportSpecializationTable from "../../../components/ReportPageComponents/ReportSpecializationTable";
import ReportPeriodChart from "../../../components/ReportPageComponents/ReportPeriodChart";
import ReportPeriodTable from "../../../components/ReportPageComponents/ReportPeriodTable";
import ReportSpecializationChart from "../../../components/ReportPageComponents/ReportSpecializationChart";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { SectionContainer, SectionTitle } from "../../../components/ReportPageComponents/StyledComponents";
import { DataTypeContext, MONTHLY } from "../../../components/ReportPageComponents/DataTypeSelectionContext";
import { AddReportResponseSchema, AdResponse, AdzApi } from "../../../api";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import ReportPage from "../../../components/ReportPageComponents/ReportPage";
import WithLoadingCard from "../../../components/UiKit/WithLoadingCard";
import { getReportPDFExport } from "../../../api/ReportPDFExport";
import { UserContext } from "../../../contexts/UserContext";
import { AdType } from "../AdsList/AdsList";

const PAGE_TYPE = "ads.ad";

function CmrAdd({ data, show }: { data: AddReportResponseSchema; show: boolean }) {
  const { t } = useTranslation();

  if (!show) {
    return null;
  }
  return (
    <SectionContainer>
      <SectionTitle>{t(`${PAGE_TYPE}.sectionTitle3`)}</SectionTitle>
      <ReportSpecializationChart data={data.by_title_and_spec} keyProp="ad_views" type="rest" />
      <ReportSpecializationTable
        data={data.by_title_and_spec}
        keys={["translation", "ad_views", "ad_views_unique_users"]}
        type="rest"
        defaultSortKey="ad_views"
      />
    </SectionContainer>
  );
}

interface ReportI {
  data: AddReportResponseSchema | undefined;
  dataType: string;
  show: boolean;
  adType: AdType;
}

function Report({ data, dataType, show, adType }: ReportI) {
  const { t } = useTranslation();
  const { user } = React.useContext(UserContext);

  const showOpens = React.useMemo(() => {
    if (adType === AdType.NewsFeedAd || adType === AdType.WebAd) {
      return !!user?.permissions?.newsfeed_ad_opens;
    }
    if (adType === AdType.SearchAd) {
      return !!user?.permissions?.search_ad_opens;
    }

    return true;
  }, [adType, user?.permissions?.newsfeed_ad_opens, user?.permissions?.search_ad_opens]);

  const keys = React.useMemo(
    () => ["ad_views", "ad_views_unique_users", ...(showOpens ? ["ad_opens", "ad_opens_unique_user"] : [])],
    [showOpens]
  );

  if (!show || !data) {
    return null;
  }
  return (
    <>
      <SectionContainer>
        <SectionTitle>{t(`${PAGE_TYPE}.sectionTitle1`)}</SectionTitle>

        <ReportPeriodChart
          reportType="ads"
          data={dataType === MONTHLY ? data.by_month : data.by_day}
          keys={["ad_views", "ad_views_unique_users"]}
          subtitle={showOpens ? t(`${PAGE_TYPE}.chartSubtitle1`) : ""}
        />

        <ReportPeriodChart
          secondaryColors
          hide={!showOpens}
          reportType="ads"
          data={dataType === MONTHLY ? data.by_month : data.by_day}
          keys={["ad_opens", "ad_opens_unique_user"]}
          subtitle={t(`${PAGE_TYPE}.chartSubtitle2`)}
        />

        <ReportPeriodTable
          data={dataType === MONTHLY ? data.by_month : data.by_day}
          total={data.by_day_total}
          keys={keys}
          showOpens={showOpens}
          totalTooltip
        />
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>{t(`${PAGE_TYPE}.sectionTitle2`)}</SectionTitle>
        <ReportSpecializationChart
          data={data.by_title_and_spec}
          keyProp="ad_views"
          type={adType === AdType.CmrAd ? "top" : "all"}
        />
        <ReportSpecializationTable
          data={data.by_title_and_spec}
          keys={["translation", ...keys]}
          type={adType === AdType.CmrAd ? "top" : "all"}
          defaultSortKey="ad_views"
        />
      </SectionContainer>

      <CmrAdd data={data} show={adType === AdType.CmrAd} />
    </>
  );
}

export default function Ad({ pageType = PAGE_TYPE }: { pageType?: "ads.ad" | "ads.search-ad" | "ads.drug-materials" }) {
  const { t } = useTranslation();
  const { id: idFromParams } = useParams();
  const id: number = React.useMemo(() => Number.parseInt(idFromParams!, 10), [idFromParams]);
  const { type: dataType } = React.useContext(DataTypeContext);
  const { selectedPeriod } = React.useContext(PagePeriodFilterContext);

  const { data: ads } = useQuery<AdResponse[]>([ApiQueryKeys.adsStaticData], AdzApi.adsStaticData);
  const selectedAd = React.useMemo(() => ads?.find((ad) => ad.id === id), [id, ads]);

  const { refetch, data, isFetching, isError } = useQuery<AddReportResponseSchema>(
    [ApiQueryKeys.getAdsReport, id, selectedPeriod],
    () =>
      AdzApi.getAdsReport({
        id,
        dateFrom: selectedPeriod?.from,
        dateUntil: selectedPeriod?.until,
      }),
    { enabled: !!id }
  );

  const exportPDF = () => {
    getReportPDFExport({
      type: "adz",
      slug: id,
      from: selectedPeriod!.from,
      until: selectedPeriod!.until,
      pdf_time_interval: dataType === MONTHLY ? "month" : "day",
    });
  };

  const reportHeaderProps = React.useMemo(
    () => ({
      title: selectedAd?.insights_display_name || selectedAd?.title,
      from: selectedAd?.published_from,
      until: selectedAd?.published_until,
      image: selectedAd?.image,
    }),
    [selectedAd]
  );

  React.useEffect(() => {
    refetch();
  }, [refetch, selectedPeriod]);

  const showEmptyState = React.useMemo(() => {
    if (isFetching && !data) {
      return false;
    }

    return _isEmpty(data?.by_day);
  }, [data, isFetching]);

  return (
    <ReportPage
      reportHeaderProps={reportHeaderProps}
      showEmptyState={showEmptyState}
      isFetching={isFetching}
      isError={isError}
      exportPDF={exportPDF}
      title={t(`${pageType}.title`)}
    >
      <WithLoadingCard isLoading={isFetching && !data}>
        <Report data={data} dataType={dataType} show={!showEmptyState} adType={selectedAd?.type as AdType} />
      </WithLoadingCard>
    </ReportPage>
  );
}
