import React from "react";
import { formatDateRange, formatNumber } from "../../../utils/numbers";
import * as Styled from "./DataCell.styled";

export const PUBLISHED_KEY = "published";
export const STATE_KEY = "state";

export default function DataCell({ record, prop }: { record: any; prop: string }) {
  const ad = React.useMemo(() => record.ad || record, [record]);
  const { state } = ad;

  switch (prop) {
    case STATE_KEY:
      return <Styled.State $state={state}>{state}</Styled.State>;
    case PUBLISHED_KEY:
      return <span>{formatDateRange(ad.published_from, ad.published_until)}</span>;
    default:
      return <Styled.NumWrapper>{formatNumber(record[prop])}</Styled.NumWrapper>;
  }
}
