import React from "react";
import { Form } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

const StyledFeedback = styled.div`
  color: ${(props) => props.theme.redDanger};
  display: block;
  font-size: 10px;
  line-height: 1.4;
  margin-top: 2px;
`;

const StyledLabel = styled.div`
  font-size: 10px;
  margin-bottom: 2px;
  color: ${(props) => props.theme.text2};
`;

const Required = styled.span<{ $hide?: boolean }>`
  color: ${(props) => props.theme.redDanger};
  &:before {
    content: "*";
  }
  ${({ $hide }) => $hide && "display: none;"}
`;

interface IFormGroupWrapper {
  label?: string;
  children: JSX.Element;
  className?: string;
  text?: string;
  invalid?: boolean;
  error?: FieldError;
  required?: boolean;
}

export default function FormGroupWrapper({
  label,
  children,
  className,
  text,
  invalid,
  error,
  required,
}: IFormGroupWrapper) {
  return (
    <Form.Group className={className}>
      {label && (
        <StyledLabel>
          <Required $hide={!required} />
          {label.toUpperCase()}
        </StyledLabel>
      )}
      {children}
      {text && <Form.Text className="text-muted">{text}</Form.Text>}
      {invalid && <StyledFeedback>{error?.message}</StyledFeedback>}
    </Form.Group>
  );
}
