import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ExportContentTypeEnum } from "../../../api/utils";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download-icon.svg";
import { ReactComponent as DDArrow } from "../../../assets/icons/dropdown-arrow.svg";
import Button from "../../UiKit/Button";
import { DropdownWrapperCSS, FlexCenterCSS } from "../../UiKit/Helpers/StyledCss";

const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    ${DropdownWrapperCSS}
    opacity: 1;
    pointer-events: all;
    border: none;
    inset: 2px 0px auto auto !important;
    width: 130px;
    min-width: 130px;
    transition: none;
  }

  .dropdown-item {
    ${FlexCenterCSS};
    gap: 8px;
    color: ${(props) => props.theme.text2};
    font-size: 14px;
    padding: 7px 24px;

    &:hover {
      background: none;
      color: ${(props) => props.theme.text1};
    }
  }
`;

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomDDToggle = React.forwardRef(({ children, onClick }: any, ref) => (
  <Button
    href=""
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

interface ExportDropdownI {
  onExportClick: (contentType: ExportContentTypeEnum) => void;
  show?: boolean;
}

export default function ExportDropdown({ onExportClick, show }: ExportDropdownI) {
  const { t } = useTranslation();

  if (!show) {
    return null;
  }
  return (
    <StyledDropdown data-cy-export-dd>
      <Dropdown.Toggle as={CustomDDToggle}>
        <DownloadIcon />
        {t("common.table.export")}
        <DDArrow style={{ fill: "#fff" }} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            onExportClick(ExportContentTypeEnum.Csv);
          }}
        >
          <DownloadIcon />
          CSV
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            onExportClick(ExportContentTypeEnum.Xlsx);
          }}
        >
          <DownloadIcon />
          XLSX
        </Dropdown.Item>
      </Dropdown.Menu>
    </StyledDropdown>
  );
}
