import React from "react";
import { useTranslation } from "react-i18next";
import EmptyState from "../../EmptyState";
import PagePeriodFilter from "../../PagePeriodFilter/PagePeriodFilter";
import { PagePeriodFilterContext } from "../../PagePeriodFilter/PagePeriodFilterContextProvider";
import { PeriodFilterOptions } from "../../PagePeriodFilter/utils";
import { PageFilterContainer } from "../../ReportPageComponents/StyledComponents";
import Container from "../../UiKit/Container";
import { MainContentWrapper } from "../../UiKit/Helpers/StyledCss";

import HeaderWithBreadcrumbs from "../HeaderWithBreadcrumbs";

interface ListPageI {
  pageType: string;
  showEmptyState?: boolean;
  children?: JSX.Element | JSX.Element[] | null;
}

export default function ListPage({ children, pageType, showEmptyState }: ListPageI) {
  const { t } = useTranslation();
  const { periodOption } = React.useContext(PagePeriodFilterContext);

  const { emptyTitle, emptySubtitle } = React.useMemo(
    () =>
      periodOption === PeriodFilterOptions.ALL_TIME
        ? {
            emptyTitle: t(`${pageType}.noDataTitle`),
            emptySubtitle: t(`${pageType}.noDataSubtitle`),
          }
        : {
            emptyTitle: t("common.noDataInPeriod.title"),
            emptySubtitle: t("common.noDataInPeriod.subtitle"),
          },
    [periodOption, pageType, t]
  );

  return (
    <MainContentWrapper>
      <HeaderWithBreadcrumbs title={t(`${pageType}.title`)} subtitle={t(`${pageType}.subTitle`)} />

      <PageFilterContainer>
        <PagePeriodFilter />
      </PageFilterContainer>

      <Container>
        {showEmptyState ? null : children}

        <EmptyState show={showEmptyState} title={emptyTitle} subtitle={emptySubtitle} />
      </Container>
    </MainContentWrapper>
  );
}
