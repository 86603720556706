import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithLoadingSpinner from "../../../components/Layout/WithLoadingSpinner";
import { Text } from "../Auth.styled";
import { ApiError, AuthUserApi } from "../../../api";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import { Heading1 } from "../../../components/UiKit/Typography";
import * as Styled from "./Verify.styled";

export default function Verify() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = React.useState("");

  const { isFetching } = useQuery(
    [ApiQueryKeys.verifyVerify],
    () => AuthUserApi.verifyVerify({ requestBody: { token: searchParams.get("token")! } }),
    {
      retry: false,
      cacheTime: 0,
      onError: (e: ApiError) => {
        const { detail } = e.body;
        if (["VERIFY_USER_BAD_TOKEN", "VERIFY_USER_ALREADY_VERIFIED"].includes(detail)) {
          setErrorMessage(detail);
          return;
        }
        setErrorMessage("GENERIC_ERROR");
      },
    }
  );

  const infoMessage = React.useMemo(
    () => (errorMessage ? t(`auth.verify.${errorMessage}`) : t("auth.verify.success")),
    [errorMessage, t]
  );

  const title = React.useMemo(
    () => (errorMessage ? t("auth.verify.errorTitle") : t("auth.verify.successTitle")),
    [errorMessage, t]
  );

  return (
    <>
      <Styled.CompleteFigure />
      <WithLoadingSpinner isLoading={isFetching}>
        <>
          <Heading1>{title}</Heading1>
          <Text center>{infoMessage}</Text>
        </>
      </WithLoadingSpinner>

      <Styled.Button big onClick={() => navigate("/auth/login")}>
        {t("auth.verify.login")}
      </Styled.Button>
    </>
  );
}
