import styled from "styled-components";
import device from "../../theme/utils/breakpoints";

export const HeadingXL = styled.h1`
  font-size: 40px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const Heading1 = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 8px;

  @media ${device.M} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const Heading2 = styled.h2`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0.2px;

  @media ${device.M} {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const Heading3 = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 32px;
`;

export const Subtitle1 = styled.p<{ $smallMargin?: boolean }>`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 28px;

  ${(props) => props.$smallMargin && `margin-bottom: 10px;`}

  @media ${device.M} {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
`;

export const SubtleSubtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text2};
`;
