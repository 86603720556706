/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderByColumn {
  TRANSLATION = 'translation',
  ACTIVE_MDS = 'active_mds',
  SESSIONS = 'sessions',
  SESSIONS_PER_ACTIVE_MDS = 'sessions_per_active_mds',
  WITH_EMAIL_CONSENT = 'with_email_consent',
}
