import { useQuery } from "@tanstack/react-query";
import React from "react";
import { MDSpecializationSchema, SpecializationsApi } from "../../api";
import { ApiQueryKeys } from "../../api/ApiQueryKeys";

const useSpecializationsList = (country: string) => {
  const { data: specializationList } = useQuery<Array<MDSpecializationSchema>>(
    [ApiQueryKeys.specializationsSpecializationsList, country],
    () => SpecializationsApi.specializationsSpecializationsList({ country })
  );

  const sortedSpecializations = React.useMemo(
    () =>
      specializationList ? specializationList.sort((a, b) => a.local_name.localeCompare(b.local_name)) : undefined,
    [specializationList]
  );

  return sortedSpecializations || [];
};

export default useSpecializationsList;
