import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import AdsListTable from "./AdsListTable";
import TrafficData from "../../../components/ListViewPartials/TrafficData";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { AdResponse, AdSummaryReportSchema, AdzApi, ExportAdType } from "../../../api";
import { ApiQueryKeys } from "../../../api/ApiQueryKeys";
import * as Styled from "./AdsList.styled";
import { isInsideTheTimeRange } from "../../../utils/dates";
import WithLoadingCard from "../../../components/UiKit/WithLoadingCard";
import { sortByPublishedFromAndState } from "../../../utils/sort";
import { UserContext } from "../../../contexts/UserContext";
import ListPage from "../../../components/Layout/ListPage";

export enum AdType {
  SearchAd = "SearchAd",
  WebAd = "WebAd",
  NewsFeedAd = "NewsfeedAd",
  CmrAd = "CmrAd",
}

export enum AdsListPage {
  SearchAds = "searchAdsList",
  NewsFeedAds = "newsFeedAdsList",
  DrugMaterials = "drugMaterialsList",
}

export default function AdsList({ adsListType }: { adsListType: AdsListPage }) {
  const { t } = useTranslation();
  const { periodOption, selectedPeriod } = React.useContext(PagePeriodFilterContext);
  const { user } = React.useContext(UserContext);

  const { data: ads, isFetching: isFetchingAds } = useQuery<AdResponse[]>(
    [ApiQueryKeys.adsStaticData],
    AdzApi.adsStaticData
  );

  const { data: adsReports, isFetching: isFetchingAdsReports } = useQuery<AdSummaryReportSchema[]>(
    [ApiQueryKeys.adsSummaryReports, selectedPeriod],
    () => AdzApi.adsSummaryReports({ dateFrom: selectedPeriod?.from, dateUntil: selectedPeriod?.until }),
    { enabled: !!selectedPeriod }
  );

  const mergedData = React.useMemo(() => {
    if (!ads || !adsReports) {
      return null;
    }

    return ads
      .map((ad: AdResponse) => ({
        ...adsReports.find((report) => report.ad_id === ad.id),
        ...ad,
      }))
      .filter((entry) => isInsideTheTimeRange(entry, selectedPeriod))
      .sort(sortByPublishedFromAndState);
  }, [ads, adsReports, selectedPeriod]);

  const { newsFeedAds, cmrAds, searchAds } = React.useMemo(() => {
    if (!mergedData) {
      return {};
    }

    return _.groupBy(mergedData, (ad) => {
      switch (ad.type) {
        case AdType.CmrAd:
          return "cmrAds";
        case AdType.NewsFeedAd:
        case AdType.WebAd:
          return "newsFeedAds";
        case AdType.SearchAd:
          return "searchAds";
        default:
          return "other";
      }
    });
  }, [mergedData]);

  const trafficDataEntryProps = React.useMemo(() => {
    const props = ["ad_views", "views_unique_users"];

    if (adsListType === AdsListPage.SearchAds) {
      if (user?.permissions?.search_ad_opens) {
        props.push("ad_opens");
      }
    } else if (user?.permissions?.newsfeed_ad_opens) {
      props.push("ad_opens");
    }

    return props;
  }, [adsListType, user?.permissions?.newsfeed_ad_opens, user?.permissions?.search_ad_opens]);

  const isFetchingTable = React.useMemo(
    () => (isFetchingAds || isFetchingAdsReports) && !(ads && adsReports),
    [isFetchingAds, isFetchingAdsReports, ads, adsReports]
  );

  const selectedPeriodReports = React.useMemo(() => {
    switch (adsListType) {
      case AdsListPage.DrugMaterials:
        return cmrAds;
      case AdsListPage.NewsFeedAds:
        return newsFeedAds;
      case AdsListPage.SearchAds:
        return searchAds;
      default:
        return undefined;
    }
  }, [adsListType, cmrAds, newsFeedAds, searchAds]);

  const showEmptyState = React.useMemo(() => {
    if (isFetchingTable) {
      return false;
    }

    return !selectedPeriodReports;
  }, [isFetchingTable, selectedPeriodReports]);

  const adType = React.useMemo(() => {
    switch (adsListType) {
      case AdsListPage.DrugMaterials:
        return ExportAdType.CMR;
      case AdsListPage.NewsFeedAds:
        return ExportAdType.NEWSFEED;
      case AdsListPage.SearchAds:
      default:
        return ExportAdType.SEARCH;
    }
  }, [adsListType]);

  const table = React.useMemo(
    () => (
      <Styled.TableWrapper>
        <AdsListTable
          tableTitle={t(`${adsListType}.title`)}
          data={selectedPeriodReports as AdResponse[]}
          adType={adType}
        />
      </Styled.TableWrapper>
    ),
    [adType, adsListType, selectedPeriodReports, t]
  );

  return (
    <ListPage pageType={adsListType} showEmptyState={showEmptyState}>
      <TrafficData
        reportType="ads"
        periodOption={periodOption}
        selectedPeriodReports={selectedPeriodReports}
        trafficDataEntryProps={trafficDataEntryProps}
        isLoading={isFetchingAdsReports && !adsReports}
      />

      <WithLoadingCard isLoading={isFetchingTable}>
        <Styled.ScrollWrapper>{table}</Styled.ScrollWrapper>
      </WithLoadingCard>
    </ListPage>
  );
}
