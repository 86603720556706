import React from "react";
import styled from "styled-components";
import FooterMediatelySection from "./FooterMediatelySection";
import FooterInsightsSection from "./FooterInsightsSection";
import FooterAboutUsSection from "./FooterAboutUsSection";
import FooterProductsSection from "./FooterProductsSection";
import FooterAppsSection from "./FooterAppsSection";
import Container from "../../UiKit/Container";
import device from "../../../theme/utils/breakpoints";

const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.text4};
`;
const Sections = styled.div`
  display: flex;
  padding-top: 31px;
  padding-bottom: 78px;
  color: ${(props) => props.theme.blueSuperDark};
  gap: 36px;
  font-size: 14px;
  line-height: 18px;

  a {
    color: ${(props) => props.theme.blueSuperDark};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  > * {
    min-width: 128px;
  }
  > :nth-child(2) {
    margin-left: auto;
  }
  > :nth-child(4) {
    max-width: 128px;
  }
  > :nth-child(5) {
    min-width: unset;
    max-width: 86px;
  }

  @media ${device.M} {
    padding-bottom: 32px;
    flex-wrap: wrap;
    justify-content: space-between;

    > :nth-child(2) {
      margin-left: unset;
    }
    > :nth-child(1) {
      min-width: 100%;
    }
  }

  @media ${device.S} {
    flex-direction: column;

    > :nth-child(2) {
      margin-left: 0;
    }
  }
`;

export default function Footer() {
  return (
    <Container>
      <Wrapper>
        <Sections>
          <FooterMediatelySection />
          <FooterInsightsSection />
          <FooterAboutUsSection />
          <FooterProductsSection />
          <FooterAppsSection />
        </Sections>
      </Wrapper>
    </Container>
  );
}
