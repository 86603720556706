import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useMountEffect } from "../../../utils/hooks/useMountEffect";
import { LoginButton } from "../AuthCommon/AuthCommon";
import Progress from "./Progress";
import { RegisterHeading1 } from "./Register.styled";
import { RegisterContext } from "./RegisterContext";
import RegisterStepOne from "./RegisterStepOne";
import RegisterStepThree from "./RegisterStepThree";
import RegisterStepTwo from "./RegisterStepTwo";
import RegisterVerifyEmail from "./RegisterVerifyEmail";
import { RegisterSteps } from "./types";

function Step({ step }: { step: RegisterSteps }) {
  switch (step) {
    case 5:
    case 4:
      return <RegisterVerifyEmail />;
    case 3:
      return <RegisterStepThree />;
    case 2:
      return <RegisterStepTwo />;
    case 1:
    default:
      return <RegisterStepOne />;
  }
}

export default function Register() {
  const { step, setStep, setFormState, formState } = React.useContext(RegisterContext);
  const { t } = useTranslation();
  const location = useLocation();

  useMountEffect(() => {
    // This is for handling navigation from login page when resending verification email
    const { state } = location;
    if (!state?.step || !state?.email) {
      return;
    }

    setStep(state.step);
    setFormState({ ...formState, email: state.email });
  });

  return (
    <>
      {step < 5 && (
        <>
          <RegisterHeading1>{t("auth.register.header")}</RegisterHeading1>
          <Progress step={step} />
        </>
      )}

      <Step step={step} />

      <LoginButton />
    </>
  );
}
