import styled from "styled-components";
import { ButtonResetCSS, LinkColorCSS } from "./Helpers/StyledCss";

export const SubtleButton = styled.button<{ $confirm?: boolean; $disabled?: boolean }>`
  ${ButtonResetCSS}
  ${LinkColorCSS}

  ${(props) => props.$confirm && `color: ${props.theme.bluePrimary}`}
  ${(props) => props.$disabled && `opacity: 0.5; pointer-events: none;`}
`;

export default SubtleButton;
